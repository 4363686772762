import React, { useContext, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { useMediaPredicate } from "react-media-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import { ContextGlobal } from "../../contexts/ContextGlobal";
import { FormPackageSearch } from "../Forms/FormPackageSearch";
import { DownloadSampleModal } from "../Modals/DownloadSampleModal";

export const StandardMCPNew = (props) => {
    const { createRequest } = useContext(ContextGlobal);

    const [loading, setLoading] = useState(false);
    const history = useNavigate();

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const laptopSize = useMediaPredicate("(max-width: 1536px)");

    return (
        <div className="card card-product-no-height info-card transparent">
            <div className="row g-0">
                <div className="col-md-8" style={{ padding: "30px" }} /*style={{ paddingRight: phoneSize ? "" : "5%"}}*/>
                    <div className="row">
                        <div className="col-md-12" style={{ marginBottom: "5px" }}>
                            {midSizeScreen ? ( // mobile
                                <>
                                    <div className="product-heading text-center">Standard Medical</div>
                                    <div className="product-heading text-center">Cost Projection</div>
                                    <div className="product-heading text-center">$500</div>
                                </>
                            ) : (
                                <>
                                    <div className="product-heading">
                                        Standard Medical Cost Projection
                                        <span className="float-end">$500</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="row g-0">
                                <div className="col-auto">
                                    <b>Fast, easy, inexpensive</b>
                                </div>
                            </div>
                            <div className="row g-0">
                                <div className="col-auto">You pick the treatment you want to see. Search our system of over 800 medical services. This is the fastest turnaround at the best price.</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row g-0">
                                <div className="col-auto">
                                    <ul className="product-ul-style">
                                        <li className="product-bullet">Includes pre-op, post-op, and all relevant provider services</li>
                                        <li className="product-bullet">Fast and easy 4-step submission</li>
                                        <li className="product-bullet">24-48 hour turnaround</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <FormPackageSearch frontpage={true} />
                        </div>
                    </div>
                    {midSizeScreen ? (
                        <div className="row">
                            <div className="col-md-4">
                                <div className="row justify-content-center align-items-center">
                                    <hr style={{ color: "#1db8ea", marginBottom: "20px" }} />
                                    <div className="row" style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <div className="col text-center">
                                            <button
                                                className="btn access-primary-btn-lg"
                                                onClick={async (e) => {
                                                    setLoading(true);
                                                    await createRequest("type_product_medical_cost_projection_standard");
                                                    setLoading(false);
                                                    history("/builder/package_search");
                                                }}
                                            >
                                                {loading ? (
                                                    <Oval color="white" height={20} width={20} />
                                                ) : (
                                                    <>
                                                        <span>ORDER STANDARD MCP</span>
                                                        <br />
                                                        <span style={{ fontSize: "12px", fontWeight: "500" }}>Your prep work, our data</span>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* Non Mobile View Right Hand Side justify-content-center align-items-center */}
                {midSizeScreen ? null : (
                    <div
                        className="col-md-4 mcp-column"
                        // onClick={async (e) => {
                        // await createRequest("type_product_medical_cost_projection_standard");
                        // history("/builder/package_search");
                        // }}
                    >
                        <div className="d-flex flex-column justify-content-center align-items-stretch" style={{ height: "100%" }}>
                            {/* <div className="col-md-12" style={{ textAlign: "center", marginTop: "15px" }}>
                            <span 
                                className="download-mcp-file-span"
                                data-bs-toggle="modal" 
                                data-bs-target="#download-sample-modal"
                                title="Download Sample"
                            >
                                <img src="/img/Access_Homepage SMCP Report Image SM.png" className="img-fluid" alt="Standard MCP"/>
                                <FontAwesomeIcon className="hidden_img" icon={faFileDownload}  />
                            </span>
                        </div> */}
                            {/* <h1 className="text-center mb-4" style={{font: "700 50pt Montserrat", color: "#1db8ea"}}>$500</h1> */}
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="text-center">
                                        <button
                                            className="btn access-primary-blue"
                                            data-bs-toggle="modal"
                                            data-bs-target="#download-sample-modal"
                                            title="Download Sample"
                                            onClick={(e) => {
                                                // e.stopPropagation();
                                                props.setProduct("type_product_medical_cost_projection_standard");
                                            }}
                                            style={{ padding: "0.6rem 4.8rem" }}
                                        >
                                            Download Sample
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <button
                                    className="btn access-primary-btn-lg"
                                    onClick={async (e) => {
                                        // setLoading(true);
                                        // await createRequest("type_product_medical_cost_projection_standard");
                                        // setLoading(false);
                                        history("/builder/package_search");
                                    }}
                                >
                                    {loading ? (
                                        <Oval color="white" height={20} width={20} />
                                    ) : (
                                        <>
                                            <span>ORDER STANDARD MCP</span>
                                            <br />
                                            <span style={{ fontSize: "12px", fontWeight: "500" }}>Your prep work, our data</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
