import React, { useContext, useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import { ContextGlobal } from "../../contexts/ContextGlobal.js";
import { WhoWeAre } from "./WhoWeAre.js";
import { WhatWeDo } from "./WhatWeDo.js";
import { TheDifference } from "./TheDifference.js";
import { Whitepapers } from "./Whitepapers.js";
import { Experts } from "./Experts.js";

import { SwitchTransition, CSSTransition } from "react-transition-group";

export const WrapperAbout = (props) => {
    const { setActivePage } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("about");
        // eslint-disable-next-line
    }, []);

    const [select, setSelect] = useState("who");

    const selectRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash !== "") {
            setSelect(location.hash.replace(/[#]/g, ""));
        } else {
            selectRef.current.click();
        }
    }, [location]);

    const history = useNavigate();

    return (
        <div className="row g-0">
            <div className="col-md-10 offset-md-1 col-12">
                <div className="card card-product-no-height mb-5 transparent">
                    <div className="row g-0">
                        {/* Side Menu */}
                        <div className="col-xl-3 col-12">
                            <div className="menu">
                                <div className="card-body" style={{ fontSize: "1.4em", padding: "40px" }}>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "who" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "who" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("who");
                                                    history("/about-us#who");
                                                }}
                                                ref={selectRef}
                                            >
                                                Who We Are
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mt-2 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "what" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "what" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("what");
                                                    history("/about-us#what");
                                                }}
                                            >
                                                What We Do
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "difference" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "difference" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("difference");
                                                    history("/about-us#difference");
                                                }}
                                            >
                                                The Difference
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "whitepaper" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "whitepaper" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("whitepaper");
                                                    history("/about-us#whitepaper");
                                                }}
                                            >
                                                Whitepapers
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "experts" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "experts" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("experts");
                                                    history("/about-us#experts");
                                                }}
                                            >
                                                Experts
                                            </span>
                                        </div>
                                    </div>
                                    <a href="/contact-us" rel="noopener noreferrer" target="_blank" className="faq-contact-link">
                                        <div className="d-flex flex-row mb-3">
                                            <div className="me-2 mx-2">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </div>
                                            <div className="p-0" style={{ fontSize: "0.6em", fontWeight: "500" }}>
                                                NOT WHAT YOU WERE LOOKING FOR? <br /> CONTACT US FOR HELP
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Content */}
                        {/* Should swap out depending on the links above */}
                        <SwitchTransition>
                            <CSSTransition addEndListener={(node, done) => node.addEventListener("transitionend", done, false)} classNames="fade-faq" key={select}>
                                <div className="col-xl-9 col-12 column-right-hand-side">
                                    <div className="card-body" style={{ padding: "40px" }}>
                                        <>
                                            {select === "who" ? <WhoWeAre /> : null}
                                            {select === "what" ? <WhatWeDo /> : null}
                                            {select === "difference" ? <TheDifference /> : null}
                                            {select === "whitepaper" ? <Whitepapers /> : null}
                                            {select === "experts" ? <Experts /> : null}
                                        </>
                                    </div>
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                </div>
            </div>
        </div>
    );
};
