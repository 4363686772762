import React, { useEffect, useState } from "react";
import generalQuestions from "../../api/questions/general.json";
import lifeCareQuestions from "../../api/questions/lifecare.json";
import fairHealthQuestions from "../../api/questions/fairHealth.json";

const GeneralQuestions = (props) => {
    const [general, setGeneral] = useState([]);
    const [lifeCare, setLifeCare] = useState([]);
    const [fairHealth, setfairHealth] = useState([]);

    useEffect(() => {
        setGeneral(generalQuestions);
        setLifeCare(lifeCareQuestions);
        setfairHealth(fairHealthQuestions);
    }, [setGeneral, setLifeCare, setfairHealth]);

    return (
        <>
            <div className="row g-0 mb-3 mt-2 mx-2">
                <div className="col-md-12 faq-headings">
                    General Questions
                </div>
            </div>
            {general.map((qa, index) => (
                <div className="row g-0 mb-4 mx-2">
                    <div className="d-flex flex-row ">
                        <div className="me-2 faq-questions" style={{ display: "inline-block" }}>
                            {"Q:"}
                        </div>
                        <div className="faq-questions" style={{ display: "inline-block" }}>{qa.question}</div>
                    </div>
                    <div className="d-flex flex-row " style={{ fontSize: "0.9em" }}>
                        <div className="me-3" style={{ display: "inline-block" }}>
                            {"A:"}
                        </div>
                        <div style={{ display: "inline-block" }}>{qa.answer}</div>
                    </div>
                    {qa.answer_two != undefined ? (
                        <div className="d-flex flex-row " style={{ fontSize: "0.9em", marginTop: "10px", }}>
                            <div className="me-3" style={{ display: "inline-block", marginLeft: "13px", }}></div>
                            <div style={{ display: "inline-block" }}>{qa.answer_two}</div>
                        </div>
                    ) : (null)}
                    {qa.answer_three != undefined ? (
                        <div className="d-flex flex-row " style={{ fontSize: "0.9em", marginTop: "10px", }}>
                            <div className="me-3" style={{ display: "inline-block", marginLeft: "13px", }}></div>
                            <div style={{ display: "inline-block" }}>{qa.answer_three}</div>
                        </div>
                    ) : (null)}
                    {qa.answer_four != undefined ? (
                        <div className="d-flex flex-row " style={{ fontSize: "0.9em", marginTop: "10px", }}>
                            <div className="me-3" style={{ display: "inline-block", marginLeft: "13px", }}></div>
                            <div style={{ display: "inline-block" }}>{qa.answer_four}</div>
                        </div>
                    ) : (null)}
                </div>
            ))}
            <div className={"row g-0 mb-3 mx-2 " + (props.select == "all" ? "mt-5" : "mt-2")} id="life">
                <div className="col-md-12 faq-headings">
                    Life Care Planning Questions
                </div>
            </div>
            {lifeCare.map((qa, index) => (
                <div className="row g-0 mb-4 mx-2">
                    <div className="d-flex flex-row ">
                        <div className="me-2 faq-questions" style={{ display: "inline-block" }}>
                            {"Q:"}
                        </div>
                        <div className="faq-questions" style={{ display: "inline-block" }}>{qa.question}</div>
                    </div>
                    <div className="d-flex flex-row " style={{ fontSize: "0.9em" }}>
                        <div className="me-3" style={{ display: "inline-block" }}>
                            {"A:"}
                        </div>
                        <div style={{ display: "inline-block" }}>{qa.answer}</div>
                    </div>
                </div>
            ))}
            <div className="row g-0 mb-3 mx-2 mt-5">
                <div className="col-md-12 faq-headings">
                    FairHealth Questions
                </div>
            </div>
            {fairHealth.map((qa, index) => (
                <div className="row g-0 mb-4 mx-2">
                    <div className="d-flex flex-row ">
                        <div className="me-2 faq-questions" style={{ display: "inline-block" }}>
                            {"Q:"}
                        </div>
                        <div className="faq-questions" style={{ display: "inline-block" }}>{qa.question}</div>
                    </div>
                    <div className="d-flex flex-row " style={{ fontSize: "0.9em" }}>
                        <div className="me-3" style={{ display: "inline-block" }}>
                            {"A:"}
                        </div>
                        <div style={{ display: "inline-block" }}>{qa.answer}</div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default GeneralQuestions;
