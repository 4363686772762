import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";

export const FormSignup = (props) => {
    const { signup, session } = useContext(ContextGlobal);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        password: Yup.string().required().label("Password"),
    });

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        reset: reset3,
        formState: { errors: errors3 },
        setError: setError3,
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        if (session.email) {
            $("#closesignup").trigger("click");
        }
    }, [session]);

    const onSubmit = async (data) => {
        setLoading(true);
        if (!(await signup(data))) {
            setError3("email", { type: "manual", message: "fail" });
        } else {
            reset3();
        }
        setLoading(false);
    };

    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCheck = (e) => {
        setCheck(e.target.checked);
    };

    const phoneScreen = useMediaPredicate("(max-width: 450px)");

    return (
        <form onSubmit={handleSubmit3(onSubmit)}>
            <div className="row">
                <div className={phoneScreen ? "col-12" : "col"}>
                    <label htmlFor="firstname" className="form-label access-input-label-style">
                        First Name
                    </label>
                    <input type="text" placeholder="First Name" className={"access-input-style form-control " + (errors3.firstname ? "is-invalid" : "")} id="firstname" {...register3("firstName")} />
                    <div className="invalid-feedback">{errors3.firstname?.message}</div>
                </div>
                <div className={phoneScreen ? "col-12 mt-4" : "col"}>
                    <label htmlFor="lastname" className="form-label access-input-label-style">
                        Last Name
                    </label>
                    <input type="text" placeholder="Last Name" className={"access-input-style form-control " + (errors3.lastname ? "is-invalid" : "")} id="lastname" {...register3("lastName")} />
                    <div className="invalid-feedback">{errors3.lastname?.message}</div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <label htmlFor="email" className="form-label access-input-label-style">
                        Email
                    </label>
                    <input type="text" placeholder="Email" className={"access-input-style form-control " + (errors3.email ? "is-invalid" : "")} id="email" {...register3("email")} />
                    <div className="invalid-feedback">
                        {errors3.email?.message === "fail" ? (
                            <span>
                                This email already exists. If this is you, please{" "}
                                <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#login"
                                    onClick={(e) => {
                                        reset3();
                                        $("#closesignup").trigger("click");
                                    }}
                                    style={{ color: "#1db8ea", cursor: "pointer", textDecoration: "underline" }}
                                >
                                    login
                                </span>{" "}
                                first.
                            </span>
                        ) : (
                            errors3.email?.message
                        )}
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <label htmlFor="password" className="form-label access-input-label-style">
                        Password
                    </label>
                    <input type="password" placeholder="Password" className={"access-input-style form-control " + (errors3.password ? "is-invalid" : "")} id="password" {...register3("password")} />
                    <div className="invalid-feedback">{errors3.password?.message}</div>
                </div>
            </div>
            <div className="row mt-4">
                <div className={phoneScreen ? "col-12 d-flex align-items-center" : "col-9 d-flex align-items-center"}>
                    <input className="form-check-input access-input-checkbox" type="checkbox" value="" id="signup-check" onChange={handleCheck} />
                    <label className="form-check-label" htmlFor="signup-check" style={{ paddingLeft: 10, marginTop: 7 }}>
                        I agree to AccuMed's{" "}
                        <a href="/eula" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#1DB8EA" }}>
                            User Agreement
                        </a>
                    </label>
                </div>
                <div className="col-1"></div>
                <div className="col-md-2 col-12 text-end">
                    <Link to="/files/User Agreement - Access 2021.pdf" target="_blank" rel="noopener noreferrer">
                        <span className="download-icon">
                            <FontAwesomeIcon icon={faFileDownload} />
                        </span>
                    </Link>
                </div>
            </div>
            <div className="row mt-4" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                <button id="closesignup" type="button" data-bs-dismiss="modal" style={{ display: "none" }}>
                    Close
                </button>
                <button className="btn access-primary-button mt-4" type="submit" disabled={!check}>
                    {loading ? <Oval color="white" height={20} width={20} /> : "REGISTER"}
                </button>
            </div>
            <div className="row">
                <div className="col-12 mt-1" style={{ fontSize: "14px" }}>
                    Have an account?&nbsp;
                    <span style={{ color: "#1db8ea", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#login" data-bs-dismiss="modal">
                        Login
                    </span>
                </div>
            </div>
        </form>
    );
};
