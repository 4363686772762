import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextGlobal } from "../contexts/ContextGlobal";
import { useMediaPredicate } from "react-media-hook";
import { LoginModal } from "./Modals/LoginModal";
import { SignUpModal } from "./Modals/SignUpModal";
import { Oval, ThreeCircles, ThreeDots } from "react-loader-spinner";

export const NavBar = (props) => {
    const { activePage, session, logout, sessionLoading } = useContext(ContextGlobal);
    const history = useNavigate();

    const midSizeScreen = useMediaPredicate("(max-width: 1080px)");

    const handleLogout = (e) => {
        e.preventDefault();

        logout();

        history("/");
    };

    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        if (openMenu) {
            document.body.classList.add("offcanvas-active");
        } else {
            document.body.classList.remove("offcanvas-active");
        }
    }, [setOpenMenu, openMenu]);

    if (activePage.portal) {
        return <></>;
    }

    return (
        <>
            <nav className="navbar navbar-expand-xl navbar-light flex mb-5">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/" rel="noopener noreferrer">
                        <img src="/img/New_logo.svg" height={!midSizeScreen ? "40vh" : "28vh"} alt="Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav justify-content-end mt-2">
                            <li className="nav-item pb-lg-0 pb-2">
                                <a className="nav-link nav-link-style" href="/" id="navbarOrder" role="button">
                                    <span className={"nav-link-span middle " + (activePage.createReport ? "active" : "inactive")}>Order A Report</span>
                                </a>
                            </li>
                            <li className="nav-item pb-lg-0 pb-2">
                                <a className="nav-link nav-link-style" href="/my_reports" id="navbarOrder" role="button">
                                    <span className={"nav-link-span middle " + (activePage.myReports ? "active" : "inactive")}>My Reports</span>
                                </a>
                            </li>
                            <li className="nav-item pb-lg-0 pb-2">
                                <a className="nav-link nav-link-style" href="/products-and-pricing" id="navbarProducts" role="button">
                                    <span className={"nav-link-span middle " + (activePage.productsInfo ? "active" : "inactive")}>Products & Pricing</span>
                                </a>
                            </li>
                            <li className="nav-item pb-lg-0 pb-2">
                                <a className="nav-link nav-link-style" href="/faq" id="navbarDropdown" role="button">
                                    <span className={"nav-link-span middle " + (activePage.faq ? "active" : "inactive")}>FAQs</span>
                                </a>
                            </li>
                            {/* <li className="nav-item dropdown pb-lg-0 pb-2">
                            <a className="nav-link dropdown-toggle nav-link-style" href="#" id="navbarAboutUs" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About Us
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarAboutUs">
                                <li style={{ border: "0px" }} ><a className="dropdown-item" href="/about-us#who">Who We Are</a></li>
                                <li style={{ border: "0px" }}><a className="dropdown-item" href="/about-us#what">What We Do</a></li>
                                <li style={{ border: "0px" }}><a className="dropdown-item" href="/about-us#difference">The Difference</a></li>
                                <li style={{ border: "0px" }}><a className="dropdown-item" href="/about-us#whitepaper">Whitepapers</a></li>
                                <li style={{ border: "0px" }}><a className="dropdown-item" href="/about-us#experts">Experts</a></li>
                            </ul>
                        </li> */}
                            {sessionLoading ? (
                                <li style={{ order: midSizeScreen ? "2" : "" }} className={"nav-item pb-lg-0 pb-2" + (session.email ? " d-none" : "")}>
                                    <span className={"nav-link nav-link-style"} style={{ cursor: "pointer" }}>
                                        <span className={"nav-link-span middle " + (activePage.login ? "active" : "inactive")} onClick={(e) => setOpenMenu(false)} data-bs-toggle="modal" data-bs-target="#login">
                                            <ThreeDots color="white" height={20} width={20} />
                                        </span>
                                    </span>
                                </li>
                            ) : (
                                <>
                                    {/* <li
                                    style={{ order: midSizeScreen ? "2" : "" }}
                                    className={"nav-item pb-lg-0 pb-2" + (session.email ? " d-none" : "")}
                                >
                                    <span className={"nav-link nav-link-style"} style={{ cursor: "pointer" }}>
                                        <span
                                            className={
                                                "nav-link-span middle " + (activePage.login ? "active" : "inactive")
                                            }
                                            onClick={(e) => setOpenMenu(false)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#login"
                                        >
                                            Login
                                        </span>
                                    </span>
                                </li> */}
                                    <li
                                        style={{
                                            order: midSizeScreen ? "1" : "",
                                            marginBottom: midSizeScreen ? "10px" : "",
                                            marginTop: midSizeScreen ? "10px" : "",
                                        }}
                                        className={"nav-item pb-lg-0 pb-2 d-flex" + (session.email ? " d-none" : "")}
                                    >
                                        <button type="button" className="btn btn-primary btn-signup" data-bs-toggle="modal" data-bs-target="#login" onClick={(e) => setOpenMenu(false)} style={{ fontSize: "14pt" }}>
                                            LOGIN / SIGN UP
                                        </button>
                                    </li>
                                    <li style={{ order: midSizeScreen ? "3" : "" }} className={"nav-item dropdown pb-lg-0 pb-2" + (!session.email ? " d-none" : "")}>
                                        <span className={"nav-link nav-link-style dropdown-toggle"} style={{ cursor: "pointer" }} id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className={"nav-link-span " + (activePage.login ? "active" : "inactive")}>{session.firstName + " " + session.lastName}</span>
                                        </span>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                                            {/* <li><a className="dropdown-item" href="/my_reports">My Reports</a></li> */}
                                            <li style={{ border: "0px" }}>
                                                <a className={"dropdown-item"} href="/settings">
                                                    <span onClick={(e) => setOpenMenu(false)}>Settings</span>
                                                </a>
                                            </li>
                                            <li>
                                                <span className={"dropdown-item"} style={{ cursor: "pointer" }} onClick={handleLogout}>
                                                    <span onClick={(e) => setOpenMenu(false)}>Logout</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
            <LoginModal />
            <SignUpModal />
        </>
    );
};
