import React, { useState, useContext, useEffect } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";

export const MaxAmountModal = (props) => {
    const history = useNavigate();
    const { patchRequest, currentRequest } = useContext(ContextGlobal);
    const [hover, setHover] = useState();
    const [loading, setLoading] = useState(false);
    const [filePath, setFilePath] = useState();

    useEffect(() => {
        if (props.allFiles.length == 0) {
            if (currentRequest.request_attachment && currentRequest.request_attachment.length != 0) {
                currentRequest.request_attachment.forEach((attachment) => {
                    const found = attachment.link.indexOf("- BillAnalysis/"); //Snapshot

                    if (found != -1) {
                        setFilePath(attachment.name);
                    }
                });
            }
        } else {
            setFilePath(props.allFiles[0].path);
        }
    }, [props.allFiles]);

    return (
        <>
            {/* will need to be fixed up for mobile */}
            <div className="modal fade" id="max-total" tabIndex="-1" aria-hidden="true" data-bs-backdrop={"static"} data-bs-keyboard={"false"}>
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "620px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            className="card-close"
                            data-bs-dismiss="modal"
                            // onClick={async (e) => {
                            //     if (filePath) {
                            //         props.handleDeleteFile(filePath);
                            //     }
                            // }}
                        />
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h4 className="modal-title w-100 text-center" style={{ font: "700 1.5rem Montserrat", color: "#000064" }}>
                                Maximum Billed Amount Reached
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="w-100 text-center">
                                You've reached the max cost of bills allowed ($40,000)
                                <br />
                                for Standard Bill Analysis. Your total is
                                <strong>{currentRequest.request_attachment && currentRequest.request_attachment.length != 0 ? (currentRequest.request_attachment[0].totalCharges ? " $" + parseFloat(currentRequest.request_attachment[0].totalCharges, 10).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "") : ""}</strong>
                                <br />
                                Please switch to a Pro Bill Analysis or contact us for more info.
                            </div>
                            <div className="row mt-4">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <div className="d-flex justify-content-center">
                                        {/* would also neeed to be a patch() call */}
                                        <button
                                            className="btn access-primary-button flex-fill"
                                            type="button"
                                            // data-bs-toggle={session.email ? "" : "modal"}
                                            // data-bs-target={session.email ? "" : "#login"}
                                            data-bs-dismiss="modal"
                                            onClick={async (e) => {
                                                setLoading(true);
                                                await patchRequest(currentRequest.request.requestID, "typeProductID", 2);
                                                setLoading(false);
                                                history("/builder/pro_bill_analysis");
                                            }}
                                        >
                                            {loading ? <Oval color="white" height={20} width={20} /> : "SWITCH TO A PRO BILL ANALYSIS REPORT"}
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="col-2">
                                    <span
                                        className="fa-layers fa-fw fa-center-icon"
                                        style={{ color: "#1DB8EA", cursor: "pointer" }}
                                        onMouseEnter={(e) => setHover(true)}
                                        onMouseLeave={(e) => setHover()}
                                    >
                                        <span
                                            data-tip="custom show"
                                            data-event="mouseenter"
                                            data-event-off="mouseleave"
                                            data-for="custom-info"
                                        >
                                            <FontAwesomeIcon
                                                style={{ display: !hover ? "none" : "" }}
                                                transform="shrink-5"
                                                icon={faCircle}
                                                color="#1db8ea"
                                            />
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                transform="shrink-10"
                                                className="share-remove"
                                                color={hover ? "white" : "#1db8ea"}
                                            />
                                        </span>
                                        <ReactTooltip
                                            className="custom-info-tooltip"
                                            id="custom-info"
                                            place="right"
                                            globalEventOff="click"
                                            multiline={true}
                                            textColor="#000000"
                                            backgroundColor="#e8ff8b"
                                            borderColor="#e8ff8b"
                                            arrowColor="#e8ff8b"
                                        >
                                            {props.futures ? (
                                                <span>
                                                    Simply attach the treatment recommendation and our talented
                                                    <br />
                                                    staff will build a customized Future Needs report.
                                                    <br />
                                                    Cost: $950
                                                </span>
                                            ) : (
                                                <span>
                                                    Want to try our pre-built treatment packages?
                                                    <br />
                                                    Switch from a Custom Report to a Standard Report
                                                    <br />
                                                    Cost: $500
                                                </span>
                                            )}
                                        </ReactTooltip>
                                    </span>
                                </div> */}
                            </div>
                            <div className="row mt-4">
                                <div className="d-flex justify-content-center" style={{ font: "700 1.5rem Montserrat", color: "#000064" }}>
                                    or
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="d-grid gap-2 col-8 mx-auto">
                                    <a href="mailto:info@accumedintel.com" className="btn access-secondary-button">
                                        Email Us
                                    </a>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="d-grid gap-2 col-8 mx-auto">
                                    <a href="/faq" target="_blank" className="btn access-secondary-button" type="button">
                                        Visit our FAQ page
                                    </a>
                                </div>
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center mt-4">
                                (844) 307 4487
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center">
                                Mon-Fri: 8am - 6pm MT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
