import React from "react";
import { ProductButton } from "../Buttons/ProductButton";
import { useMediaPredicate } from "react-media-hook";

export const FutureAnalysisProducts = (prop) => {
    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const laptopSize = useMediaPredicate("(max-width: 1536px)");

    return (
        <div className="card card-product">
            <div className="card-header text-center mt-4" style={{ background: "#fff", borderTopLeftRadius: "1em", borderTopRightRadius: "1em", borderBottom: "0" }}>
                {/* <img src="/img/Access_Homepage Folder SM.png" className="img-fluid" alt="Responsive image"/> */}
                <h4
                    style={{
                        marginBottom: "10px",
                        marginTop: "24px",
                        paddingLeft: "16px",
                        font: "700 17pt Montserrat",
                    }}
                >
                    <span style={{ color: "#1db8ea", font: "700 22pt Montserrat" }}>Future Analysis Products</span>
                </h4>
            </div>
            <div className="card-body text-center">
                <h5 style={{ font: "700 18px Montserrat", color: "#000046" }}>MEDICAL COST PROJECTION</h5>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12">A complete estimate of the reasonable value of future medical care as recommended by a medical professional.</div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER STANDARD MCP"} description={"Your prep work, our data"} product={"type_product_medical_cost_projection_standard"} />
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER PRO MCP"} description={"Hand it over to the Pros"} product={"type_product_medical_cost_projection_pro"} />
                    </div>
                </div>
                <div className="row justify-content-center my-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER EXPERT MCP"} description={"Expert Witness at your service"} product={"type_product_medical_cost_projection_expert"} />
                    </div>
                </div>
            </div>
        </div>
    );
};
