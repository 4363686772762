import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";
import { SwitchProductButton } from "../Buttons/SwitchProductButton";
import { ExpertProductsInfo } from "../Tooltips/ExpertProductInfo";

export const EngageExpertModal = (props) => {

    const [product, setProduct] = useState('');
    const [name, setName] = useState('');
    const [cost, setCost] = useState('');
    const [link, setLink] = useState('');
    const [message, setMessage] = useState('');

    const sheetRef = useRef(null);
    const cancelRef = useRef(null);

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    useLayoutEffect(() => {
        sheetRef.current.style.setProperty("font-size", "10pt", "important");
        cancelRef.current.style.setProperty("font-size", "10pt", "important");
    }, []);

    useEffect(() => {

        switch (props.product) {
            case 'type_product_medical_cost_projection_standard':
                setName("Medical Cost Projection");
                setCost('$2,950');
                setProduct('type_product_medical_cost_projection_expert');
                setLink("/builder/expert_mcp");
                break;
            case 'type_product_medical_cost_projection_pro':
                setName("Medical Cost Projection");
                setCost('$2,950');
                setProduct('type_product_medical_cost_projection_expert');
                setLink("/builder/expert_mcp");
                break;
       
            case 'type_product_medical_bill_analysis_standard':
                setName("Medical Bill Analysis");
                setCost('$1,750 + 1% of billed charges');
                setProduct('type_product_medical_bill_analysis_expert');
                setLink("/builder/expert_bill_analysis");
                break;
            case 'type_product_medical_bill_analysis_pro':
                setName("Medical Bill Analysis");
                setCost('$1,750 + 1% of billed charges');
                setProduct('type_product_medical_bill_analysis_expert');
                setLink("/builder/expert_bill_analysis");
                break;      
    
            case 'type_product_bill_analysis_with_code_review_pro':
                setName("Medical Bill Analysis + Code Review");
                setCost('$2,250 + 2% of billed charges');
                setProduct('type_product_bill_analysis_with_code_review_expert');
                setLink("/builder/expert_bill_analysis_code_review");
                break;      
   
            default:
                break;
        }

    }, [props.product]);

    const expertMessage =
        <>
            <p style={{ textAlign: "left" }}>
                AccuMed Experts are available for the needs of your case. Once you engage one of our qualified Experts, you will
                work with them directly to gain strategic insight on the value of future recommended treatment. Expert Medical 
                Cost Projection costs $2,950 and has a 3-week turnaround time.
            </p>
        </>
    
    const historicalMessage =
        <>
            <p style={{textAlign: "left"}}>
                Engage one of our qualified Experts and work directly with them as they analyze and establish the reasonable
                value of billed charges. Expert Engagement on a Bill Analysis has a 3-week turnaround, a total fee of $1,750 + 1%
                of the billed charges, and includes a complete Expert file that meets Expert Disclosure requirements.
            </p>
        </>

    return (
        <div className="modal fade" id="engage-expert" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "700px" }}>
                <div className="modal-content modal-eula-sign">
                    <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faTimes}
                        className="card-close"
                        data-bs-dismiss="modal"
                    />
                    <div className="modal-header" style={{ borderBottom: "none" }}>
                        <h4
                            className={"modal-title w-100 " + (phoneSize ? "" : "text-center")}
                            style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                        >
                            You are switching to the Expert version
                            <br />
                            of our {name}
                        </h4>
                    </div>
                    <div className="modal-body">
                        <p className={(phoneSize ? "" : "text-center")}>
                            The cost for this report is {cost}. We'll save the information you've already entered.
                        </p>
                        <div className="row mt-4">
                            <div className="col-md-10 offset-md-1">
                                <div className="d-flex justify-content-center mb-4">
                                    {/* would also neeed to be a patch() call */}
                                    <SwitchProductButton 
                                        product={product}
                                        name={"Expert " + name}
                                        link={link}
                                    />
                                    
                                </div>
                            </div>
                            <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                <>
                                    {props.product == "type_product_medical_cost_projection_standard" ||
                                        props.product == "type_product_medical_cost_projection_pro" ? (
                                        <ExpertProductsInfo
                                            product={product}
                                            message={expertMessage}
                                        />
                                    ) : (null)}
                                    {props.product == "type_product_medical_bill_analysis_standard" ||
                                        props.product == "type_product_medical_bill_analysis_pro" ? (
                                        <ExpertProductsInfo
                                            product={product}
                                            message={historicalMessage}
                                        />
                                    ) : (null)}
                                </>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-10 offset-md-1">
                                <div className="d-flex justify-content-center">
                                    <a 
                                        ref={sheetRef}
                                        type="button"
                                        href="/files/AccuMed Pricing Sheet - June 2023.pdf"
                                        target="_blank" rel="noopener noreferrer"
                                        className="btn access-secondary-button" 
                                        style={{ textDecoration: "none", color: "white", width: "100%"}}
                                    >
                                        Download our product comparison sheet
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-10 offset-md-1">
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn access-secondary-button"
                                        ref={cancelRef}
                                        style={{paddingTop: "6px", width: "100%"}}
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
