import React, { useContext, useState } from "react";
import { faPen, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FormReview } from "../../Forms/FormReview";
import { useMediaPredicate } from "react-media-hook";
import { HashLink } from "react-router-hash-link";

export const BuilderReview = (props) => {
    const { requestPackages } = useContext(ContextGlobal);
    const [hover, setHover] = useState();
    const midSizeScreen = useMediaPredicate("(max-width: 769px)");

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <Link to="/builder/standard_mcp">&lt; Patient Info</Link>
            </div>
            <div className="card content-card mb-5">
                <div className="row g-0">
                    <div className="col-lg-6 col-12"
                        style={{
                            paddingRight: midSizeScreen ? "" : "40px",
                            borderRight: midSizeScreen ? "" : "1px solid #e1e5ec",
                            borderBottom: midSizeScreen ? "2px solid #e1e5ec" : "",
                        }}
                    >
                        <h3 className="mb-4">Selected treatments</h3>
                        <div className="mb-4">
                            {requestPackages &&
                                requestPackages?.map((packageRow, index) => (
                                    <div key={index} className="row row-striped g-0 p-4">
                                        <div className="col-11">
                                            <h4
                                                style={{
                                                    font: midSizeScreen ? "500 1.2rem Roboto" : "500 1.5rem Roboto",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {packageRow.request_package.title}
                                            </h4>
                                        </div>
                                        <div className="col-1">
                                            <HashLink
                                                smooth
                                                to={
                                                    "/builder/package_search#requestPackage" +
                                                    packageRow.request_package.requestPackageID
                                                }
                                            >
                                                <span
                                                    className="fa-layers fa-fw fa-center-icon link-primary float-end"
                                                    style={{ cursor: "pointer" }}
                                                    //   onClick={(e) =>
                                                    //       packageRow.quantity !== 0
                                                    //           ? updateRequestPackage({
                                                    //                 requestPackageID: packageRow.requestPackageID,
                                                    //                 isFormReview: !requestPackages.find(
                                                    //                     (row) => row.requestPackageID===packageRow.requestPackageID
                                                    //                 ).isFormReview,
                                                    //             })
                                                    //           : ""
                                                    //   }
                                                    onMouseEnter={(e) => setHover(index)}
                                                    onMouseLeave={(e) => setHover()}
                                                >
                                                    <FontAwesomeIcon
                                                        style={{ display: hover !== index ? "none" : "" }}
                                                        icon={faCircle}
                                                        color="#1db8ea"
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        transform="shrink-8"
                                                        className="share-remove"
                                                        color={hover === index ? "white" : "#1db8ea"}
                                                    />
                                                </span>
                                            </HashLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 mb-2">
                                                    <small className="text-muted">
                                                        {packageRow.request_package.multiplier +
                                                            " "}
                                                        {packageRow.request_package.multiplier >
                                                            1 ||
                                                        packageRow.request_package.multiplier ===
                                                            0
                                                            ? "occurrences"
                                                            : "occurrence"}
                                                        {packageRow.request_package.multiplierText
                                                            ? " (" +
                                                            packageRow.request_package
                                                                .multiplierText +
                                                            ")"
                                                        : ""}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        {packageRow?.request_bill?.map((bill, indexBill) => (
                                            <div
                                                className="ms-md-5 ms-2 mb-1"
                                                style={{
                                                    width: "80%",
                                                    textDecoration: bill.isIncluded ? "" : "line-through",
                                                }}
                                            >
                                                {bill.title}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-12"
                        style={{ paddingLeft: midSizeScreen ? "" : "40px", marginTop: midSizeScreen ? "16px" : "" }}
                    >
                        <FormReview />
                    </div>
                </div>
            </div>
        </>
    );
};
