import React, { useRef, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";
import { SwitchProductButton } from "../Buttons/SwitchProductButton.js";
import { StandardProductsInfo } from "../Tooltips/StandardProductsInfo.js";
import { ProProductsInfo } from "../Tooltips/ProProductInfo.js";
import { ExpertProductsInfo } from "../Tooltips/ExpertProductInfo.js";

export const SOSModal = (props) => {

    const message =
        <>
            <p>
                Fast, easy, inexpensive
            </p>
            <p>
                You pick the treatment you want to see. Search our system of over 800 medical services. This is the fastest
                turnaround at the best price.
            </p>
            <ul style={{textAlign: "left"}}>
                <li>Includes pre-op, post-op, and all relevant provider services </li>
                <li>Fast and easy 4-step submission</li>
                <li>1-2 day turnaround</li>
                <li>Switch to a Pro Report anytime</li>
            </ul>
        </>

    const proMessage = 
        <>
            <p>
                Estimates built by Certified Professional Coders
            </p>
            <p style={{  }}>
                Not sure what treatment is needed? Leave it to the pros. Send us the doctor's recommendation and we'll take
                care of the rest.
            </p>
            <ul style={{ textAlign: "left" }}>
                <li>Inlcudes pre-op, post-op, and all relevant provider services</li>
                <li>1-step submission, we just need case info and recommendation</li>
                <li>5-7 day turnaround</li>
            </ul>
        </>

    const expertMessage =
        <>
            <p style={{ textAlign: "left" }}>
                AccuMed Experts are available for the needs of your case. Once you engage one of our qualified Experts, you will
                work with them directly to gain strategic insight on the value of future recommended treatment. Expert Medical 
                Cost Projection costs $2,950 and has a 3-week turnaround time.
            </p>
        </>

    // const emailRef = useRef(null);
    const faqRef = useRef(null);
    const sheetRef = useRef(null)

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    useLayoutEffect(() => {
        // emailRef.current.style.setProperty("font-size", "10pt", "important");
        faqRef.current.style.setProperty("font-size", "10pt", "important");
        if (!phoneSize) {
            sheetRef.current.style.setProperty("font-size", "10pt", "important");
        } else {
            sheetRef.current.style.setProperty("font-size", "9pt", "important");
        }
    }, []);

    return (
        <>
            {/* will need to be fixed up for mobile */}
            <div className="modal fade" id="sos" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "700px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            className="card-close"
                            data-bs-dismiss="modal"
                        />
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h4
                                className={"modal-title w-100 " + (phoneSize ? "" : "text-center")}
                                style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                            >
                                Are you stuck? Have a quick question?
                                <br />
                                Just want to chat with some rad people?
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <h4
                                        style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                                        className="modal-title w-100 text-center"
                                    >
                                        We're here to help!
                                    </h4>
                                </div>
                            </div>

                            {/* Product Switch Buttons */}
                            {props.standard ? (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_cost_projection_pro"}
                                                    name={"Pro Medical Cost Projection"}
                                                    link={"/builder/pro_mcp"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ProProductsInfo 
                                                product={"type_product_medical_cost_projection_pro"} 
                                                message={proMessage} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_cost_projection_expert"}
                                                    name={"Expert Medical Cost Projection"}
                                                    link={"/builder/pro_mcp?disclosure=true"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ExpertProductsInfo
                                                product={"type_product_medical_cost_projection_expert"}
                                                message={expertMessage}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (null)}
                            {props.pro ? (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_cost_projection_standard"}
                                                    name={"Standard Medical Cost Projection"}
                                                    link={"/builder/package_search"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <StandardProductsInfo 
                                                product={"type_product_medical_cost_projection_standard"} 
                                                message={message}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_cost_projection_expert"}
                                                    name={"Expert Medical Cost Projection"}
                                                    link={"/builder/pro_mcp?disclosure=true"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ExpertProductsInfo
                                                product={"type_product_medical_cost_projection_expert"}
                                                message={expertMessage}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (null)}
                            {props.expert ? (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_cost_projection_standard"}
                                                    name={"Standard Medical Cost Projection"}
                                                    link={"/builder/package_search"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <StandardProductsInfo 
                                                product={"type_product_medical_cost_projection_standard"} 
                                                message={message} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_cost_projection_pro"}
                                                    name={"Pro Medical Cost Projection"}
                                                    link={"/builder/pro_mcp"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ProProductsInfo 
                                                product={"type_product_medical_cost_projection_pro"} 
                                                message={proMessage} 
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (null)}
                            <div className="row">
                                <div
                                    className="d-flex justify-content-center"
                                    style={{ font: "700 1.5rem Montserrat", color: "#000064" }}
                                >
                                    or
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center flex-fill">
                                        <a href="/contact-us" target="_blank" rel="noreferrer noopener" className="btn access-secondary-button" style={{ width: "100%" }}>
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center">
                                        <a
                                            href="/faq"
                                            target="_blank"
                                            className="btn access-secondary-button"
                                            type="button"
                                            ref={faqRef}
                                            style={{ width: "100%" }}
                                        >
                                            Visit our FAQ page
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center">
                                        <a 
                                            ref={sheetRef}
                                            type="button"
                                            href="/files/AccuMed Pricing Sheet - June 2023.pdf"
                                            target="_blank" rel="noopener noreferrer"
                                            className="btn access-secondary-button" 
                                            style={{ textDecoration: "none", color: "white", width: "100%"}}
                                            // onClick={(e) => {
                                            //     e.stopPropagation();
                                            // }}
                                        >
                                            Download our product comparison sheet
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div
                                style={{ font: "600 0.8rem Montserrat" }}
                                className="d-flex justify-content-center mt-4"
                            >
                                (844) 307 4487
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center">
                                Mon-Fri: 8am - 5:00pm MST
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
