import React, { useEffect, useState, useContext } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-bootstrap";

export const BuilderBillUploadExcelValidation = (props) => {
    const { currentRequest, excelObj } = useContext(ContextGlobal);

    const [titleRow, setTitleRow] = useState();
    const [dataRow, setDataRow] = useState();
    const [totalObj, setTotalObj] = useState({ total: "" }); // needs to be an obj so we can trigger the useEffect on the same ammount.
    const [maxAmount, setMaxAmount] = useState(false);
    const [noticationCount, setNoticationCount] = useState(null);
    const [errorCount, setErrorCount] = useState(null);
    const [warningCount, setWarningCount] = useState(null);
    const [invalidHeader, setInvalidHeader] = useState(false);
    const [invalidColumns, setInvalideColums] = useState(false);

    useEffect(() => {
        setInvalideColums(false);
        setInvalidHeader(false);
        // when uploading file after file...
        if (excelObj.data && excelObj.data.length != 0) {
            setTitleRow(excelObj.data[0]);
            setDataRow(excelObj.data.slice(1));
        } else {
            if (excelObj.invalidHeader) {
                setInvalidHeader(excelObj.invalidHeader);
            } else if (excelObj.invalidColumns) {
                setInvalideColums(excelObj.invalidColumns);
            } else {
                setTitleRow([]);
                setDataRow([]);
            }
        }
        setTotalObj({ total: excelObj.total });
        setNoticationCount(excelObj.noticationCount);
        setErrorCount(excelObj.errorCount);
        setWarningCount(excelObj.warningCount);
    }, [excelObj]);

    useEffect(() => {
        if (props.files && props.files.length == 0) {
            setNoticationCount(null);
            setErrorCount(null);
            setWarningCount(null);
            setTitleRow(null);
            setDataRow(null);
            setInvalideColums(false);
            setInvalidHeader(false);
            setMaxAmount(false);
            // setTotal();
        }
    }, [props.files]);

    useEffect(() => {
        if (currentRequest.request_attachment && currentRequest.request_attachment.length != 0) {
            currentRequest.request_attachment.forEach((attachment) => {
                const found = attachment.link.indexOf("- BillAnalysis/"); //Snapshot

                if (found != -1) {
                    if (attachment.metadata == "1") {
                        setErrorCount(0);
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        // coming back to the page...
        if (excelObj.data && excelObj.data.length != 0) {
            setTitleRow(excelObj.data[0]);
            setDataRow(excelObj.data.slice(1));
        }
    }, []);

    // display modal when max limit is reached/exceeded
    useEffect(() => {
        if (!isNaN(totalObj.total)) {
            if (parseFloat(totalObj.total) >= 40000) {
                props.maxTotalRef.current.click();
                setMaxAmount(true);
            }
        }
    }, [totalObj]);

    const splitTitle = (title) => {
        const split = title.split("(");

        return {
            title: split[0],
            paran: split[1].replace(")", ""),
        };
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-10 mb-4 align-self-center">
                    {noticationCount > 0 ? (
                        <>
                            <span style={{ verticalAlign: "middle" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#1db8ea" size="2x" />
                            </span>
                            <span style={{ marginLeft: "10px", color: "#1db8ea" }}>{noticationCount} Notications found. Notifications are information only and do not prevent you from Continuing</span>
                            <br />
                        </>
                    ) : (
                        <></>
                    )}
                    {errorCount > 0 ? (
                        <>
                            <span style={{ verticalAlign: "middle" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#bb0046" size="2x" />
                            </span>
                            <span style={{ marginLeft: "10px", color: "#bb0046" }}>{errorCount} Errors found. Please fix errors and re-upload Bill Analysis Template</span>
                            <br />
                        </>
                    ) : (
                        <> </>
                    )}
                    {warningCount > 0 ? (
                        <>
                            <span style={{ verticalAlign: "middle" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#a6800e" size="2x" />
                            </span>
                            <span style={{ marginLeft: "10px", color: "#a6800e" }}>{warningCount} Warnings found. Might affect final report</span>
                            <br />
                        </>
                    ) : (
                        <></>
                    )}
                    {invalidHeader ? (
                        <>
                            <span style={{ verticalAlign: "middle" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#bb0046" size="2x" />
                            </span>
                            <span style={{ marginLeft: "10px", color: "#bb0046" }}>Incorrect header found in spreadsheet, make sure to upload Bill Analysis template without altering the columns.</span>
                            <br />
                        </>
                    ) : (
                        <></>
                    )}
                    {invalidColumns ? (
                        <>
                            <span style={{ verticalAlign: "middle" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#bb0046" size="2x" />
                            </span>
                            <span style={{ marginLeft: "10px", color: "#bb0046" }}>Incorrect number of columns found, upload correct Bill Analysis Template</span>
                            <br />
                        </>
                    ) : (
                        <></>
                    )}
                    {titleRow && dataRow && titleRow.length == 0 && dataRow.length == 0 ? (
                        <>
                            <span style={{ verticalAlign: "middle" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#bb0046" size="2x" />
                            </span>
                            <span style={{ marginLeft: "10px", color: "#bb0046" }}>Empty spreadsheet uploaded - re upload filled out Bill Analysis Template</span>
                            <br />
                        </>
                    ) : (
                        <></>
                    )}
                    {errorCount == 0 && (warningCount == 0 || warningCount == null) ? (
                        <>
                            {maxAmount ? (
                                <>
                                    <span style={{ verticalAlign: "middle" }}>
                                        <FontAwesomeIcon icon={faExclamationCircle} color="#a6800e" size="2x" />
                                    </span>
                                    <span style={{ marginLeft: "10px", color: "#a6800e" }}>
                                        Max amount exceeded. Your total is:{" "}
                                        <strong>
                                            $
                                            {parseFloat(totalObj.total)
                                                .toFixed(2)
                                                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                                        </strong>
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span style={{ verticalAlign: "middle" }}>
                                        <FontAwesomeIcon icon={faCheckCircle} color="#28a745" size="2x" />
                                    </span>
                                    <span style={{ marginLeft: "10px", color: "#28a745" }}>
                                        Looks good, please continue to fill out patient information. Your total is:
                                        <strong>
                                            {totalObj.total
                                                ? parseFloat(totalObj.total)
                                                      .toFixed(2)
                                                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                                                : " $" + parseFloat(currentRequest.request_attachment[0].totalCharges, 10).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                        </strong>
                                    </span>
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="col-md-2 mb-4 d-flex align-items-end flex-column">
                    {/* {(errorCount == 0) && (titleRow && dataRow) && (titleRow.length != 0 && dataRow.length != 0) ? ( */}
                    <div className="mt-auto">
                        <Link
                            to={
                                parseFloat(totalObj.total) >= 40000
                                    ? "#"
                                    : {
                                          pathname: "/builder/snapshot",
                                          state: {
                                              fromBillUpload: true,
                                          },
                                      }
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                if (parseFloat(totalObj.total) >= 40000) {
                                    props.maxTotalRef.current.click();
                                }
                            }}
                        >
                            <button className="btn access-primary-button float-end" disabled={errorCount > 0 || errorCount == null}>
                                {errorCount > 0 || errorCount == null ? "FIX ERRORS TO CONTINUE" : "CONTINUE"}
                            </button>
                        </Link>
                    </div>
                    {/* ) : ( <></> )} */}
                </div>
                {titleRow && dataRow && titleRow.length != 0 && dataRow.length != 0 ? (
                    <div className="col-md-12 p-0">
                        <div className="table-responsive">
                            <table className="table table-striped mb-0" style={{ fontSize: "14px" }}>
                                <thead>
                                    <tr style={{ backgroundColor: "#A5E3F7" }}>
                                        {/* Claim Type */}
                                        <th style={{ whiteSpace: "nowrap" }} scope="col">
                                            <span data-tip data-for="title-0">
                                                {splitTitle(titleRow[0]).title}
                                                <ReactTooltip id="title-0" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[0]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* DOS */}
                                        <th style={{ width: "100px", maxWidth: "100px" }} scope="col">
                                            <span data-tip data-for="title-1">
                                                {splitTitle(titleRow[1]).title}
                                                <ReactTooltip id="title-1" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[1]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Code */}
                                        <th style={{ whiteSpace: "nowrap", textAlign: "left", width: "65px", maxWidth: "65px" }} scope="col">
                                            <span data-tip data-for="title-2">
                                                {splitTitle(titleRow[2]).title}
                                                <ReactTooltip id="title-2" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[2]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Modifier */}
                                        <th style={{ whiteSpace: "nowrap", textAlign: "right" }} scope="col">
                                            <span data-tip data-for="title-3">
                                                {splitTitle(titleRow[3]).title}
                                                <ReactTooltip id="title-3" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[3]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Actual Charge */}
                                        <th style={{ whiteSpace: "nowrap", textAlign: "right" }} scope="col">
                                            <span data-tip data-for="title-4">
                                                {splitTitle(titleRow[4]).title}
                                                <ReactTooltip id="title-4" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[4]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Units */}
                                        <th style={{ whiteSpace: "nowrap", textAlign: "right" }} scope="col">
                                            <span data-tip data-for="title-5">
                                                {splitTitle(titleRow[5]).title}
                                                <ReactTooltip id="title-5" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[5]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Rendering Provider NPI */}
                                        <th style={{ whiteSpace: "nowrap", textAlign: "left" }} scope="col">
                                            <span data-tip data-for="title-6">
                                                {splitTitle(titleRow[6]).title}
                                                <ReactTooltip id="title-6" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[6]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Physician Name */}
                                        <th style={{ whiteSpace: "nowrap" }} scope="col">
                                            <span data-tip data-for="title-7">
                                                {splitTitle(titleRow[7]).title}
                                                <ReactTooltip id="title-7" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[7]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                        {/* Service / Facility / Location Name */}
                                        <th style={{ whiteSpace: "nowrap" }} scope="col">
                                            <span data-tip data-for="title-8">
                                                {splitTitle(titleRow[8]).title}
                                                <ReactTooltip id="title-8" type="light" effect="solid">
                                                    <span>{splitTitle(titleRow[8]).paran}</span>
                                                </ReactTooltip>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataRow?.map((dataCell, index) => (
                                        <tr className="" id={"datarow-" + index}>
                                            {dataCell?.map((data, index) => (
                                                <td
                                                    className={data.notification ? "notification-cell" : data.warning ? "warning-cell" : data.error ? "invalid-cell" : ""}
                                                    style={{
                                                        lineHeight: "80%",
                                                        textAlign: index == 1 || index == 2 || index == 6 ? "left" : index == 3 || index == 4 || index == 5 ? "right" : "",
                                                    }}
                                                >
                                                    <div style={{ whiteSpace: "nowrap" }}>{data.value}</div>
                                                    {data.error ? (
                                                        <>
                                                            <span className="excel-validation-message-error">{data.message}</span>
                                                        </>
                                                    ) : null}
                                                    {data.warning ? (
                                                        <>
                                                            <span className="excel-validation-message-warning">{data.message}</span>
                                                        </>
                                                    ) : null}
                                                    {data.notification ? (
                                                        <>
                                                            <span className="excel-validation-message-notification">{data.message}</span>
                                                        </>
                                                    ) : null}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="col-md-12 mt-4">
                    {errorCount == 0 && titleRow && dataRow && titleRow.length != 0 && dataRow.length != 0 ? (
                        <Link
                            to={
                                parseFloat(totalObj.total) >= 40000
                                    ? "#"
                                    : {
                                          pathname: "/builder/snapshot",
                                          state: {
                                              fromBillUpload: true,
                                          },
                                      }
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                if (parseFloat(totalObj.total) >= 40000) {
                                    props.maxTotalRef.current.click();
                                }
                            }}
                        >
                            <button className="btn access-primary-button float-end" disabled={errorCount > 0 || errorCount == null}>
                                {errorCount > 0 || errorCount == null ? "FIX ERRORS TO CONTINUE" : "CONTINUE"}
                            </button>
                        </Link>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};
