import React, { useEffect, useState, useRef, useContext, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { ContextGlobal } from "../../contexts/ContextGlobal";
export const Feedback = (props) => {
    // const feedback = props.feedback;
    const { feedback, removeFeedback } = useContext(ContextGlobal);

    useEffect(() => {
        if (feedback.length > 0) {
            feedback.map((feed, index) => {
                setTimeout(() => { 
                    removeFeedback(index);
                }, 20000);
            })
        }
    }, [feedback]);

    return (
        <>
        {feedback.length > 0 ? (
            <div className="feedback-div">
                {feedback.map((feed, index) => (   
                    <div 
                        className={"row feedback-bottom-border" + 
                                    (feed.type == "error" ? "-error" : 
                                    feed.type == "warning" ? "-warning" : "")
                        }
                        style={{marginBottom: "10px"}}
                        key={"feedback-" + index}
                    >
                        <div className={"col-3 feedback-background" + 
                                        (feed.type == "error" ? "-error" : 
                                        feed.type == "warning" ? "-warning" : "")
                        }>
                            <FontAwesomeIcon
                                className="feedback-close"
                                icon={faTimes}
                                onClick={() => {
                                    removeFeedback(index);
                                }}
                            />
                            <FontAwesomeIcon icon={
                                feed.type == "error" ? faTimes : 
                                (feed.type == "warning" ? faExclamation : faCheck)} 
                                size="3x"
                                className={"feedback-icon" + (feed.type == "error" ? "-error" : "-warning")}
                            />
                        </div>
                        <div className="col-9"  style={{ backgroundColor: "#fff", padding: "16px" }} >
                            <p className={"card-text feedback-heading" + 
                                (feed.type == "error" ? "-error" : 
                                feed.type == "warning" ? "-warning" : "")} 
                            >{feed.heading}</p>
                            <p className={"card-text feedback-msg" + 
                                (feed.type == "error" ? "-error" : 
                                feed.type == "warning" ? "-warning" : "")}
                            >{feed.message}</p>
                        </div>
                    </div>
                ))}
            </div>
        ) : (null)}
        </>
    );
};
