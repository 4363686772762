const reducer = (state, action) => {
    switch (action.type) {
        case "SET_PACKAGES": {
            return action.payload;
        }

        case "CLEAR_ALL": {
            return [];
        }

        default: {
            return state;
        }
    }
};

export default reducer;
