import React, { useContext, useState, useEffect } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { useFormContext, Controller } from "react-hook-form";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

export const FormReviewPatientInfo = (props) => {
    const { formPatientInfo, updateFormPatientInfoSections, formPatientInfoSections, updateFormPatientInfo } = useContext(ContextGlobal);
    const methods = useFormContext();

    const [autocomplete, setAutoComplete] = useState(null);
    const [hoverPatient, setHoverPatient] = useState(false);
    const [dol, setDoL] = useState(null);

    useEffect(() => {
        if (formPatientInfo.dateOfLoss !== undefined) {
            setDoL(new Date(formPatientInfo.dateOfLoss));
            methods.setValue("dateOfLoss", formPatientInfo.dateOfLoss, {
                isDirty: true,
            });
        }
        // eslint-disable-next-line
    }, [setDoL, formPatientInfo]);

    const onLoad = (auto) => {
        setAutoComplete(auto);
    };

    const onPlaceChanged = () => {
        let lat = "";
        let long = "";
        let city = "";
        let state = "";
        let zip = "";

        if (autocomplete !== null) {
            const place = autocomplete.getPlace();

            if (place?.address_components) {
                props.setValidAddress(true);
                const address = place.address_components[0].long_name + " " + place.address_components[1].short_name;
                methods.setValue("address", address);

                place.address_components.forEach((data) => {
                    if (data.types[0] === "locality") {
                        city = data.long_name;
                        methods.setValue("city", data.long_name);
                    } else if (data.types[0] === "administrative_area_level_1") {
                        state = data.short_name;
                        methods.setValue("state", data.short_name);
                    } else if (data.types[0] === "postal_code") {
                        zip = data.long_name;
                        methods.setValue("zip", data.long_name);
                    }
                });

                if (place.geometry !== undefined) {
                    lat = place.geometry.location.lat();
                    long = place.geometry.location.lng();
                    methods.setValue("latitude", lat);
                    methods.setValue("longitude", long);
                }
                const formatted_address = address + ", " + city + ", " + state + ", " + zip;
                updateFormPatientInfo({
                    location: formatted_address,
                    address: address,
                    city: city,
                    state: state,
                    zip: zip,
                    latitude: lat,
                    longitude: long,
                });
            } else {
                // user clicked enter instead of choosing an option
                // remove text from input box
                props.setValidAddress(false);
                methods.reset({
                    location: "",
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                    latitude: "",
                    longitude: "",
                });
            }
        } else {
            // console.log("Autocomplete is not loaded yet!");
        }
    };

    return (
        <>
            <div className="row g-0">
                <div className="col-12 mt-5 mb-3">
                    <h3 style={{ display: "inline-block" }}>Patient info</h3>
                    <span
                        className="fa-layers fa-fw fa-center-icon link-primary float-end"
                        style={{ cursor: "pointer", marginRight: "0px" }}
                        onClick={(e) =>
                            !methods.formState.errors.firstName && !methods.formState.errors.lastName && !methods.formState.errors.dateOfLoss && !methods.formState.errors.sex && !methods.formState.errors.location
                                ? updateFormPatientInfoSections({
                                      patientInfo: !formPatientInfoSections.patientInfo,
                                  })
                                : ""
                        }
                        onMouseEnter={(e) => setHoverPatient(true)}
                        onMouseLeave={(e) => setHoverPatient(false)}
                    >
                        <FontAwesomeIcon style={{ display: !hoverPatient ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                        <FontAwesomeIcon icon={formPatientInfoSections.patientInfo ? faCheck : faPen} transform="shrink-8" className="share-remove" color={hoverPatient ? "white" : "#1db8ea"} />
                    </span>
                </div>
            </div>
            <div className="text-end"></div>
            <div className="row g-0">
                <div className="col-xxl-3 col-xl-6 col-12" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.patientInfo ? "0px" : "12px" }}>
                    <label htmlFor="first-name" className="form-label mb-0 text-muted">
                        <small>First Name</small>
                    </label>
                    <input type="text" className={"form-control access-input-style review-page " + (methods.formState.errors.firstName ? "is-invalid" : "")} id="first-name" defaultValue={formPatientInfo.firstName ?? ""} {...methods.register("firstName")} onChange={(e) => updateFormPatientInfo({ firstName: e.target.value })} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }} />
                    <div className="invalid-feedback">{methods.formState.errors.firstName?.message}</div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-12" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.patientInfo ? "0px" : "12px" }}>
                    <label htmlFor="last-name" className="form-label mb-0 text-muted">
                        <small>Last Name</small>
                    </label>
                    <input type="text" className={"form-control access-input-style review-page " + (methods.formState.errors.lastName ? "is-invalid" : "")} id="last-name" defaultValue={formPatientInfo.lastName ?? ""} {...methods.register("lastName")} onChange={(e) => updateFormPatientInfo({ lastName: e.target.value })} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }} />
                    {<div className="invalid-feedback">{methods.formState.errors.lastName?.message}</div>}
                </div>
                <div className="col-xxl-1 d-none d-xxl-block"></div>
                <div className="col-xxl-3 col-xl-6 col-9" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.patientInfo ? "0px" : "12px" }}>
                    <label htmlFor="date-of-loss" className="form-label mb-0 text-muted">
                        <small>Date of Loss</small>
                    </label>
                    <Controller
                        control={methods.control}
                        name="dateOfLoss"
                        render={({ field }) => (
                            <DatePicker
                                name={field.name}
                                className={"form-control access-input-style " + (methods.formState.errors.dateOfLoss ? "is-invalid" : "")}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Choose date"
                                onChange={(e) => {
                                    methods.setValue("dateOfLoss", e.toLocaleString().split(",")[0], {
                                        shouldDirty: true,
                                    });
                                    setDoL(e);
                                    updateFormPatientInfo({ dateOfLoss: e.toLocaleString().split(",")[0] });
                                }}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={dol}
                                minDate={new Date("1970-01-02")}
                                maxDate={new Date()}
                                readOnly={!formPatientInfoSections.patientInfo}
                            />
                        )}
                    />
                    {<div className="invalid-feedback">{methods.formState.errors.dateOfLoss?.message}</div>}
                </div>
                <div className="col-xxl-2 col-xl-6 col-3 ">
                    <label htmlFor="sex" className="form-label mb-0 text-muted">
                        <small>Sex</small>
                    </label>
                    <select className={"form-select access-input-style review-page " + (methods.formState.errors.sex ? "is-invalid" : "")} id="sex" {...methods.register("sex")} onChange={(e) => updateFormPatientInfo({ sex: e.target.value })} defaultValue={formPatientInfo.sex ?? "Select"} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "0px" : "12px", background: !formPatientInfoSections.patientInfo ? "none" : "" }}>
                        <option disabled>Select</option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                    </select>
                    {<div className="invalid-feedback">{methods.formState.errors.sex?.message}</div>}
                </div>
            </div>
            <div className="d-flex row mt-4">
                <label htmlFor="treatment-location" className="form-label mb-0 text-muted">
                    <small>Treatment Location</small>
                </label>
                <LoadScript googleMapsApiKey="AIzaSyDL3z1KGSpAPVawCDPTbRTKSEAjezZHd-E" libraries={libraries}>
                    <>
                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            readOnly={!formPatientInfoSections.patientInfo}
                            // fields=""
                        >
                            <>
                                <input type="text" className={"form-control access-input-style review-page " + (methods.formState.errors.location ? "is-invalid" : "")} id="treatment-location" defaultValue={formPatientInfo.location ?? ""} {...methods.register("location")} onChange={(e) => updateFormPatientInfo({ location: e.target.value })} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }} />
                                {<div className="invalid-feedback">{methods.formState.errors.location?.message}</div>}
                            </>
                        </Autocomplete>
                    </>
                </LoadScript>
                {!props.validAddress && (
                    <div className="row">
                        <div className="col-12">
                            <div className="" id="" style={{ color: "#dc3545" }}>
                                Please choose location from drop down
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* hidden fields */}
            <div>
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.address ?? ""} {...methods.register("address")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.city ?? ""} {...methods.register("city")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.state ?? ""} {...methods.register("state")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.zip ?? ""} {...methods.register("zip")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.latitude ?? ""} {...methods.register("latitude")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.longitude ?? ""} {...methods.register("longitude")} />
            </div>
        </>
    );
};
