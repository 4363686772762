import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import $ from "jquery";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const FormPassword = (props) => {
    const { login, createCode, session } = useContext(ContextGlobal);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [getCode, setGetCode] = useState(false);
    const [codeLoading, setCodeLoading] = useState(false);

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        setError: setError2,
        formState: { errors: errors2 },
    } = useForm({ mode: "onSubmit" });

    const onSubmit = async (data) => {
        // console.log("data: ", data);
        // console.log("email: ", props.email);
        // console.log("credentials: ", {...data, ...props.email})
        let res = {};
        setLoading(true);
        if (data.code) {
            res = await login({ code: data.code, ...props.email });
        } else {
            res = await login({ ...data, ...props.email });
        }
        setLoading(false);

        if (res) {
            reset2();
            props.setVerifiedEmail(false);
            $("#closelogin").trigger("click");
        } else {
            if (data.code) {
                setError2("code", { message: "Invalid code entered, try again" });
            } else {
                setError2("password", { message: "Invalid password" });
            }
        }
    };

    useEffect(() => {
        if (props.closed) {
            props.setVerifiedEmail(false);
            props.setClosed(false);
            // props.setEmail(null);
            reset2();
        }
    }, [props.closed]);

    const oneTimeCode = async () => {
        reset2();
        setGetCode(true);
        setCodeLoading(true);
        await createCode(props.email);
        setCodeLoading(false);
    };

    return (
        <form onSubmit={handleSubmit2(onSubmit)}>
            <div className="row">
                <div className="col-md-12">
                    {props.guest && !getCode ? (
                        <>
                            <span
                                style={{
                                    color: "#ce0044",
                                }}
                            >
                                <p>ATTENTION:</p> You do not have a password set on this account. To set one follow the steps by {""}
                            </span>
                            <Link to="/resetpassword" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <span style={{ color: "rgb(29, 184, 234)", cursor: "pointer" }}>clicking here</span>
                            </Link>
                            <span
                                style={{
                                    color: "#ce0044",
                                }}
                            >
                                {" "}
                                or
                            </span>
                        </>
                    ) : null}
                </div>
                <div className="col-md-12 mt-3">{!getCode ? null : <>{codeLoading ? "Code being sent to your email" : "Code has been sent, please check your email!"}</>}</div>
            </div>
            {!getCode ? (
                <>
                    {!(props.guest && !getCode) ? (
                        <>
                            <div className="row mt-1">
                                <div className="col-12">
                                    <label htmlFor="login-password" className="form-label access-input-label-style">
                                        Password
                                    </label>
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} placeholder="Password" className={"form-control access-input-style login " + (errors2.password ? "is-invalid" : "")} id="login-password" {...register2("password")} aria-describedby="password-eye" />
                                        <span className={"input-group-text show-hide-password" + (errors2.password ? "-invalid" : "")} id="password-eye">
                                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showPassword ? faEyeSlash : faEye} onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} />
                                        </span>
                                        <div className="invalid-feedback">{errors2.password?.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-1" style={{ fontSize: "12px" }}>
                                    Don't remember your password?&nbsp;
                                    <Link to="/resetpassword" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                        <span style={{ color: "rgb(29, 184, 234)", cursor: "pointer" }}>Click here.</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <button id="closelogin" type="button" data-bs-dismiss="modal" style={{ display: "none" }}>
                                        Close
                                    </button>
                                    <button className="btn access-primary-button mt-4" type="submit" style={{ width: "100%" }}>
                                        {loading ? <Oval color="white" height={20} width={20} /> : "LOGIN"}
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : null}
                </>
            ) : (
                <>
                    {codeLoading ? (
                        <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
                    ) : (
                        <>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <label htmlFor="login-code" className="form-label access-input-label-style">
                                        Code
                                    </label>
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} placeholder="Code" className={"form-control access-input-style login " + (errors2.code ? "is-invalid" : "")} id="login-code" {...register2("code")} aria-describedby="code-eye" />
                                        <span className={"input-group-text show-hide-password" + (errors2.code ? "-invalid" : "")} id="code-eye">
                                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showPassword ? faEyeSlash : faEye} onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} />
                                        </span>
                                        <div className="invalid-feedback">{errors2.code?.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-1" style={{ fontSize: "12px" }}>
                                    Need another code?&nbsp;
                                    <span style={{ color: "rgb(29, 184, 234)", cursor: "pointer" }} onClick={() => oneTimeCode()}>
                                        Click here.
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <button id="closelogin" type="button" data-bs-dismiss="modal" style={{ display: "none" }}>
                                        Close
                                    </button>
                                    <button className="btn access-primary-button mt-4" type="submit" style={{ width: "100%" }}>
                                        {loading ? <Oval color="white" height={20} width={20} /> : "LOGIN"}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            <div className="row mt-1">
                <div className="col-12">
                    {!getCode ? (
                        <>
                            {props.guest ? (
                                <button
                                    className="btn access-primary-button mt-4"
                                    type="submit"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        oneTimeCode();
                                    }}
                                >
                                    SIGN IN WITH A ONE TIME CODE
                                </button>
                            ) : (
                                <button
                                    className="btn access-primary-button mt-4"
                                    type="submit"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        oneTimeCode();
                                    }}
                                >
                                    SIGN IN WITHOUT PASSWORD
                                </button>
                            )}
                        </>
                    ) : null}
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-12">
                    <button
                        className="btn access-primary-button mt-4"
                        type="submit"
                        style={{ backgroundColor: "#0000468c", borderColor: "#00004600", width: "100%" }}
                        onClick={() => {
                            props.setVerifiedEmail(false);
                        }}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </form>
    );
};
