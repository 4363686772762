import React, { useEffect, useState } from "react";
import expertQuestions from "../../api/questions/expert.json";

const ExpertQuestions = (props) => {
    const [expert, setExpert] = useState([]);

    useEffect(() => {
        setExpert(expertQuestions);
    }, [setExpert]);

    return (
        <>
            <div id="expert"></div>
            <div className={"row g-0 mb-3 mx-2 " + (props.select == "all" ? "mt-5" : "mt-2")}  >
                <div className="col-md-12 faq-headings">
                    Expert Witnesses / Upgrading
                </div>
            </div>
            {expert.map((qa, index) => (
                <div className="row g-0 mb-4 mx-2">
                    <div className="d-flex flex-row ">
                        <div className="me-2 faq-questions" style={{ display: "inline-block" }}>
                            {"Q:"}
                        </div>
                        <div className="faq-questions" style={{ display: "inline-block" }}>{qa.question}</div>
                    </div>
                    <div className="d-flex flex-row " style={{ fontSize: "0.9em" }}>
                        <div className="me-3" style={{ display: "inline-block" }}>
                            {"A:"}
                        </div>
                        <div style={{ display: "inline-block" }}>{qa.answer}</div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ExpertQuestions;
