import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ContextGlobal } from "../../../contexts/ContextGlobal";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const FormUser = (props) => {
    const { session, formPatientInfo, updateFormPatientInfo, currentRequest } = useContext(ContextGlobal);
    const methods = useFormContext();

    useEffect(() => {
        methods.setValue("lawFirm", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.lawFirm : session.lawFirm);
        methods.setValue("userFirstName", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.userFirstName : session.firstName);
        methods.setValue("userLastName", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.userLastName : session.lastName);
        methods.setValue("phoneNumber", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.phoneNumber : session.phone);
        methods.setValue("email", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.email : session.email);
        // eslint-disable-next-line
    }, []);

    const query = useQuery();

    useEffect(() => {
        if (query.get("lawFirm")) {
            methods.setValue("lawFirm", query.get("lawFirm"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ lawFirm: query.get("lawFirm") });
        }
        if (query.get("userFirstName")) {
            methods.setValue("userFirstName", query.get("userFirstName"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ userFirstName: query.get("userFirstName") });
        }
        if (query.get("userLastName")) {
            methods.setValue("userLastName", query.get("userLastName"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ userLastName: query.get("userLastName") });
        }
        if (query.get("phoneNumber")) {
            methods.setValue("phoneNumber", query.get("phoneNumber"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ phoneNumber: query.get("phoneNumber") });
        }
        if (query.get("email")) {
            methods.setValue("email", query.get("email"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ email: query.get("email") });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <h3 className="mb-4">Your info</h3>
            <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <label htmlFor="law-firm" className="form-label access-input-label-style">
                        Company{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input
                        type="text"
                        className={"form-control access-input-style " + (methods.formState.errors.lawFirm ? "is-invalid" : "")}
                        id="law-firm"
                        placeholder={"Company"}
                        defaultValue={formPatientInfo.lawFirm ?? ""}
                        {...methods.register("lawFirm")}
                        readOnly={session.lawFirm ? (currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.lawFirm : session.lawFirm ?? formPatientInfo.lawFirm) : false}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                lawFirm: e.target.value,
                            })
                        }
                    />
                    <div className="invalid-feedback">{methods.formState.errors.lawFirm?.message}</div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6 col-12 mt-sm-0 mt-3">
                    <label htmlFor="phone-number" className="form-label access-input-label-style">
                        Phone Number
                    </label>
                    <input
                        type="tel"
                        placeholder={"000-000-0000"}
                        className="form-control access-input-style "
                        id="phone-number"
                        defaultValue={formPatientInfo.phoneNumber ?? ""}
                        {...methods.register("phoneNumber")}
                        readOnly={session.phone ? (currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.phoneNumber : session.phone) : false}
                        onChange={(e) => {
                            var x = e.target.value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                            e.target.value = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? " " + x[3] : "");
                            updateFormPatientInfo({ phoneNumber: e.target.value });
                        }}
                    />
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-xl-3 col-lg-6 col-md-3 col-12">
                    <label htmlFor="user-first-name" className="form-label access-input-label-style">
                        First Name{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input
                        type="text"
                        className={"form-control access-input-style " + (methods.formState.errors.userFirstName ? "is-invalid" : "")}
                        placeholder={"First Name"}
                        id="user-first-name"
                        defaultValue={formPatientInfo.userFirstName ?? ""}
                        {...methods.register("userFirstName")}
                        readOnly={session.firstName ? (currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.userFirstName : session.firstName) : false}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                userFirstName: e.target.value,
                            })
                        }
                    />
                    <div className="invalid-feedback">{methods.formState.errors.userFirstName?.message}</div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-3 col-12 mt-sm-0 mt-3">
                    <label htmlFor="user-last-name" className="form-label access-input-label-style">
                        Last Name{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input
                        type="text"
                        className={"form-control access-input-style " + (methods.formState.errors.userFirstName ? "is-invalid" : "")}
                        placeholder={"Last Name"}
                        id="user-last-name"
                        defaultValue={formPatientInfo.userLastName ?? ""}
                        {...methods.register("userLastName")}
                        readOnly={session.firstName ? (currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.userLastName : session.firstName) : false}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                userLastName: e.target.value,
                            })
                        }
                    />
                    <div className="invalid-feedback">{methods.formState.errors.userLastName?.message}</div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6 col-12 mt-sm-0 mt-3">
                    <label htmlFor="email" className="form-label access-input-label-style">
                        Email{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input
                        type="email"
                        placeholder={"Email"}
                        className={"form-control access-input-style " + (methods.formState.errors.email ? "is-invalid" : "")}
                        id="email"
                        defaultValue={formPatientInfo.email ?? ""}
                        {...methods.register("email")}
                        readOnly={session.email ? (currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.email : session.email) : false}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                email: e.target.value,
                            })
                        }
                    />
                    <div className="invalid-feedback">{methods.formState.errors.email?.message}</div>
                    {!session.email ? (
                        <div className="" style={{ fontSize: "12px" }}>
                            <span>
                                Have an account?{" "}
                                <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#login"
                                    style={{
                                        color: "#1db8ea",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                >
                                    Login
                                </span>{" "}
                                to fill in your info.
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};
