import React, { useEffect, useState, useContext } from "react";

import { ContextGlobal } from "../../contexts/ContextGlobal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";

export const UpdatePassword = (props) => {
    const { updatePassword } = useContext(ContextGlobal);

    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required().label("Current Password"),
        password: Yup.string().required().label("New Password"),
        confirmPassword: Yup.string().required().label("Confirm Password"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
    } = useForm({
        mode: "onSubmit",
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    });

    const [showCurrent, setShowCurrent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [newPassword, setNewPassword] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);
    // const [failed, setFailed] = useState(false);

    useEffect(() => {
        if (props.resetForm) {
            setValue("old_password", "");
            setValue("password", "");
            setValue("confirmPassword", "");
            setUpdated(false);
            props.setResetForm(false);
        }
    }, [props.resetForm]);

    const onSubmit = async (data) => {
        setConfirm(false);
        if (data.confirmPassword === data.password) {
            setLoading(true);
            const res = await updatePassword({
                old_password: data.old_password,
                password: data.password,
            });
            setLoading(false);
            if (res) {
                setValue("old_password", "");
                setValue("password", "");
                setValue("confirmPassword", "");
                setUpdated(true);
            } else {
                setError("old_password", { type: "manual", message: "Make sure your current password is correct" });
                setUpdated(false);
            }
        } else {
            setError("password");
            setError("confirmPassword");
            setConfirm(true);
        }
    };

    return (
        <div className="modal fade" id="updatePassword" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-eula-sign">
                    <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" />
                    <div className="modal-header" style={{ borderBottom: "none", padding: 0 }}>
                        <h3 className="modal-title w-100 text-center" style={{ fontWeight: "700", color: "#000064" }}>
                            Update password
                        </h3>
                    </div>
                    <div className="modal-body">
                        {!updated ? (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12">
                                        <label style={{ marginBottom: "0.2rem" }} className="form-label access-input-label-style">
                                            Current Password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                placeholder="Current Password"
                                                type={showCurrent ? "text" : "password"}
                                                className={"form-control access-input-style " + (errors.old_password ? "is-invalid" : "")}
                                                id="password"
                                                defaultValue={""}
                                                autocomplete="new-password"
                                                {...register("old_password")}
                                                // onChange={(e) => setNewPassword(e.target.value )}
                                                aria-describedby="old-password-eye"
                                            />
                                            <span className={"input-group-text show-hide-password" + (errors.old_password ? "-invalid" : "")} id="old-password-eye">
                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showCurrent ? faEyeSlash : faEye} onClick={() => (showCurrent ? setShowCurrent(false) : setShowCurrent(true))} />
                                            </span>
                                            <div className="invalid-feedback">{errors.old_password?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 pass-wrapper">
                                        <label style={{ marginBottom: "0.2rem" }} className="form-label access-input-label-style">
                                            New Password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                placeholder="New Password"
                                                type={showPassword ? "text" : "password"}
                                                className={"form-control access-input-style " + (errors.password ? "is-invalid" : "")}
                                                id="password"
                                                defaultValue={""}
                                                autocomplete="new-password"
                                                {...register("password")}
                                                aria-describedby="new-password-eye"
                                                // onChange={(e) => setNewPassword(e.target.value )}
                                            />
                                            <span className={"input-group-text show-hide-password" + (errors.password ? "-invalid" : "")} id="new-password-eye">
                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showPassword ? faEyeSlash : faEye} onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} />
                                            </span>
                                            <div className="invalid-feedback">{errors.password?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 pass-wrapper">
                                        <label style={{ marginBottom: "0.2rem" }} className="form-label access-input-label-style">
                                            Confirm Password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                placeholder="Confirm Password"
                                                type={showConfirm ? "text" : "password"}
                                                className={"form-control access-input-style " + (errors.confirmPassword ? "is-invalid" : "")}
                                                id="confirm-password"
                                                defaultValue={""}
                                                autocomplete="new-password"
                                                {...register("confirmPassword")}
                                                aria-describedby="confirm-password-eye"
                                                // onChange={(e) => handleCompare(e.target.value )}
                                            />
                                            <span className={"input-group-text show-hide-password" + (errors.confirmPassword ? "-invalid" : "")} id="confirm-password-eye">
                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showConfirm ? faEyeSlash : faEye} onClick={() => (showConfirm ? setShowConfirm(false) : setShowConfirm(true))} />
                                            </span>
                                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                        </div>
                                        {confirm ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "0.95em",
                                                    color: "#bb0046",
                                                }}
                                            >
                                                Both passwords must match!
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <button className="btn access-primary-button" style={{ width: "100%" }}>
                                            {loading ? <Oval color="white" height={20} width={20} /> : "UPDATE"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <div className="row">
                                <div className="col-12 text-center">Successfully updated password!</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
