import React, { useRef, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";
import { SwitchProductButton } from "../Buttons/SwitchProductButton.js";

export const SOSReviewModal = (props) => {

    // const emailRef = useRef(null);
    const faqRef = useRef(null);
    const sheetRef = useRef(null);

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    useLayoutEffect(() => {
        // emailRef.current.style.setProperty("font-size", "10pt", "important");
        faqRef.current.style.setProperty("font-size", "10pt", "important");
        if (!phoneSize) {
            sheetRef.current.style.setProperty("font-size", "10pt", "important");
        } else {
            sheetRef.current.style.setProperty("font-size", "9pt", "important");
        }
    }, []);

    return (
        <>
            {/* will need to be fixed up for mobile */}
            <div className="modal fade" id="sos" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "700px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            className="card-close"
                            data-bs-dismiss="modal"
                        />
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h4
                                className={"modal-title w-100 " + (phoneSize ? "" : "text-center")}
                                style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                            >
                                Are you stuck? Have a quick question?
                                <br />
                                Just want to chat with some rad people?
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <h4
                                        style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                                        className="modal-title w-100 text-center"
                                    >
                                        We're here to help!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    {props.pro ? (
                                        <>
                                            <div className="d-flex justify-content-center mb-4">
                                                {/* would also neeed to be a patch() call */}
                                                <SwitchProductButton 
                                                    product={"type_product_bill_analysis_with_code_review_expert"}
                                                    name={"Expert Bill Analysis + Code Review"}
                                                    link={"/builder/expert_bill_analysis_code_review"}
                                                />
                                            </div>
                                        </>
                                    ) : (null)}
                                    {props.expert ? (
                                        <>
                                            <div className="d-flex justify-content-center mb-4">
                                                {/* would also neeed to be a patch() call */}
                                                <SwitchProductButton 
                                                    product={"type_product_bill_analysis_with_code_review_pro"}
                                                    name={"Pro Bill Analysis + Code Review"}
                                                    link={"/builder/pro_bill_analysis_code_review"}
                                                />
                                            </div>
                                        </>
                                    ) : (null)}
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="d-flex justify-content-center"
                                    style={{ font: "700 1.5rem Montserrat", color: "#000064" }}
                                >
                                    or
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center flex-fill">
                                        <a href="/contact-us" target="_blank" rel="noreferrer noopener" className="btn access-secondary-button" style={{ width: "100%" }}>
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center">
                                        <a
                                            href="/faq"
                                            target="_blank"
                                            className="btn access-secondary-button"
                                            type="button"
                                            ref={faqRef}
                                            style={{ width: "100%" }}
                                        >
                                            Visit our FAQ page
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center">
                                        <a 
                                            ref={sheetRef}
                                            type="button"
                                            href="/files/AccuMed Pricing Sheet - June 2023.pdf"
                                            target="_blank" rel="noopener noreferrer"
                                            className="btn access-secondary-button" 
                                            style={{ textDecoration: "none", color: "white", width: "100%"}}
                                        >
                                            Download our product comparison sheet
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div
                                style={{ font: "600 0.8rem Montserrat" }}
                                className="d-flex justify-content-center mt-4"
                            >
                                (844) 307 4487
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center">
                                Mon-Fri: 8am - 5:30pm MT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
