import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircle } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { ContextGlobal } from "../../../contexts/ContextGlobal";

export const UploadDocuments = (props) => {
    const { uploadFiles, deleteFile, uploadProgress } = useContext(ContextGlobal);

    const [files, setFiles] = useState([]);
    const [allFiles, setAllFiles] = useState([]);
    const [completedFiles, setCompletedFiles] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: async (acceptedFiles) => {
            const fileList = [];
            setAllFiles([...allFiles, ...acceptedFiles]);
            props.setUploadCompleted(false);
            for (const file of acceptedFiles) {
                fileList.push(file);
                setFiles([...files, ...fileList]);
                const res = await uploadFiles(file);
                // failed file upload
                if (!res.success) {
                    // remove from fileList
                    let filtered = fileList.filter((uploaded) => uploaded.path !== file.path);
                    setFiles(filtered);
                    setAllFiles(filtered);
                }
            }
            props.setUploadCompleted(true);
        },
    });

    useEffect(() => {
        if (uploadProgress.progress >= 100) {
            setCompletedFiles([...completedFiles, uploadProgress.name]);
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (allFiles.length > 0 && completedFiles.length == allFiles.length) {
            props.setUploadCompleted(true);
        }
    }, [completedFiles]);

    const [fileHover, setFileHover] = useState(null);

    const handleDeleteFile = (path) => {
        deleteFile(path);
        let filtered = files.filter((file) => file.path !== path);
        setFiles(filtered);
        setAllFiles(filtered);
    };

    return (
        <div className="row g-0 mb-4" style={{ marginTop: "60px" }}>
            <h3>Upload documents</h3>
            <div
                {...getRootProps({
                    className: "dropzone",
                })}
                className="col-12 p-4 mt-4 text-center"
                style={{
                    border: "1px dashed #1db8ea",
                    borderRadius: 10,
                }}
            >
                <input {...getInputProps()} />
                <h3 className="text-muted" style={{ fontSize: "12pt" }}>
                    DROP FILE
                    <br />
                    or
                </h3>
                <button
                    type="button"
                    onClick={open}
                    className="btn access-secondary-button"
                    // style={{ width: 170 }}
                >
                    Browse Computer
                </button>
            </div>
            <aside className="mt-4">
                {allFiles.map((file) => (
                    <>
                        <div className="row" key={file.path}>
                            <div className="col-10">
                                <p>{file.path}</p>
                            </div>
                            <div className="col-2">
                                <span
                                    className="fa-layers fa-fw fa-center-icon"
                                    style={{
                                        fontSize: "1.5em",
                                        cursor: "pointer",
                                        float: "right",
                                    }}
                                    onClick={(e) => handleDeleteFile(file.path)}
                                    onMouseEnter={(e) => setFileHover(file.path)}
                                    onMouseLeave={(e) => setFileHover(null)}
                                >
                                    <FontAwesomeIcon
                                        style={{
                                            display: fileHover === file.path ? "" : "none",
                                        }}
                                        icon={faCircle}
                                        color="#1db8ea"
                                    />
                                    <FontAwesomeIcon icon={faTimes} transform="shrink-5" className="share-remove" color={fileHover === file.path ? "white" : "#bb0046"} />
                                </span>
                            </div>
                        </div>
                        <div className="progress mb-4">
                            {file.path == uploadProgress.name ? (
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuenow={uploadProgress.progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                        width: uploadProgress.progress + "%",
                                    }}
                                >
                                    {uploadProgress.progress + "%"}
                                </div>
                            ) : completedFiles.includes(file.path) ? (
                                <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    100%
                                </div>
                            ) : (
                                <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                        width: "0",
                                    }}
                                >
                                    0%
                                </div>
                            )}
                        </div>
                    </>
                ))}
            </aside>
        </div>
    );
};
