import React, { useContext, useState, useEffect } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import DatePicker from "react-datepicker";
import { useFormContext, Controller } from "react-hook-form";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import Map from "../../Map/Map";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const libraries = ["places"];

export const FormPatient = (props) => {
    const { formPatientInfo, updateFormPatientInfo } = useContext(ContextGlobal);
    const methods = useFormContext();

    const [autocomplete, setAutoComplete] = useState(null);
    const [latLong, setLatLong] = useState({
        lat: formPatientInfo.latitude === undefined || formPatientInfo.latitude === "" ? 39.7392 : formPatientInfo.latitude,
        long: formPatientInfo.longitude === undefined || formPatientInfo.longitude === "" ? -104.9903 : formPatientInfo.longitude,
    });
    const [dol, setDoL] = useState(null);

    useEffect(() => {
        if (formPatientInfo.dateOfLoss !== undefined) {
            setDoL(new Date(formPatientInfo.dateOfLoss));
            methods.setValue("dateOfLoss", formPatientInfo.dateOfLoss, {
                isDirty: true,
            });
        }
        // eslint-disable-next-line
    }, [setDoL, formPatientInfo]);

    const query = useQuery();

    useEffect(() => {
        if (query.get("firstName")) {
            methods.setValue("firstName", query.get("firstName"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ firstName: query.get("firstName") });
        }
        if (query.get("lastName")) {
            methods.setValue("lastName", query.get("lastName"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ lastName: query.get("lastName") });
        }
        if (query.get("dateOfLoss")) {
            setDoL(new Date(query.get("dateOfLoss")));
            methods.setValue("dateOfLoss", query.get("dateOfLoss"), {
                isDirty: true,
            });
            updateFormPatientInfo({ dateOfLoss: query.get("dateOfLoss") });
        }
        if (query.get("sex")) {
            methods.setValue("sex", query.get("sex"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ sex: query.get("sex") });
        }
        if (query.get("location")) {
            methods.setValue("location", query.get("location"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ location: query.get("location") });
        }
        if (query.get("address")) {
            methods.setValue("address", query.get("address"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ address: query.get("address") });
        }
        if (query.get("city")) {
            methods.setValue("city", query.get("city"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ city: query.get("city") });
        }
        if (query.get("state")) {
            methods.setValue("state", query.get("state"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ state: query.get("state") });
        }
        if (query.get("zip")) {
            methods.setValue("zip", query.get("zip"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ zip: query.get("zip") });
        }
        if (query.get("latitude")) {
            methods.setValue("latitude", query.get("latitude"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ latitude: query.get("latitude") });
        }
        if (query.get("longitude")) {
            methods.setValue("longitude", query.get("longitude"), {
                shouldDirty: true,
            });
            updateFormPatientInfo({ longitude: query.get("longitude") });
        }
        // eslint-disable-next-line
    }, []);

    const onLoad = (auto) => {
        setAutoComplete(auto);
    };

    const onPlaceChanged = () => {
        let lat = "";
        let long = "";
        let city = "";
        let state = "";
        let zip = "";

        if (autocomplete !== null) {
            const place = autocomplete.getPlace();

            if (place?.address_components) {
                props.setValidAddress(true);
                const address = place.address_components[0].long_name + " " + place.address_components[1].short_name;
                methods.setValue("address", address);

                place.address_components.forEach((data) => {
                    if (data.types[0] === "locality") {
                        city = data.long_name;
                        methods.setValue("city", data.long_name);
                    } else if (data.types[0] === "administrative_area_level_1") {
                        state = data.short_name;
                        methods.setValue("state", data.short_name);
                    } else if (data.types[0] === "postal_code") {
                        zip = data.long_name;
                        methods.setValue("zip", data.long_name);
                    }
                });

                if (place.geometry !== undefined) {
                    lat = place.geometry.location.lat();
                    long = place.geometry.location.lng();
                    setLatLong({
                        lat: lat,
                        long: long,
                    });
                    methods.setValue("latitude", lat);
                    methods.setValue("longitude", long);
                }
                const formatted_address = address + ", " + city + ", " + state + ", " + zip;
                updateFormPatientInfo({
                    location: formatted_address,
                    address: address,
                    city: city,
                    state: state,
                    zip: zip,
                    latitude: lat,
                    longitude: long,
                });
            } else {
                // user clicked enter instead of choosing an option
                // remove text from input box
                props.setValidAddress(false);
                methods.reset({
                    location: "",
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                    latitude: "",
                    longitude: "",
                });
            }
        } else {
            // console.log("Autocomplete is not loaded yet!");
        }
    };

    return (
        <>
            <h3 className="" style={{ marginTop: "40px" }}>
                Patient info
            </h3>
            <div className="row row-cols-auto">
                {/* First name */}
                <div className="col-md-6 col-12 mt-2 mb-3">
                    <label htmlFor="first-name" className="form-label access-input-label-style">
                        Patient First Name{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input type="text" className={"form-control access-input-style review-page " + (methods.formState.errors.firstName ? "is-invalid" : "")} id="first-name" placeholder="Patient's First Name" defaultValue={formPatientInfo.firstName ?? ""} {...methods.register("firstName")} onChange={(e) => updateFormPatientInfo({ firstName: e.target.value })} />
                    <div className="invalid-feedback">{methods.formState.errors.firstName?.message}</div>
                </div>
                {/* Last Name */}
                <div className="col-md-6 col-12 mt-2 mb-3">
                    <label htmlFor="last-name" className="form-label access-input-label-style">
                        Patient Last Name{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input type="text" className={"form-control access-input-style review-page " + (methods.formState.errors.lastName ? "is-invalid" : "")} id="last-name" placeholder="Patient's Last Name" defaultValue={formPatientInfo.lastName ?? ""} {...methods.register("lastName")} onChange={(e) => updateFormPatientInfo({ lastName: e.target.value })} />
                    <div className="invalid-feedback">{methods.formState.errors.lastName?.message}</div>
                </div>
                {props.warning ? (
                    <div className="col-md-12">
                        <div className="alert alert-warning">{props.historical ? <>Attention: The patient's name is the same as the user's name, if this is CORRECT you may continue by clicking the submit button again.</> : <>Attention: The patient's name is the same as the user's name, if this is CORRECT you may continue by clicking the save button again.</>}</div>
                    </div>
                ) : null}
                {/* Date of Loss */}
                <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="date-of-loss" className="form-label access-input-label-style">
                        Date of Loss{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <Controller
                        control={methods.control}
                        name="dateOfLoss"
                        render={({ field }) => (
                            <DatePicker
                                name={field.name}
                                className={"form-control access-input-style " + (methods.formState.errors.dateOfLoss ? "is-invalid" : "")}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Choose date"
                                onChange={(e) => {
                                    methods.setValue("dateOfLoss", e.toLocaleString().split(",")[0], {
                                        shouldDirty: true,
                                    });
                                    setDoL(e);
                                    updateFormPatientInfo({
                                        dateOfLoss: e.toLocaleString().split(",")[0],
                                    });
                                    methods.trigger("dateOfLoss");
                                }}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={dol}
                                minDate={new Date("1970-01-02")}
                                maxDate={new Date()}
                                ref={field.ref}
                            />
                        )}
                    />
                    {methods.formState.errors.dateOfLoss && <div className="controller-invalid-feedback">{methods.formState.errors.dateOfLoss?.message}</div>}
                </div>
                {/* Sex */}
                <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="sex" className="form-label access-input-label-style">
                        Sex{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <select
                        className={"form-select access-input-style " + (methods.formState.errors.sex ? "is-invalid" : "")}
                        id="sex"
                        {...methods.register("sex")}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                sex: e.target.value,
                            })
                        }
                        defaultValue={formPatientInfo.sex ?? "Select"}
                    >
                        <option disabled>{"Select"}</option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                    </select>
                    <div className="invalid-feedback">{methods.formState.errors.sex?.message}</div>
                </div>
            </div>
            {/* Patient Address */}
            <div
                className="mb-3"
                style={{ marginBottom: "40px" }}
                data-tip
                data-for="treatment-location-tooltip"
                // data-event="mouseenter"
                data-event-off="mouseleave"
            >
                <label htmlFor="treatment-location" className="form-label access-input-label-style">
                    Patient Address{" "}
                    <sup className="ms-1" style={{ color: "red" }}>
                        *
                    </sup>
                </label>
                <LoadScript googleMapsApiKey="AIzaSyDL3z1KGSpAPVawCDPTbRTKSEAjezZHd-E" libraries={libraries}>
                    <>
                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            // fields=""
                        >
                            <>
                                <input
                                    type="text"
                                    className={"form-control access-input-style " + (methods.formState.errors.location ? "is-invalid" : "")}
                                    id="treatment-location"
                                    defaultValue={formPatientInfo.location ?? ""}
                                    {...methods.register("location")}
                                    placeholder="Enter patient's home address for most accurate results"
                                    // onChange={(e) => updateFormPatientInfo({ location: e.target.value })}
                                    aria-describedby="location_help_block"
                                />
                                <div className="invalid-feedback">{methods.formState.errors.location?.message}</div>
                            </>
                        </Autocomplete>
                    </>
                </LoadScript>
                {!props.validAddress && (
                    <div className="row">
                        <div className="col-12">
                            <div className="" id="" style={{ color: "#dc3545" }}>
                                Please choose location from drop down
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* hidden fields */}
            <div>
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.address ?? ""} {...methods.register("address")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.city ?? ""} {...methods.register("city")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.state ?? ""} {...methods.register("state")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.zip ?? ""} {...methods.register("zip")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.latitude ?? ""} {...methods.register("latitude")} />
                <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.longitude ?? ""} {...methods.register("longitude")} />
            </div>
            <div className="mt-5 card d-flex align-items-center" style={{ width: "100%", height: 200 }}>
                <Map latLong={latLong} />
            </div>
        </>
    );
};
