import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaPredicate } from "react-media-hook";

export const FormFrequencyDuration = (props) => {
    const { requestPackages, updateRequestPackage } = useContext(ContextGlobal);

    const validationSchema = Yup.object().shape({
        quantity: Yup.number().typeError("Quantity must be a number").required().min(1).label("Quantity"),
        frequencyMultiplier: Yup.number().typeError("Frequency must be a number").required().min(1).label("Frequency"),
        frequency: Yup.string()
            .min(1, "Choose a time frame")
            .when("frequencyMultiplier", {
                is: (frequencyMultiplier) => frequencyMultiplier > 1,
                then: Yup.string().required().label("Frequency Multiplier"),
            }),
        duration: Yup.number().typeError("Duration must be a number").required().min(1).label("Duration"),
        period: Yup.string()
            .min(1, "Choose a time frame")
            .when("duration", {
                is: (duration) => duration > 1,
                then: Yup.string().required().label("Period"),
            }),
    });

    const requestPackage = requestPackages.find((row) => row.request_package.requestPackageID === props.requestPackageID);

    const {
        register,
        trigger,
        formState: { errors },
        watch,
    } = useForm({ mode: "onBlur", resolver: yupResolver(validationSchema) });
    const midSizeScreen = useMediaPredicate("(max-width: 1250px)");

    const watchFields = watch(["frequencyMultiplier", "duration"]);
    console.log("watch: ", watchFields);

    const [frequencyWarning, setFrequencyWarning] = useState(false);
    const [durationWarning, setDurationWarning] = useState();

    useEffect(() => {
        if (watchFields[0] > 1) {
            setFrequencyWarning(true);
        } else {
            setFrequencyWarning(false);
        }
    }, watchFields[0]);

    useEffect(() => {
        if (watchFields[1] > 1) {
            setDurationWarning(true);
        } else {
            setDurationWarning(false);
        }
    }, watchFields[1]);

    return (
        <form>
            {!midSizeScreen && (
                <div className="row">
                    <div className="col-md-4">Occurs</div>
                    <div className="col-md-4">Every</div>
                    <div className="col-md-4">For</div>
                </div>
            )}
            <div className="row">
                <div className="col-xl-4 col-12">
                    <div className="input-group mb-3">
                        {midSizeScreen && (
                            <label className="col-form-label" htmlFor="quantity" style={{ marginRight: 20 }}>
                                Occurs
                            </label>
                        )}
                        <input
                            type="number"
                            min={1}
                            className={"form-control access-freq-input-style  " + (errors.quantity || !requestPackage.request_package.quantity ? "is-invalid" : "")}
                            defaultValue={requestPackage.request_package.quantity ?? 1}
                            {...register("quantity")}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    updateRequestPackage({
                                        requestPackageID: props.requestPackageID,
                                        quantity: e.target.value,
                                    });
                                }
                            }}
                        />
                        <span className="input-group-text" style={{ border: "none", backgroundColor: "inherit" }}>
                            {requestPackage.request_package.quantity > 1 ? "times" : "time"}
                        </span>
                        <div className="invalid-feedback">{errors.quantity?.message ?? requestPackage.request_package.quantity === 0 ? "Number of occurences cannot be 0" : ""}</div>
                    </div>
                </div>
                <div className="col-xl-4 col-12">
                    <div className="input-group mb-3">
                        {midSizeScreen && (
                            <label className="col-form-label" htmlFor="frequencyMultiplier" style={{ marginRight: 20 }}>
                                Every&nbsp;&nbsp;&nbsp;
                            </label>
                        )}
                        <input
                            type="number"
                            min={1}
                            className={"form-control access-freq-input-style  " + (errors.frequencyMultiplier || !requestPackage.request_package.quantity ? "is-invalid" : "")}
                            defaultValue={requestPackage.request_package.frequencyMultiplier ?? 1}
                            {...register("frequencyMultiplier")}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    updateRequestPackage({
                                        requestPackageID: props.requestPackageID,
                                        frequencyMultiplier: e.target.value,
                                    });
                                    trigger("frequency");
                                    trigger("duration");
                                }
                            }}
                        />
                        <select
                            className={"form-select " + (errors.frequency || !requestPackage.request_package.quantity ? "is-invalid" : "")}
                            {...register("frequency", { required: true })}
                            onChange={(e) =>
                                updateRequestPackage({
                                    requestPackageID: props.requestPackageID,
                                    frequency: e.target.value,
                                })
                            }
                            defaultValue={requestPackage.request_package.frequency ?? "day"}
                            style={{ border: "none", backgroundColor: "inherit" }}
                        >
                            <option value="">select</option>
                            <option value="day">{requestPackage.request_package.frequencyMultiplier > 1 ? "days" : "day"}</option>
                            <option value="week">{requestPackage.request_package.frequencyMultiplier > 1 ? "weeks" : "week"}</option>
                            <option value="month">{requestPackage.request_package.frequencyMultiplier > 1 ? "months" : "month"}</option>
                            <option value="quarter">{requestPackage.request_package.frequencyMultiplier > 1 ? "quarters" : "quarter"}</option>
                            <option value="year">{requestPackage.request_package.frequencyMultiplier > 1 ? "years" : "year"}</option>
                        </select>
                        <div className="invalid-feedback">{(errors.frequency?.message ?? "") + (errors.frequency?.message ? ". " : "") + (errors.frequencyMultiplier?.message ?? "")}</div>
                    </div>
                </div>
                <div className="col-xl-4 col-12">
                    <div className="input-group mb-3">
                        {midSizeScreen && (
                            <label className="col-form-label" htmlFor="duration" style={{ marginRight: 20 }}>
                                For&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>
                        )}
                        <input
                            type="number"
                            id="duration"
                            min={1}
                            className={"form-control access-freq-input-style  " + (errors.duration || !requestPackage.request_package.quantity ? "is-invalid" : "")}
                            defaultValue={requestPackage.request_package.duration ?? 1}
                            {...register("duration")}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    updateRequestPackage({
                                        requestPackageID: props.requestPackageID,
                                        duration: e.target.value,
                                    });
                                    trigger("period");
                                    trigger("frequency");
                                }
                            }}
                        />
                        <select
                            className={"form-select " + (errors.period || !requestPackage.request_package.quantity ? "is-invalid" : "")}
                            {...register("period")}
                            onChange={(e) =>
                                updateRequestPackage({
                                    requestPackageID: props.requestPackageID,
                                    period: e.target.value,
                                })
                            }
                            defaultValue={requestPackage.request_package.period ?? "day"}
                            style={{ border: "none", backgroundColor: "inherit" }}
                        >
                            <option value="">select</option>
                            <option value="day">{requestPackage.request_package.duration > 1 ? "days" : "day"}</option>
                            <option value="week">{requestPackage.request_package.duration > 1 ? "weeks" : "week"}</option>
                            <option value="month">{requestPackage.request_package.duration > 1 ? "months" : "month"}</option>
                            <option value="quarter">{requestPackage.request_package.duration > 1 ? "quarters" : "quarter"}</option>
                            <option value="year">{requestPackage.request_package.duration > 1 ? "years" : "year"}</option>
                        </select>
                        <div className="invalid-feedback">{(errors.period?.message ?? "") + (errors.period?.message ? ". " : "") + (errors.duration?.message ?? "")}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-12"></div>
                <div className="col-xl-4 col-12">
                    {frequencyWarning ? (
                        <div className="alert alert-warning" style={{ fontSize: "14px", padding: "0.5rem 0.5rem" }}>
                            Frequency changed. If "day" is correct you can leave as is
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="col-xl-4 col-12">
                    {durationWarning ? (
                        <div className="alert alert-warning" style={{ fontSize: "14px", padding: "0.5rem 0.5rem" }}>
                            Duration changed. If "day" is correct you can leave as is
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div>
                <button
                    className="btn access-secondary-button"
                    type="button"
                    // data-bs-toggle="collapse"
                    data-bs-target={"#frequencyForm-" + props.requestPackageID}
                    aria-expanded="false"
                    aria-controls={"#frequencyForm-" + props.requestPackageID}
                    onClick={(e) => props.setOccurrenceExpand()}
                >
                    Done
                </button>
            </div>
        </form>
    );
};
