import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const FormSetPassword = (props) => {
    const { login, session, updateUser, sendConfirmEmail } = useContext(ContextGlobal);
    const history = useNavigate();

    const validationSchema = Yup.object().shape({
        password: Yup.string().required().label("Password"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data) => {
        setLoading(true);
        const user = await updateUser({ password: data.password });
        reset();
        await login({ email: user.email, password: data.password });
        setLoading(false);
        const confirm = await sendConfirmEmail({
            email: user.email,
            type: "email_confirmation",
        });
        if (!confirm) {
            history("/");
        }
    };

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (session.email) {
            $("#close").trigger("click");
        }
    }, [session]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-0 justify-content-center" style={{ color: "#455E89" }}>
                An account has been created for you, please add a password to complete set up.
            </div>
            <div className="row g-0 mt-4">
                <label htmlFor="password" className="form-label access-input-label-style">
                    Password
                </label>
                <div className="input-group">
                    <input type={showPassword ? "text" : "password"} placeholder="Password" className={"access-input-style form-control " + (errors.password ? "is-invalid" : "")} id="password" {...register("password")} />
                    <span className={"input-group-text show-hide-password" + (errors.password ? "-invalid" : "")} id="password-eye">
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showPassword ? faEyeSlash : faEye} onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} />
                    </span>
                </div>
                <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
            <div className="row g-0 mt-4">
                <button id="close" type="button" data-bs-dismiss="modal" style={{ display: "none" }}>
                    Close
                </button>
                <button className="btn access-primary-button" type="submit">
                    {loading ? <Oval color="white" height={20} width={20} /> : "REGISTER"}
                </button>
            </div>
        </form>
    );
};
