import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { SendResetEmail } from "./SendResetEmail.js";
import { ResetPassword } from "./ResetPassword.js";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const AuthWrapper = () => {
    const [emailReset, setEmailReset] = useState(null);
    const [token, setToken] = useState();

    const location = useLocation();
    const query = useQuery();

    useEffect(() => {
        if (location.search !== "") {
            const token = query.get("token");
            setToken(token);
            setEmailReset(true);
        } else {
            setEmailReset(false);
        }
    }, [location]);

    return (
        <>
            {emailReset == true ? <ResetPassword token={token} /> : null}
            {emailReset == false ? <SendResetEmail /> : null}
        </>
    );
};
