import React, { useState, useContext } from "react";

import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import { useMediaPredicate } from "react-media-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { ContextGlobal } from "../contexts/ContextGlobal";

export const Footer = (props) => {
    const { reachOut, activePage } = useContext(ContextGlobal);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({ mode: "onSubmit" });

    const [loading, setLoading] = useState(false);

    const onSubmitEmail = async (data) => {
        // -- Constant Contact Form --
        const emailCC = document.getElementById("email_address_1");
        emailCC.value = data.email;
        document.querySelector("div.ct-footer-form button[className='ctct-form-button']").click();

        setLoading(true);
        data.reachOut = true;
        await reachOut(data);
        reset({
            email: "",
        });
        setLoading(false);
    };

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    if (activePage.portal) {
        return <></>;
    }

    return (
        <footer className="">
            <div className="row transparent">
                <div className="col-md-1"></div>
                <div className="col-md-10 px-4 mt-5">
                    <div className="row">
                        {/* <div className="col-6 col-md-2 mb-3">
                            <h6 style={{ color: '#1db8ea' }}>About Us</h6>
                            <ul className="nav flex-column">
                                <li className="mb-2" style={{ fontSize: "14px" }}><a href="/about-us#who" className="p-0 footer-line-item">Who We Are</a></li>
                                <li className="mb-2" style={{ fontSize: "14px" }}><a href="/about-us#what" className="p-0 footer-line-item">What We Do</a></li>
                                <li className="mb-2" style={{ fontSize: "14px" }}><a href="/about-us#difference" className="p-0 footer-line-item">The Difference</a></li>
                                <li className="mb-2" style={{ fontSize: "14px" }}><a href="/about-us#whitepaper" className="p-0 footer-line-item">Whitepapers</a></li>
                                <li className="mb-2" style={{ fontSize: "14px" }}><a href="/about-us#experts" className="p-0 footer-line-item">Experts</a></li>
                            </ul>
                        </div> */}

                        <div className="col-6 col-md-2 mb-3">
                            <h6 style={{ color: "#1db8ea" }}>Products & Pricing</h6>
                            <ul className="nav flex-column">
                                <li className="mb-2" style={{ fontSize: "14px" }}>
                                    <a href="/products-and-pricing" className="p-0 footer-line-item" rel="noopener noreferrer" target="_blank">
                                        Product Info
                                    </a>
                                </li>
                                <li className="mb-2" style={{ fontSize: "14px" }}>
                                    <a href="/products-and-pricing#comparison" className="p-0 footer-line-item" rel="noopener noreferrer" target="_blank">
                                        Product Comparison
                                    </a>
                                </li>
                                <li className="mb-2" style={{ fontSize: "14px" }}>
                                    <a href="/products-and-pricing#samples" className="p-0 footer-line-item" rel="noopener noreferrer" target="_blank">
                                        Sample Products
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-6 col-md-2 mb-3">
                            <h6 style={{ color: "#1db8ea" }}>Support</h6>
                            <ul className="nav flex-column">
                                <li className="mb-2" style={{ fontSize: "14px" }}>
                                    <a href="/contact-us" className="p-0 footer-line-item" rel="noopener noreferrer" target="_blank">
                                        Contact Us
                                    </a>
                                </li>
                                <li className="mb-2" style={{ fontSize: "14px" }}>
                                    <a href="/faq" className="p-0 footer-line-item" rel="noopener noreferrer" target="_blank">
                                        General Questions
                                    </a>
                                </li>
                                <li className="mb-2" style={{ fontSize: "14px" }}>
                                    <a href="/faq#expert" className="p-0 footer-line-item" rel="noopener noreferrer" target="_blank">
                                        Expert Services
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-6 col-md-2 mb-3"></div>

                        <div className="col-md-5 offset-md-1 mb-3">
                            {/* <form onSubmit={handleSubmit(onSubmitEmail)}>
                                <h6>Want to know more?</h6>
                                <p>Get in touch with us and we will reach back!</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                    <input 
                                        id="email" 
                                        type="text" 
                                        className={"form-control email-input-style " + (errors.email ? "is-invalid" : "")}
                                        placeholder="Email"
                                        {...register("email", { required: "Please enter your email." })}
                                    />
                                    <button 
                                        className="btn access-primary-button" 
                                        type="submit"
                                        style={{ textDecoration: "none", color: "white"  }}
                                    >
                                        {loading ? (
                                            <Oval color="white" height={20} width={20} />
                                        ) : (
                                            <FontAwesomeIcon className="notification-close" icon={faPaperPlane} color="#fff" style={{ margin: "0px" }} />
                                        )}
                                    </button>
                                </div>
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </form> */}
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                        <p>© 2024 Copyright: AccuMed HealthCare Research, LLC. All rights reserved.</p>
                        <ul className="list-unstyled d-flex">
                            <li className="ms-3">
                                <a className="link-dark" href="#"></a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="#"></a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="#"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
            {/* <div className="ctct-inline-form ct-footer-form" style={{ display: "none" }} data-form-id="6d185326-9e24-46e7-9daf-25aab59b11d7"></div> */}
        </footer>
    );
};
