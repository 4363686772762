import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";

export const BuilderProgressBar = (props) => {

    const [fromBillUpload, setFromBillUpload] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        setFromBillUpload(location.state);
    }, [location]);

    const smallScreen = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(min-width: 451px) and (max-width: 767px)");

    return (
        <div className="row mb-0 mt-0" style={{ margin: "64px" }}>
            {/* <div className="col-12"> */}
            <>
                <ul id="progressbar" className="text-center p-0">
                    <Link
                        to="/" 
                        style={{ 
                            color: "white", 
                            display: (smallScreen && props.step > 1) ? "none" : 
                                     (midSizeScreen && props.step > 2) ? "none" :
                                     ""
                        }}
                    >
                        <li className={"progress-1 active"} id="product-selection">
                            <span className="nav-step">Product Selection</span>
                        </li>
                    </Link>
                    <Link
                        to={((props.step > 2 && props.step < 5) && (props.upload || fromBillUpload)) ? "/builder/snapshot_upload" : (props.step > 2 && props.step < 5) ? "/builder/package_search" : "#"}
                        style={{ 
                            color: "white",
                            display: (smallScreen && props.step > 2) ? "none" :
                                     (midSizeScreen && props.step > 3) ? "none" :
                                     ""
                        }}
                    >
                        <li className={"progress-2 " + (props.step >= 2 ? "active " : "") + (props.step===2 ? "current" : "")} id="select-treatments">
                            <span className="nav-step">
                                {props.upload || fromBillUpload ? (
                                    "Upload Bill Analysis Template"
                                ) : (
                                    "Select Treatments"
                                )}
                            </span>
                        </li>
                    </Link>
                    <Link 
                        to={((props.step > 3 && props.step < 5) && (props.product == "standard_mcp")) ? "/builder/standard_mcp" : 
                            ((props.step > 3 && props.step < 5) && (props.product == "standard_ba")) ? "/builder/snapshot" : "#"} 
                        style={{ 
                            color: "white",
                            display: (smallScreen && props.step > 3 ) ? "none" :
                                     (midSizeScreen && props.step > 4) ? "none" :
                                     ""
                        }}
                    >
                        <li className={"progress-3 " + (props.step >= 3 ? "active " : "") + (props.step===3 ? "current" : "")} id="patient-information">
                            <span className="nav-step">Patient Information</span>
                        </li>
                    </Link>
                    <Link 
                        to={props.step > 4 && props.step < 5 ? "/builder/review" : "#"} 
                        style={{ 
                            color: "white",
                            display: (smallScreen && props.step < 3) ? "none" :
                                     (midSizeScreen && props.step < 3 ) ? "none" :
                                     ""
                        }}>
                        <li className={"progress-4 " + (props.step >= 4 ? "active " : "") + (props.step===4 ? "current" : "")} id="review">
                            <span className="nav-step">Review</span>
                        </li>
                    </Link>
                    <li 
                        className={"progress-5 " + (props.step >= 5 ? "active " : "") + (props.step===5 ? "current final" : "")} 
                        id="submit"
                        style={{
                            display: (smallScreen && props.step < 4) ? "none" :
                                     (midSizeScreen && props.step < 4) ? "none" :
                                     ""
                        }}
                    >
                        <span className="nav-step">Submit</span>
                    </li>
                </ul>
            </>
            {/* </div> */}
        </div>
    );
};
