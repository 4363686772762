import React from "react";
import { Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";

export const ProductSheetCard = (props) => {

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const laptopSize = useMediaPredicate("(max-width: 1536px)");

    return (
        <div className="card card-product mb-4">
            <div 
                className="card-header text-center"
                style={{ background: "#fff", borderTopLeftRadius: "1em", borderTopRightRadius: "1em", borderBottom: "0" }}
            >
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12"
                        style={{
                            marginBottom: "10px",
                            marginTop: "24px",
                            paddingLeft: "16px",
                            font: "700 20pt Montserrat",
                            color: "#1db8ea",
                        }}
                    >
                        Need more information?
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12"
                        style={{
                            marginBottom: "10px",
                            paddingLeft: "16px",
                            font: "700 20pt Montserrat",
                            color: "#1db8ea",
                        }}
                    >
                        Here's our product comparison sheet.
                    </div>
                </div>
            </div>
            <div className="card-body text-center">
                <Link to="/products-and-pricing" style={{ color: "#1db8ea" }}>
                    <button style={{ padding: "1.2rem 5.8rem", borderRadius: "50px" }} className="btn access-primary-button">
                        PRICING AND COMPARISON
                    </button>
                </Link>
            </div>
        </div>
    )
}
