import React, { useState, useContext } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes, faCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Oval } from "react-loader-spinner";
import { Tooltip } from "react-bootstrap";

export const SwapToCustom = (props) => {
    const history = useNavigate();
    const { patchRequest, currentRequest } = useContext(ContextGlobal);
    const [hover, setHover] = useState();
    const [loading, setLoading] = useState(false);

    return (
        <>
            {/* will need to be fixed up for mobile */}
            <div className="modal fade" id="custom-switch" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "590px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" />
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h4 className="modal-title w-100 text-center" style={{ font: "700 1.5rem Montserrat", color: "#000064" }}>
                                A report cannot be submitted without procedures. Would you like to submit a custom instead?
                            </h4>
                        </div>
                        <div className="modal-body">
                            {!props.billValue && (
                                <>
                                    <div className="row mt-1">
                                        <div className="col-2"></div>
                                        <div className="col-8">
                                            <div className="d-flex justify-content-center">
                                                {/* would also neeed to be a patch() call */}
                                                <button
                                                    className="btn access-primary-button flex-fill"
                                                    type="button"
                                                    // data-bs-toggle={session.email ? "" : "modal"}
                                                    // data-bs-target={session.email ? "" : "#login"}
                                                    data-bs-dismiss="modal"
                                                    onClick={async (e) => {
                                                        setLoading(true);
                                                        await patchRequest(currentRequest.request.requestID, "typeProduct", "type_product_medical_bill_analysis_pro");
                                                        setLoading(false);
                                                        history("/builder/pro_mcp");
                                                    }}
                                                >
                                                    {loading ? <Oval color="white" height={20} width={20} /> : "SWITCH TO A CUSTOM REPORT"}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <span className="fa-layers fa-fw fa-center-icon" style={{ color: "#1DB8EA", cursor: "pointer" }} onMouseEnter={(e) => setHover(true)} onMouseLeave={(e) => setHover()}>
                                                <span data-tip="custom show" data-event="mouseenter" data-event-off="mouseleave" data-for="custom-info">
                                                    <FontAwesomeIcon style={{ display: !hover ? "none" : "" }} transform="shrink-5" icon={faCircle} color="#1db8ea" />
                                                    <FontAwesomeIcon icon={faInfo} transform="shrink-10" className="share-remove" color={hover ? "white" : "#1db8ea"} />
                                                </span>
                                                <ReactTooltip className="custom-info-tooltip" id="custom-info" place="right" globalEventOff="click" multiline={true} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                                                    <span>
                                                        Simply attach the treatment recommendation and our talented
                                                        <br />
                                                        staff will build a customized Future Needs report.
                                                        <br />
                                                        Cost: $950
                                                    </span>
                                                </ReactTooltip>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div
                                            className="d-flex justify-content-center"
                                            // style={{ font: "700 1.5rem Montserrat", color: "#000064" }}
                                        >
                                            <p>If not, please go back to Select Treaments and add procedures.</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="d-flex justify-content-center" style={{ font: "700 1.5rem Montserrat", color: "#000064" }}>
                                            Need more assistance?
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="row mt-4">
                                <div className="d-grid gap-2 col-8 mx-auto">
                                    <a href="mailto:info@accumedintel.com" className="btn access-secondary-button">
                                        Email Us
                                    </a>
                                </div>
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center mt-4">
                                (844) 307 4487
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center">
                                Mon-Fri: 8am - 6pm MT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
