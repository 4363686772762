import React, { useContext, useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";

import { ContextGlobal } from "../../contexts/ContextGlobal.js";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { ProductsPricing } from "./ProductsPricing.js";
import { ProductComparison } from "./ProductComparison.js";
import { SampleProducts } from "./SampleProducts.js";

export const WrapperProductsInfo = (props) => {
    const { setActivePage } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("productsInfo");
    }, []);

    const [select, setSelect] = useState("pricing");

    const selectRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash !== "") {
            setSelect(location.hash.replace(/[#]/g, ""));
        } else {
            selectRef.current.click();
        }
    }, [location]);

    const history = useNavigate();

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const laptopSize = useMediaPredicate("(max-width: 1536px)");

    return (
        <div className="row g-0">
            <div className="col-md-10 offset-md-1 col-12">
                <div className="card card-product-no-height mb-5 transparent">
                    <div className="row g-0">
                        {/* Side Menu */}
                        <div className="col-xl-3 col-12">
                            <div className="menu" style={{ height: "100%" }}>
                                <div className="card-body sticky-top" style={{ fontSize: "1.4em", padding: "40px" }}>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "pricing" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "pricing" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("pricing");
                                                    // history("/products-and-pricing#pricing");
                                                }}
                                                ref={selectRef}
                                            >
                                                <a href="#pricing" style={{ textDecoration: "none", color: "#000046" }}>
                                                    Products & Pricing
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mt-2 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "comparison" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "comparison" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("comparison");
                                                    // history("/products-and-pricing#comparison");
                                                }}
                                            >
                                                <a href="#comparison" style={{ textDecoration: "none", color: "#000046" }}>
                                                    Product Comparison
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "samples" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "samples" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("samples");
                                                    // history("/products-and-pricing#samples");
                                                }}
                                            >
                                                <a href="#samples" style={{ textDecoration: "none", color: "#000046" }}>
                                                    Sample Products
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <a href="/contact-us" rel="noopener noreferrer" target="_blank" className="faq-contact-link">
                                        <div className="d-flex flex-row mb-3">
                                            <div className="me-2 mx-2">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </div>
                                            <div className="p-0" style={{ fontSize: "0.6em", fontWeight: "500" }}>
                                                NOT WHAT YOU WERE LOOKING FOR? <br /> CONTACT US FOR HELP
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-12 column-right-hand-side ">
                            <div className="card-body" id="pricing" style={{ padding: phoneSize ? "25px" : "40px" }}>
                                <>
                                    <ProductsPricing phoneSize={phoneSize} midSizeScreen={midSizeScreen} laptopSize={laptopSize} />
                                    <ProductComparison phoneSize={phoneSize} midSizeScreen={midSizeScreen} laptopSize={laptopSize} />
                                    <SampleProducts phoneSize={phoneSize} midSizeScreen={midSizeScreen} laptopSize={laptopSize} />
                                </>
                            </div>
                        </div>
                        {/* Content */}
                        {/* Should swap out depending on the links above */}
                        {/* <SwitchTransition>
                            <CSSTransition
                                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                classNames="fade-faq"
                                key={select}
                            >
                                <div className="col-xl-9 col-12 column-right-hand-side ">
                                    <div className="card-body" style={{ padding: phoneSize ? "25px" : "40px" }}>
                                        <>
                                            {select === "pricing" || select === "all" ? <ProductsPricing phoneSize={phoneSize} midSizeScreen={midSizeScreen} laptopSize={laptopSize} /> : null}
                                            {select === "comparison" || select === "all" ? <ProductComparison phoneSize={phoneSize} midSizeScreen={midSizeScreen} laptopSize={laptopSize} /> : null}
                                            {select === "samples" ? <SampleProducts phoneSize={phoneSize} midSizeScreen={midSizeScreen} laptopSize={laptopSize} /> : null}
                                        </>
                                    </div>
                                </div>
                            </CSSTransition>
                        </SwitchTransition> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
