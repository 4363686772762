import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';

export const WhatWeDo = (props) => {

    return (
        <>
            <div className="row g-0 mb-3 mt-2 mx-2">
                <div className="col-md-12 faq-headings" style={{ color: "#000046" }}>
                    We Do...
                </div>
            </div>
        </>
    )
}
