import React, { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faUser, faCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";

export const FormUserContact = (props) => {
    const open = props.open;
    const setOpen = props.setOpen;
    const contact = props.contact;
    const index = props.index;

    const [checkHover, setCheckHover] = useState();
    const [trashHover, setTrashHover] = useState();

    const contactButton = useRef(null);

    const { deleteFromContactList, updateContact } = useContext(ContextGlobal);

    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        // console.log("data: ", data);
        updateContact(data);
    };

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    const largeSizeScreen = useMediaPredicate("(max-width: 1199px)");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-0 d-flex mb-2" key={index}>
                <div className="col-xl-1 d-none d-xl-block">
                    <span>
                        <h3 style={{ marginTop: "2px", marginBottom: "0px" }}>
                            <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    color={sharedIcons[index % sharedIcons.length].background}
                                />
                                <FontAwesomeIcon
                                    icon={faUser}
                                    transform="shrink-6"
                                    color={sharedIcons[index % sharedIcons.length].icon}
                                />
                            </span>
                        </h3>
                    </span>
                </div>
                <div
                    className="col-xl-3 col-md-6 col-12 mb-lx-0 mb-2"
                    style={{
                        paddingLeft: "0px",
                        paddingRight: open !== contact.contactID ? "0px" : "12px",
                        borderColor: "#1DB8EA",
                    }}
                >
                    {largeSizeScreen && (
                        <label className="form-label" style={{ color: "#1DB8EA" }}>
                            First Name
                        </label>
                    )}
                    <input
                        type="text"
                        className="form-control  access-input-style"
                        defaultValue={contact.contactID ?? ""}
                        readOnly
                        // onChange={(e) => updateFormContact({ id: contact.contactID, firstName: e.target.value })}
                        style={{ display: "none" }}
                        {...register("contactID")}
                    />
                    <input
                        type="text"
                        className="form-control  access-input-style"
                        id={"shared-first-name-" + index}
                        defaultValue={contact.firstName ?? ""}
                        readOnly={open !== contact.contactID}
                        // onChange={(e) => updateFormContact({ id: contact.contactID, firstName: e.target.value })}
                        style={{ paddingLeft: open !== contact.contactID ? "0px" : "12px" }}
                        {...register("firstName")}
                    />
                </div>
                <div
                    className="col-xl-3 col-md-6 col-12 mb-lx-0 mb-2"
                    style={{
                        paddingLeft: "0px",
                        paddingRight: open !== contact.contactID ? "0px" : "12px",
                        borderColor: "#1DB8EA",
                    }}
                >
                    {largeSizeScreen && (
                        <label className="form-label" style={{ color: "#1DB8EA" }}>
                            Last Name
                        </label>
                    )}
                    <input
                        type="text"
                        className="form-control access-input-style "
                        id={"shared-last-name-" + index}
                        defaultValue={contact.lastName ?? ""}
                        readOnly={open !== contact.contactID}
                        // onChange={(e) => updateFormContact({ id: contact.contactID, lastName: e.target.value })}
                        style={{ paddingLeft: open !== contact.contactID ? "0px" : "12px" }}
                        {...register("lastName")}
                    />
                </div>
                <div
                    className="col-xl-4 col-md-6 col-12 mb-xl-0 mb-2"
                    style={{
                        paddingLeft: "0px",
                        paddingRight: open !== contact.contactID ? "0px" : "12px",
                        borderColor: "#1DB8EA",
                    }}
                >
                    {largeSizeScreen && (
                        <label className="form-label" style={{ color: "#1DB8EA" }}>
                            Email
                        </label>
                    )}
                    <input
                        type="text"
                        className="form-control access-input-style "
                        id={"shared-email-" + index}
                        defaultValue={contact.email ?? ""}
                        readOnly={open !== contact.contactID}
                        // onChange={(e) => updateFormContact({ id: contact.contactID, email: e.target.value })}
                        style={{ paddingLeft: open !== contact.contactID ? "0px" : "12px" }}
                        {...register("email")}
                    />
                </div>
                <div className="col-xl-1 col-md-6 flex-fill align-self-center text-end">
                    <div className="row g-0">
                        <div className="col-md-4 col-2 text-center">
                            <span
                                style={{ cursor: "pointer" }}
                                className="fa-layers fa-fw fa-center-icon"
                                onClick={(e) => {
                                    if (open !== contact.contactID) {
                                        setOpen(contact.contactID);
                                    } else {
                                        setOpen(false);
                                        contactButton.current.click();
                                        // updateContact(contact.contactID)
                                    }
                                }}
                                onMouseEnter={(e) => setCheckHover(index)}
                                onMouseLeave={(e) => setCheckHover()}
                            >
                                <FontAwesomeIcon
                                    style={{ display: checkHover !== index ? "none" : "" }}
                                    icon={faCircle}
                                    color="#1db8ea"
                                />
                                <FontAwesomeIcon
                                    color={checkHover === index ? "white" : "#1db8ea"}
                                    transform="shrink-8"
                                    icon={open === contact.contactID ? faCheck : faPen}
                                />
                            </span>
                        </div>
                        <div className="col-md-4 col-2 text-start">
                            <span
                                className="fa-layers fa-fw fa-center-icon"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => deleteFromContactList(contact.contactID)}
                                onMouseEnter={(e) => setTrashHover(index)}
                                onMouseLeave={(e) => setTrashHover()}
                            >
                                <FontAwesomeIcon
                                    style={{ display: trashHover !== index ? "none" : "" }}
                                    icon={faCircle}
                                    color="#1db8ea"
                                />
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    transform="shrink-8"
                                    color={trashHover === index ? "white" : "#1db8ea"}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <button type="submit" ref={contactButton} style={{ display: "none" }}></button>
            </div>
        </form>
    );
};
