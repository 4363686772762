import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUser, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import { useMediaPredicate } from "react-media-hook";
import { ContextGlobal } from "../../contexts/ContextGlobal";

export const ContactBookModal = (props) => {
    const { contactList, addToSharedList } = useContext(ContextGlobal);

    const [sharedContacts, setSharedContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    // const contactRef = useRef([]);
    const phoneSize = useMediaPredicate("(max-width: 450px)");

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    const handleCheck = async (e, id, index) => {
        if (e.target.checked) {
            // add to share list
            const filtered = contactList.filter((contact, i) => id === contact.contactID);
            setSharedContacts([...sharedContacts, ...filtered]);
        } else {
            // remove from temp sharedContacts array when unchecking before clicking 'share' button
            const filtered = sharedContacts.filter((shared) => props.contactRef.current[index].value !== shared.email);
            setSharedContacts(filtered);
        }
    };

    const handleShare = async () => {
        await addToSharedList(sharedContacts);
        // disable added contacts
        props.contactRef.current.forEach((ref, index) => {
            sharedContacts.forEach((shared, index) => {
                if (shared.email === ref.value) {
                    ref.disabled = true;
                }
            });
        });
        setSharedContacts([]); // clears the temp array used to push to sharedList
    };

    const closeRef = useRef(null);

    const closeModal = () => {
        closeRef.current.click();
    };

    return (
        <>
            <div className="modal fade" id="address-book" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "900px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" />
                        <div
                            className="modal-header"
                            style={{
                                font: "700 1.2rem Montserrat",
                                color: "#000064",
                                borderBottom: "none",
                            }}
                        >
                            <span style={{ marginLeft: "25px" }}>Your Contacts</span>
                        </div>
                        <div className="modal-body">
                            <div className="row text-muted mb-2" style={{ fontSize: "0.9em" }}>
                                <div style={{ paddingLeft: "0px" }} className="col-2"></div>
                                <div style={{ paddingLeft: "0px", display: phoneSize ? "none" : "" }} className="col-2">
                                    First Name
                                </div>
                                <div style={{ paddingLeft: "0px", display: phoneSize ? "none" : "" }} className="col-3">
                                    Last Name
                                </div>
                                <div style={{ paddingLeft: "0px" }} className="col-5">
                                    Email
                                </div>
                            </div>
                            {contactList.map((contact, index) => (
                                <div className="d-flex row mb-3" key={index}>
                                    <div className="col-2">
                                        <input
                                            className="form-check-input mx-3"
                                            type="checkbox"
                                            id={"contact-" + index}
                                            onChange={(e) => {
                                                handleCheck(e, contact.contactID, index);
                                            }}
                                            ref={(ref) => {
                                                props.contactRef.current[index] = ref;
                                            }}
                                            value={contact.email}
                                        />
                                        <label className="form-check-label" htmlFor={"contact-" + index} style={{ display: phoneSize ? "none" : "" }}>
                                            <h3>
                                                <span
                                                    className="fa-layers fa-fw fa-center-icon"
                                                    style={{
                                                        fontSize: "1.55em",
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCircle} color={sharedIcons[index % sharedIcons.length].background} />
                                                    <FontAwesomeIcon icon={faUser} transform="shrink-6" color={sharedIcons[index % sharedIcons.length].icon} />
                                                </span>
                                            </h3>
                                        </label>
                                    </div>
                                    <div
                                        className="col-2"
                                        style={{
                                            borderBottom: "2px solid #f2f4f7",
                                            paddingLeft: "0px",
                                            height: "fit-content",
                                            paddingBottom: "0.375rem",
                                            paddingTop: "0.375rem",
                                            display: phoneSize ? "none" : "",
                                        }}
                                    >
                                        {contact.firstName}
                                    </div>
                                    <div
                                        className="col-3"
                                        style={{
                                            borderBottom: "2px solid #f2f4f7",
                                            paddingLeft: "0px",
                                            height: "fit-content",
                                            paddingBottom: "0.375rem",
                                            paddingTop: "0.375rem",
                                            display: phoneSize ? "none" : "",
                                        }}
                                    >
                                        {contact.lastName}
                                    </div>
                                    <div
                                        className={phoneSize ? "col-10" : "col-5"}
                                        style={{
                                            borderBottom: "2px solid #f2f4f7",
                                            paddingLeft: phoneSize ? "5px" : "0px",
                                            height: "fit-content",
                                            paddingBottom: "0.375rem",
                                            paddingTop: "0.375rem",
                                        }}
                                    >
                                        {contact.email}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-start">
                            <button
                                style={{ marginLeft: "2.8em" }}
                                type="button"
                                className="btn access-secondary-button"
                                onClick={async () => {
                                    setLoading(true);
                                    await handleShare();
                                    setLoading(false);
                                    closeModal();
                                }}
                            >
                                {loading ? <Oval color="white" height={20} width={20} /> : <>Share</>}
                            </button>
                            <button style={{ display: "none" }} data-bs-dismiss="modal" ref={closeRef}></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* To close modal */}
        </>
    );
};
