import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { FormVerifyEmail } from "../Forms/FormVerifyEmail.js";

export const LoginModal = (props) => {
    const history = useNavigate();
    const location = useLocation();

    const [closed, setClosed] = useState(false);

    return (
        <div className="modal fade" id="login" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-eula-sign">
                    {location.pathname === "/builder/pro_mcp" ? <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" onClick={(e) => history("/builder/package_search")} /> : <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" onClick={() => setClosed(true)} />}
                    <div className="modal-header" style={{ borderBottom: "none", padding: 0 }}>
                        <h3 className="modal-title w-100 text-center" style={{ fontWeight: "700", color: "#000064" }}>
                            Login
                        </h3>
                    </div>
                    <div className="modal-body">
                        <FormVerifyEmail closed={closed} setClosed={setClosed} />
                    </div>
                </div>
            </div>
        </div>
    );
};
