import React, { useState, useRef, useContext, useEffect } from "react";
import { faChevronDown, faEllipsisV, faInfo, faTimes, faPlus, faCircle, aQuestionCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Oval } from "react-loader-spinner";
import { useMediaPredicate } from "react-media-hook";

import { ContextGlobal } from "../../../contexts/ContextGlobal";

export const Package = (props) => {
    // props handleAddToReport, addToLoad, buttonColor, checkIcon, packageID
    const { packages, requestPackages, session } = useContext(ContextGlobal);

    const packageRow = props.packageRow;
    const [firstTooltip, setFirstTooltip] = useState(session.email !== undefined ? true : false);
    const [infoHover, setInfoHover] = useState();
    const [rotate, setRotate] = useState();
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");

    const addToReportRef = useRef([]);

    useEffect(() => {
        if (packages.length !== 0 && session.email === undefined) {
            ReactTooltip.show(addToReportRef.current[0]);
        }
    }, [packages, session]);

    const closeAddToReportTooltip = () => {
        ReactTooltip.hide(addToReportRef.current[0]);
        setFirstTooltip(true);
    };

    return (
        <div key={"package-list-" + props.index} style={{ marginBottom: "24px" }}>
            <div style={{ font: "500 14pt Roboto", marginBottom: "3px", color: "#000046" }}>{packageRow.package.title}</div>
            {midSizeScreen ? (
                <>
                    <div className="collapse" id={"packageDescription-" + packageRow.package.packageID} style={{ fontSize: "0.85em", marginBottom: "20px" }}>
                        {packageRow.package.description}
                    </div>
                    <div
                        className="mb-2"
                        data-bs-toggle="collapse"
                        href={"#packageDescription-" + packageRow.package.packageID}
                        aria-expanded="false"
                        aria-controls={"packageDescription-" + packageRow.package.packageID}
                        onClick={(e) => {
                            rotate ? setRotate() : setRotate(packageRow.package.packageID);
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </>
            ) : (
                <div style={{ fontSize: "0.85em", marginBottom: "20px" }}>{packageRow.package.description}</div>
            )}
            <div className="pb-2">
                {!session.isSignedEula && requestPackages.length === 1 ? (
                    <button
                        className={
                            "btn access-secondary-button" +
                            (packageRow.package.packageID === props.packageID
                                ? props.buttonColor === null
                                    ? ""
                                    : props.buttonColor === "teal"
                                    ? "-check"
                                    : // (buttonColor === "navy") ?
                                      //     ("-another") :
                                      //     ("")
                                      ""
                                : "")
                        }
                        data-bs-toggle="modal"
                        data-bs-target="#eula"
                        data-tip
                        data-for="add-to-tooltip"
                        // data-event="mouseenter"
                        data-event-off="mouseleave"
                        onClick={() => {
                            props.handleAddToReport(packageRow.package.packageID, false);
                        }}
                        ref={(ref) => {
                            addToReportRef.current[props.index] = ref;
                        }}
                    >
                        {props.addToLoad === packageRow.package.packageID ? <Oval color="white" height={20} width={20} /> : "Add to report"}
                    </button>
                ) : (
                    <button
                        className={
                            "btn access-secondary-button" +
                            (packageRow.package.packageID === props.packageID
                                ? props.buttonColor === null
                                    ? ""
                                    : props.buttonColor === "teal"
                                    ? "-check"
                                    : // (buttonColor === "navy") ?
                                      //     ("-another") :
                                      //     ("")
                                      ""
                                : "")
                        }
                        onClick={async () => {
                            props.handleAddToReport(packageRow.package.packageID, true);
                        }}
                        data-tip
                        data-for={"add-to-tooltip-" + props.index}
                        // data-event="mouseenter"
                        data-event-off="mouseleave"
                        ref={(ref) => {
                            addToReportRef.current[props.index] = ref;
                        }}
                    >
                        {props.addToLoad === packageRow.package.packageID ? <Oval color="white" height={20} width={20} /> : <>{packageRow.package.packageID === props.packageID ? <>{props.checkIcon ? <FontAwesomeIcon icon={faCheck} color="white" /> : "Add Again"}</> : "Add to report"}</>}
                    </button>
                )}
                {!firstTooltip && (
                    <ReactTooltip
                        id={"add-to-tooltip-" + props.index}
                        // type="warning"
                        effect="solid"
                        multiline={true}
                        delayHide={500}
                        delayUpdate={500}
                        textColor="#000000"
                        backgroundColor="#e8ff8b"
                        borderColor="#e8ff8b"
                        arrowColor="#e8ff8b"
                    >
                        <span className="close-tooltip">
                            <FontAwesomeIcon
                                onClick={(e) => closeAddToReportTooltip()}
                                style={{
                                    cursor: "pointer",
                                }}
                                icon={faTimes}
                                color="#B7B7CB"
                            />
                        </span>
                        <span>
                            You can adjust procedure details once
                            <br />
                            you add it to your report
                        </span>
                    </ReactTooltip>
                )}
                <span className="fa-layers fa-fw fa-center-icon" style={{ marginLeft: "25px", color: "#1DB8EA", cursor: "pointer" }} onMouseEnter={(e) => setInfoHover(packageRow.package.packageID)} onMouseLeave={(e) => setInfoHover()}>
                    <span data-tip="custom show" data-event="click" data-for="bill-tooltip">
                        <FontAwesomeIcon
                            style={{
                                display: infoHover !== packageRow.package.packageID ? "none" : "",
                            }}
                            transform="shrink-5"
                            icon={faCircle}
                            color="#1db8ea"
                        />
                        <FontAwesomeIcon icon={faInfo} transform="shrink-10" className="share-remove" color={infoHover === packageRow.package.packageID ? "white" : "#1db8ea"} />
                    </span>
                    <ReactTooltip id="bill-tooltip" place="right" globalEventOff="click" textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                        Bills Included:
                        {packageRow.bill.length !== 0 ? (
                            <>
                                {packageRow.bill.map((bill, billIndex) => (
                                    <div key={"bill-tooltip-" + billIndex} className={"row g-0"}>
                                        <div
                                            className="col-12"
                                            style={{
                                                paddingLeft: "12px",
                                                paddingRight: "24px",
                                            }}
                                        >
                                            {bill.title}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>&nbsp;None</>
                        )}
                    </ReactTooltip>
                </span>
            </div>
            {props.index === 9 || props.index + 1 === packages.length ? null : <>{props.index + 1 !== packages.length && <hr style={{ color: "#f2f4f7", opacity: 1 }} />}</>}
        </div>
    );
};
