import React, { useEffect, useState, useContext } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { useNavigate } from "react-router";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner";

export const ProductButton = (props) => {
    const { createRequest } = useContext(ContextGlobal);

    const newStyle = props.newStyle;
    const beta = props.beta;

    const history = useNavigate();

    const laptopSize = useMediaPredicate("(max-width: 875px)"); // mostly for the badge sizing issues
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState("");
    const [link, setLink] = useState("");

    useEffect(() => {
        setProduct(props.product);
        switch (props.product) {
            case "type_product_medical_cost_projection_standard":
                setLink("/builder/package_search");
                break;
            case "type_product_medical_cost_projection_pro":
                setLink("/builder/pro_mcp");
                break;
            case "type_product_medical_cost_projection_expert":
                setLink("/builder/expert_mcp");
                break;
            case "type_product_medical_bill_analysis_standard":
                setLink("/builder/standard_bill_analysis");
                break;
            case "type_product_medical_bill_analysis_pro":
                setLink("/builder/pro_bill_analysis");
                break;
            case "type_product_medical_bill_analysis_expert":
                setLink("/builder/expert_bill_analysis");
                break;
            case "type_product_bill_analysis_with_code_review_pro":
                setLink("/builder/pro_bill_analysis_code_review");
                break;
            case "type_product_bill_analysis_with_code_review_expert":
                setLink("/builder/expert_bill_analysis_code_review");
                break;
            case "bill_value_auto":
                setLink("/builder/snapshot_upload");
                break;
            default:
                break;
        }
    }, [props.product]);

    return (
        <button
            className={"btn access-primary-btn-lg-gradient " + (beta ? "beta-button" : "")}
            style={newStyle}
            onClick={async (e) => {
                setLoading(true);
                if (product != "type_product_medical_cost_projection_standard") {
                    await createRequest(product);
                }
                history(link);
                setLoading(false);
            }}
        >
            {/* {beta ? (
                <img 
                    src="/img/Access_Beta Badge.png" 
                    className={"img-fluid " + (laptopSize ? (midSizeScreen ? "beta-badge" : "beta-badge-laptop") : "beta-badge")}
                />
            ) :(null)} */}
            {loading ? (
                <Oval color="white" height={20} width={20} />
            ) : (
                <>
                    <span>{props.title}</span>
                    <br />
                    <span style={{ fontSize: "12px", fontWeight: "500" }}>{props.description}</span>
                </>
            )}
        </button>
    );
};
