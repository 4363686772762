import React, { useState, useContext, useEffect, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { ContextGlobal } from "../../../contexts/ContextGlobal";

export const HistoricalEstimateForm = (props) => {

    const { formPatientInfo, updateFormPatientInfo } = useContext(ContextGlobal);
    const methods = useFormContext();

    // const formatNumber = (number) => {
    //     // format number 1000000 to 1,234,567
    //     return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    const formatCurrency = (value) => {
        if (value == '') { return ''; } // empty string
        if (!(/\d/.test(value))) { return ''; } // doesn't contain a number
        let num = Number(value.replace(/[$,^a-zA-Z]/g, ''));
        if (num == '') { return ''; } // number is empty
        const format = num.toLocaleString("en-US", {style:"currency", currency:"USD"});
        return format;
    }

    return (
        <>
            <div className="row g-0 mb-2" style={{ marginTop: "60px" }}>
                <h3>Estimate of Bills to be Analyzed</h3>
                <div className="row g-0">
                    <div className="col-md-12">
                        <p className="m-0">
                            This helps ensure we have all the bills you want analyzed.
                        </p>
                    </div>
                    <div className="col-md-6 col-12 mt-2">
                        <input 
                            type="text"
                            className={"form-control access-input-style review-page " + (methods.formState.errors.billsToAnalyze ? "is-invalid" : "") }
                            id="first-name"
                            placeholder="$0000.00"
                            defaultValue={formPatientInfo.billsToAnalyze ?? ""}
                            {...methods.register("billsToAnalyze")}
                            onBlur={(e) => {
                                var x = e.target.value;
                                e.target.value = formatCurrency(x);
                                updateFormPatientInfo({ billsToAnalyze: e.target.value });
                            }}
                        />
                        <div className="invalid-feedback">{methods.formState.errors.billsToAnalyze?.message}</div>
                    </div>
                    {props.estimateWarning ? (
                        <div className="col-md-12 mt-2">
                            <div className="alert alert-warning">
                                Attention: You haven't entered an estimate of bills to be analyzed. Without this info we may have trouble reconciling the bills we're analyzing to the bills you expect us to analyze.
                                If you don't have an estimate, no problem, just click the submit button again.
                            </div>
                        </div>
                    ) : (null)}
                </div>
            </div>
        </>
    );

}