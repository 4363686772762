import React, { useEffect, useState, useContext } from "react";

import { Link, useLocation } from "react-router-dom";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { Oval } from "react-loader-spinner";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Confirm = () => {
    const { validateUserToken, confirmEmail } = useContext(ContextGlobal);

    const [token, setToken] = useState();
    const [validated, setValidated] = useState(null);
    const [message, setMessage] = useState(null);

    const location = useLocation();
    const query = useQuery();

    useEffect(() => {
        async function validateToken(token) {
            const res = await validateUserToken(token);
            if (res) {
                const confirm = await confirmEmail({
                    token: token,
                    isConfirmed: { isConfirmed: true },
                });

                if (confirm.status) {
                    setValidated(res);
                } else {
                    setValidated(false);
                    if (confirm.message == "unauthorized") {
                        setMessage("Unauthorized access - this link does not belong to this account. Please log out and try again or log into the correct account to confirm");
                    } else {
                        setMessage("Error when confirming account, please try again");
                    }
                }
            } else {
                setValidated(false);
                // setMessage(null);
            }
        }

        if (location.search !== "") {
            const token = query.get("token");
            setToken(token);
            validateToken(token);
        }
    }, []);

    return (
        <div className="row">
            <div className="col-xxl-3 col-lg-2 col-md-1"></div>
            <div className="col-xxl-6 col-lg-8 col-md-10 col-12">
                <div className="card content-card" style={{ marginTop: "20vh", minHeight: "30vh" }}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 style={{ marginBottom: "16px", font: "700 17pt Montserrat" }}>
                                <span style={{ color: "#000046", font: "700 20pt Montserrat" }}>Email Confirmation</span>
                            </h4>
                        </div>
                    </div>
                    {/* Waiting for validation on Token */}
                    {validated === null ? (
                        <div className="row">
                            <div className="col-12">
                                <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
                            </div>
                        </div>
                    ) : null}
                    {/* Failed to validate Token */}
                    {/* figure out how to close Login modal */}
                    {validated === false ? (
                        <div className="row">
                            <div className="col-12 mb-3 text-center">{message ? <>{message}</> : <>We apologize, but this link has EXPIRED.</>}</div>
                            <div className="d-grid col-xxl-7 col-lg-7 col-md-8 col-10 mx-auto">
                                <a href="/" className="btn access-large-button">
                                    HOME
                                </a>
                            </div>
                        </div>
                    ) : null}
                    {/* Successfully updated password */}
                    {validated === true ? (
                        <div className="row">
                            <div className="col-12 mb-3 text-center">Email successfully confirmed!</div>
                            {/* <div className="col-12 text-center">
                                Please log in again!
                            </div> */}
                            <div className="d-grid col-xxl-7 col-lg-7 col-md-8 col-10 mx-auto">
                                <a href="/" className="btn access-large-button">
                                    HOME
                                </a>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="col-xxl-3 col-lg-2 col-md-1"></div>
        </div>
    );
};
