import React, { useContext } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";

export const FormPatientName = (props) => {
    const { formPatientInfo, updateFormPatientInfo } = useContext(ContextGlobal);

    return (
        <div className="row g-0">
            <div className="col-xl-4 col-12 d-flex align-items-center">
                <h3 className="mb-4">This report is for: </h3>
            </div>
            <div className="col-xl-8 col-12" style={{ paddingLeft: "0px" }}>
                <form className="row align-items-center">
                    <div className="col-xl-6 col-12">
                        <label htmlFor="first-name" className="form-label access-input-label-style"></label>
                        <input
                            id="first-name"
                            type="text"
                            placeholder="Patient First Name"
                            className="form-control access-input-style"
                            value={formPatientInfo.firstName ?? ""}
                            onChange={(e) => updateFormPatientInfo({ firstName: e.target.value })}
                            autoComplete="off"
                        />
                    </div>
                    <div className="col-xl-6 col-12">
                        <label htmlFor="last-name" className="form-label access-input-label-style"></label>
                        <input
                            id="last-name"
                            type="text"
                            placeholder="Patient Last Name"
                            className="form-control access-input-style"
                            value={formPatientInfo.lastName ?? ""}
                            onChange={(e) => updateFormPatientInfo({ lastName: e.target.value })}
                            autoComplete="off"
                        />
                    </div>
                </form>
                <div className="row" style={{ marginBottom: "32px", color: "#a0a0a0" }}>
                    <small>You can change this information later</small>
                </div>
            </div>
        </div>
    );
};
