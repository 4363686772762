import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormSetPassword } from "./Forms/FormSetPassword";
import { ContextGlobal } from "../contexts/ContextGlobal";
import { useMediaPredicate } from "react-media-hook";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ThankYou = (props) => {
    const query = useQuery();

    const { session } = useContext(ContextGlobal);
    const [rush, setRush] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(true);

    // const [productName, setProductName] = useState(false);
    // const location = useLocation();

    // useEffect(() => {
    //     setProductName(location.state);
    // }, [location]);

    useEffect(() => {
        if (query.get("rush") === "true") {
            setRush(true);
        }
        if (query.get("isConfirmed") === "false") {
            setIsConfirmed(false);
        }
    }, []);

    const phoneScreen = useMediaPredicate("(max-width: 450px)");

    return (
        <>
            {!session.email && !session.requestUserID ? (
                <div className="row">
                    <div className="col-xxl-4 col-xl-3 col-lg-2 d-none d-md-block"></div>
                    <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-12">
                        <div className="card p-5 content-card create-password-modal" style={{ marginTop: "20vh", minHeight: "30vh" }}>
                            <div className="card-title">
                                {/* <h3 className="text-center mb-1" style={{ fontWeight: "700", color: "#000064" }}>
                                    Your {productName}
                                </h3> */}
                                <h3 className="text-center mb-3" style={{ fontWeight: "700", color: "#000064" }}>
                                    Your Report is on the way!
                                </h3>
                            </div>
                            <FormSetPassword isConfirmed={isConfirmed} setIsConfirmed={setIsConfirmed} />
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-3 col-lg-2 d-none d-md-block"></div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-xxl-3 col-lg-2 col-md-1"></div>
                    <div className="col-xxl-6 col-lg-8 col-md-10 col-12">
                        <div className="card content-card" style={{ marginTop: "5vh", minHeight: "30vh" }}>
                            <div className="d-flex row m-auto">
                                <div className="col-12">
                                    <h1 className="contact-thank-you d-flex justify-content-center mb-4">Thank you!</h1>
                                </div>
                                <div className="col-12 text-center mt-3">
                                    {rush ? (
                                        <>
                                            <h3 className="mb-5">Please contact us to confirm your rush Report.</h3>
                                            <div className="d-grid col-xxl-8 col-lg-8 col-md-8 col-10 mx-auto">
                                                <a href="/contact-us" rel="noopener noreferrer" target="_blank" className="btn access-large-button">
                                                    CONTACT US
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {!isConfirmed ? (
                                                <>
                                                    <div className="row g-0 mb-4">{session.requestUserID != undefined && session.tempUserID ? "" : "Thank you for registering with Access, we have sent you an email to confirm your account!"}</div>
                                                </>
                                            ) : null}
                                            <div className="d-grid col-xxl-8 col-lg-8 col-md-8 col-10 mx-auto">
                                                <a href="/" className="btn access-large-button">
                                                    HOME
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-2 col-md-1"></div>
                </div>
            )}

            {/* <button id="showmodal" className="d-none" data-bs-toggle="modal" data-bs-target="#setpassword"></button>

            <div className="modal fade" id="setpassword" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-eula-sign">
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h3
                                className="modal-title w-100 text-center"
                                style={{ fontWeight: "700", color: "#000064" }}
                            >
                                Your report is on the way!
                            </h3>
                        </div>
                        <div className="modal-body">
                            <FormSetPassword />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};
