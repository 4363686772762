import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Beforeunload } from "react-beforeunload";
import { CookiesProvider } from "react-cookie";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ContextGlobal } from "./contexts/ContextGlobal";
import { Frontpage } from "./components/Frontpage";
import { NavBar } from "./components/NavBar";
import { MyReports } from "./components/MyReports/MyReports";
import { WrapperAbout } from "./components/AboutUs/WrapperAbout";
import { ContactUs } from "./components/ContactUs/ContactUs";
import { WrapperFaq } from "./components/FAQ/WrapperFaq";
import { WrapperProductsInfo } from "./components/ProductsAndPricing/WrapperProductsInfo";
import { Settings } from "./components/Settings/Settings";
import { EulaCard } from "./components/Settings/EulaCard";
import { BuilderProgressBar } from "./components/Builder/BuilderProgressBar";
import { BuilderPackageSearch } from "./components/Builder/MedicalCostProjection/BuilderPackageSearch";
import { BuilderStandardMCP } from "./components/Builder/MedicalCostProjection/BuilderStandardMCP";
import { BuilderReview } from "./components/Builder/MedicalCostProjection/BuilderReview";
import { BuilderAutoBA } from "./components/Builder/AutoBillAnalysis/BuilderAutoBA";
import { BuilderAutoBAUpload } from "./components/Builder/AutoBillAnalysis/BuilderAutoBAUpload";
import { BuilderAutoBAReview } from "./components/Builder/AutoBillAnalysis/BuilderAutoBAReview";
import { BuilderProMCP } from "./components/Builder/MedicalCostProjection/BuilderProMCP";
import { BuilderStandardBA } from "./components/Builder/MedicalBillAnalysis/BuilderStandardBA";
import { BuilderProBA } from "./components/Builder/MedicalBillAnalysis/BuilderProBA";
import { BuilderProBACodeReview } from "./components/Builder/BillAnalysisAndCodeReview/BuilderProBACodeReview";
import { BuilderExpertBACodeReview } from "./components/Builder/BillAnalysisAndCodeReview/BuilderExpertBACodeReview";
import { BuilderExpertMCP } from "./components/Builder/MedicalCostProjection/BuilderExpertMCP";
import { BuilderExpertBA } from "./components/Builder/MedicalBillAnalysis/BuilderExpertBA";
import { ThankYou } from "./components/ThankYou";
import { ContactThankYou } from "./components/ContactUs/ContactThankYou";
import { Submitted } from "./components/Submitted/Submitted";
import { SubmittedDetails } from "./components/Submitted/SubmittedDetails";
import { AuthWrapper } from "./components/Auth/AuthWrapper";
import { Confirm } from "./components/Auth/Confirm";
import { SetPassword } from "./components/Auth/SetPassword";
import { Feedback } from "./components/Feedback/Feedback";
import { PageNotFound } from "./components/Feedback/PageNotFound";
import { ServerError } from "./components/Feedback/ServerError";
import { Footer } from "./components/Footer";

// for demo only
import { EasyButton } from "./components/EasyButton/EasyButton";
import ScrollToTop from "./components/ScrollToTop";

export const App = () => {
    const { checkSession, currentRequest } = useContext(ContextGlobal);

    useEffect(() => {
        checkSession();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta name="description" content="You pick the treatment you want to see. Search our system of over 800 medical services. This is the fastest turnaround at the best price." />
                <title>AccuMed Access</title>
                <script async src="https://w.appzi.io/w.js?token=OEYvv"></script>
            </Helmet>
            <CookiesProvider>
                <div className="container-fluid">
                    <Router>
                        <NavBar />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <Frontpage />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/faq"
                                element={
                                    <>
                                        <WrapperFaq />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/products-and-pricing"
                                element={
                                    <>
                                        <WrapperProductsInfo />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/contact-us"
                                element={
                                    <>
                                        <ContactUs />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/about-us"
                                element={
                                    <>
                                        <WrapperAbout />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/contact-us/thank-you"
                                element={
                                    <>
                                        <ContactThankYou />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <>
                                        <Settings />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/my_reports"
                                element={
                                    <>
                                        <MyReports />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/request/details/:id"
                                element={
                                    <>
                                        <SubmittedDetails />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/request/:id"
                                element={
                                    <>
                                        <Submitted />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/builder/package_search"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderProgressBar step={2} />
                                        <BuilderPackageSearch />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/snapshot_upload"
                                element={
                                    <>
                                        <BuilderProgressBar step={2} upload={true} />
                                        <BuilderAutoBAUpload />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/builder/standard_mcp"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderProgressBar step={3} />
                                        <BuilderStandardMCP />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/snapshot"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderProgressBar step={3} upload={true} />
                                        <BuilderAutoBA />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/snapshot_review"
                                element={
                                    <>
                                        <BuilderProgressBar step={4} product={"standard_ba"} upload={true} />
                                        <BuilderAutoBAReview />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/builder/pro_mcp"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderProMCP />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/expert_mcp"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderExpertMCP />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/standard_bill_analysis"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderStandardBA />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/pro_bill_analysis"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderProBA />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/pro_bill_analysis_code_review"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderProBACodeReview />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/expert_bill_analysis"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderExpertBA />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/expert_bill_analysis_code_review"
                                element={
                                    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                        <BuilderExpertBACodeReview />
                                        <ScrollToTop />
                                    </Beforeunload>
                                }
                            />
                            <Route
                                path="/builder/review"
                                element={
                                    <>
                                        {currentRequest.request?.requestID ? (
                                            <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
                                                <BuilderProgressBar step={4} product={"standard_mcp"} />
                                                <BuilderReview />
                                                <ScrollToTop />
                                            </Beforeunload>
                                        ) : (
                                            <>
                                                <Frontpage />
                                                <ScrollToTop />
                                            </>
                                        )}
                                    </>
                                }
                            />
                            <Route
                                path="/builder/thank_you"
                                element={
                                    <>
                                        <ThankYou />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/eula"
                                element={
                                    <>
                                        <EulaCard />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/resetpassword"
                                element={
                                    <>
                                        <AuthWrapper />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/confirm"
                                element={
                                    <>
                                        <Confirm />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/setpassword"
                                element={
                                    <>
                                        <SetPassword />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/error"
                                element={
                                    <>
                                        <ServerError />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="/portal"
                                element={
                                    <>
                                        <EasyButton />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <>
                                        <PageNotFound />
                                        <ScrollToTop />
                                    </>
                                }
                            />
                        </Routes>
                    </Router>
                </div>
                <Footer />
                <Feedback />
            </CookiesProvider>
        </>
    );
};
