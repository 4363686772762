const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_FORM": {
            return { ...state, ...action.payload };
        }

        case "CLEAR_CONTACT_INFO": {
            return {
                ...state,
                contactFirstName: "",
                contactLastName: "",
                contactEmail: "",
            };
        }

        case "CLEAR_ALL": {
            return {};
        }

        default: {
            return state;
        }
    }
};

export default reducer;
