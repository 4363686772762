import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope, faMapMarkerAlt, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faLinkedinIn, faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { useNavigate } from "react-router";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner";

export const ContactUs = (props) => {
    const { setActivePage, session } = useContext(ContextGlobal);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setActivePage("contactUs");
        // eslint-disable-next-line
    }, []);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().required().label("Email"),
        // lawfirm: Yup.string().required().label("Lawfirm"),
        message: Yup.string().required().label("Message"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        mode: "onSubmit",
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    });

    const { contactUs } = useContext(ContextGlobal);

    const history = useNavigate();

    const onSubmit = async (data) => {
        // -- Constant Contact Form --
        const emailCC = document.getElementById("email_address_2");
        emailCC.value = data.email;
        const fNameCC = document.getElementById("first_name_2");
        fNameCC.value = data.firstName;
        const lNameCC = document.getElementById("last_name_2");
        lNameCC.value = data.lastName;
        const companyCC = document.getElementById("company_2");
        companyCC.value = data.lawFirm;
        document.querySelector(".ctct-form-button").click();

        // e.preventDefault();
        setLoading(true);
        data.contact = true;
        await contactUs(data);
        setLoading(false);
        history("/contact-us/thank-you");
    };

    const midSizeScreen = useMediaPredicate("(max-width: 767px)");

    useEffect(() => {
        setValue("firstName", session.firstName);
        setValue("lastName", session.lastName);
        setValue("email", session.email);
        setValue("lawFirm", session.lawFirm);
        // eslint-disable-next-line
    }, [session]);

    return (
        <div className="row mb-5">
            <div className="col-xxl-3 col-xl-2 col-lg-1 d-none d-md-block"></div>
            <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12">
                <div className="card card-product-no-height info-card transparent">
                    <div className="row d-flex">
                        <div className="col-md-5">
                            <div className="">
                                <div style={{ paddingBottom: "40px" }} className="card-body h-100 d-flex flex-column our-info-borders">
                                    <div className="row mt-4 mb-4 ms-2">
                                        <div className="col-md-12 contact-us-heading">Our Information</div>
                                    </div>
                                    <div className="row g-0 mb-4 ms-2">
                                        <div className="col-md-12">
                                            <div className="d-flex flex-row mb-3">
                                                <div className="ps-2">
                                                    <span className="fa-layers fa-fw fa-center-icon">
                                                        <FontAwesomeIcon icon={faCircle} color="#1DB8EA" />
                                                        <FontAwesomeIcon icon={faPhoneAlt} inverse transform="shrink-6" />
                                                    </span>
                                                </div>
                                                <div className="pt-1" style={{ fontWeight: "500" }}>
                                                    (844) 307-4487
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 ms-2 ">
                                        <div className="col-md-12">
                                            <div className="d-flex flex-row mb-3">
                                                <div className="ps-2">
                                                    <span className="fa-layers fa-fw fa-center-icon">
                                                        <FontAwesomeIcon icon={faCircle} color="#1DB8EA" />
                                                        <FontAwesomeIcon icon={faEnvelope} inverse transform="shrink-6" />
                                                    </span>
                                                </div>
                                                <div className="pt-1" style={{ fontWeight: "500" }}>
                                                    info@accumedintel.com
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 ms-2">
                                        <div className="col-md-12">
                                            <div className="d-flex flex-row mb-3">
                                                <div className="ps-2 pt-1">
                                                    <span className="fa-layers fa-fw fa-center-icon">
                                                        <FontAwesomeIcon icon={faCircle} color="#1DB8EA" />
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} inverse transform="shrink-6" />
                                                    </span>
                                                </div>
                                                <div style={{ fontWeight: "500" }}>6400 S. Fiddlers Green Circle, Suite 1320, Greenwood Village, CO 80111</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-auto">
                                        <div className="col-md-12 text-center">
                                            <svg style={{ width: "0", height: "0" }}>
                                                <defs>
                                                    <linearGradient id="grad1" x1="0%" y1="30%" x2="50%" y2="0%">
                                                        <stop offset="50%" stopColor="#34a853" />
                                                        <stop offset="50%" stopColor="#4285f4" />
                                                    </linearGradient>
                                                    <linearGradient id="grad2" x1="0%" y1="30%" x2="50%" y2="0%">
                                                        <stop offset="50%" stopColor="#fbbc05" />
                                                        <stop offset="50%" stopColor="#ea4335" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <div className="icon">
                                                <a href="https://g.page/r/CagIATsTYx-GEAg/review" target="_blank" rel="noreferrer noopener">
                                                    <FontAwesomeIcon icon={faGoogle} size="2x" />
                                                    <FontAwesomeIcon icon={faGoogle} size="2x" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4 text-center" style={{ fontSize: "12px" }}>
                                            Feeling good about your report?
                                            <br />
                                            Feel free to share your experience on Google!
                                        </div>
                                        {/* <div
                                            className="col-md-4 col-4"
                                            style={{ fontSize: "1.3em", paddingRight: "0px" }}
                                        >
                                            <span className="float-end">
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </span>
                                        </div>
                                        <div className="col-md-4 col-4 text-center" style={{ fontSize: "1.3em" }}>
                                            <span>
                                                <FontAwesomeIcon icon={faTwitter} />
                                            </span>
                                        </div>
                                        <div
                                            className="col-md-4 col-4"
                                            style={{ fontSize: "1.3em", paddingLeft: "0px" }}
                                        >
                                            <span>
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 order-first order-md-2 ">
                            <div
                                style={{
                                    paddingBottom: "40px",
                                    paddingRight: midSizeScreen ? "" : "36px",
                                    borderTopLeftRadius: midSizeScreen ? "1em" : "",
                                    borderBottomRightRadius: midSizeScreen ? "0" : "",
                                }}
                                className="card-body card-right-hand-side"
                            >
                                <div className="row mt-4">
                                    <div className="col-md-12 mb-2 contact-us-heading">Your information</div>
                                    <form className="contact-us-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label style={{ marginBottom: "0.2rem" }} className="form-label contact-form-required">
                                                    {/* First Name<sup className="ms-1" style={{ color: "red" }}>*</sup> */}
                                                </label>
                                                <input placeholder="First Name" type="text" className={"form-control " + (errors.firstName ? "is-invalid" : "")} {...register("firstName")} />
                                                <div className="invalid-feedback">{errors.firstName?.message}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <label style={{ marginBottom: "0.2rem" }} className="form-label">
                                                    {/* Last Name<sup className="ms-1" style={{ color: "red" }}>*</sup> */}
                                                </label>
                                                <input placeholder="Last Name" type="text" className={"form-control " + (errors.lastName ? "is-invalid" : "")} {...register("lastName")} />
                                                <div className="invalid-feedback">{errors.lastName?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label style={{ marginBottom: "0.2rem" }} className="form-label">
                                                    {/* Email<sup className="ms-1" style={{ color: "red" }}>*</sup> */}
                                                </label>
                                                <input placeholder="Email@email.com" type="email" className={"form-control " + (errors.email ? "is-invalid" : "")} {...register("email")} />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label style={{ marginBottom: "0.2rem" }} className="form-label">
                                                    {/* Company */}
                                                </label>
                                                <input placeholder="Company" type="text" className="form-control" {...register("lawFirm")} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-md-12 contact-us-heading mb-4">{/* Your message <sup className="ms-1" style={{ color: "red" }}>*</sup> */}</div>
                                            <div className="col-md-12">
                                                <textarea style={{ fontSize: "14pt" }} placeholder="Your message" rows="8" className={"form-control " + (errors.message ? "is-invalid" : "")} {...register("message")} />
                                                <div className="invalid-feedback">{errors.message?.message}</div>
                                            </div>
                                        </div>
                                        <div className="d-grid">
                                            <button className="btn access-primary-button" type="submit">
                                                {loading ? <Oval color="white" height={20} width={20} /> : "SUBMIT"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="ctct-inline-form" style={{ display: "none" }} data-form-id="923b123a-3fd1-4170-90d6-5bf4d9bfb2cb"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-2 col-lg-1 d-none d-md-block"></div>
        </div>
    );
};
