import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const FrontPageScrollButton = (props) => {

    // useEffect(())

    const message = props.message;
    
    // expects either "top" or "bottom"
    const location = props.location;
    const color = props.color;

    return (
        <div 
            className="scroll-to-section"
            onClick={(e) => {
                // e.stopPropagation();
                props.executeScroll();
            }}
        >
            {location == "bottom" ? (  
                <div className="d-flex justify-content-center align-items-center text-center">
                    <FontAwesomeIcon className="" icon={faCaretUp} size="2x" color={color} />
                </div>    
            ) : (null)}
            <div className="d-flex justify-content-center align-items-center text-center" style={{ fontSize: "18px", color: color }}>
                {message}
            </div>
            {location == "top" ? (
                <div className="d-flex justify-content-center align-items-center text-center">
                    <FontAwesomeIcon className="" icon={faCaretDown} size="2x" color={color} />
                </div>
            ) : (null)}
        </div>
    )
}
