import React, { useState, useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner";

import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { SOSModal } from "../../Modals/SOSModal";
import { EngageExpertModal } from "../../Modals/EngageExpertModal";
import { FormPatient } from "../../Forms/Updated/FormPatient";
import { FormUser } from "../../Forms/Updated/FormUser";
import { FormSharedContacts } from "../../Forms/Updated/FormShareContacts";
import { SharedList } from "../../Forms/Updated/SharedList";
import { DueDatePicker } from "../../DatePicker/DueDatePicker";

export const BuilderStandardMCP = (props) => {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        dateOfLoss: Yup.string().required().label("Date of Loss"),
        sex: Yup.string().required().max(1, "Sex is a required field").label("Sex"),
        location: Yup.string().required().label("Treatment location"),
        userFirstName: Yup.string().required().label("First Name"),
        userLastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        contacts: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required().label("First Name"),
                lastName: Yup.string().required().label("Last Name"),
                email: Yup.string().required().email().label("Email"),
            })
        ),
    });

    const { session, formPatientInfo, updateFormPatientInfo, currentRequest, setActivePage, updateRequest, updateUser, clearState } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("");
        // eslint-disable-next-line
    }, []);

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 768px)");

    const history = useNavigate();
    const [loading, setLoading] = useState(false);

    const [validAddress, setValidAddress] = useState(true);

    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema),
    });
    const watchFields = methods.watch(["location", "latitude", "longitude"]);

    useEffect(() => {
        if (session.email) {
            if (watchFields[3] !== "" && !session.lawFirm) {
                methods.setValue("lawFirm", watchFields[3], {
                    shouldDirty: true,
                });
            } else {
                methods.setValue("lawFirm", session.lawFirm, {
                    shouldDirty: true,
                });
            }
            updateFormPatientInfo({ lawFirm: session.lawFirm });

            methods.setValue("phoneNumber", session.phone, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ phone: session.phone });

            methods.setValue("userFirstName", session.firstName, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ userFirstName: session.firstName });

            methods.setValue("userLastName", session.lastName, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ userLastName: session.lastName });

            methods.setValue("email", session.email, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ email: session.email });
        }
    }, [session]);

    const [warning, setWarning] = useState(false);

    const checkNames = async () => {
        if (formPatientInfo.firstName == formPatientInfo.userFirstName && formPatientInfo.lastName == formPatientInfo.userLastName) {
            methods.setError("firstName", {
                type: "manual",
                message: "Same first name as the user first name",
            });
            methods.setError("lastName", {
                type: "manual",
                message: "Same last name as the user last name",
            });
            setWarning(true);
            return false;
        }
        setWarning(false);
        return true;
    };

    const onSubmit = async (data) => {
        setLoading(true);
        // true validate if the updated lat / long exists or if the current lat/long is valid
        const validate = (watchFields[1] === "" && watchFields[2] === "") || (formPatientInfo.longitude === "" && formPatientInfo.latitude === "") ? false : true;

        if (!validate) {
            methods.setValue("location", "");
            setValidAddress(false);
        } else {
            if ((await checkNames()) || warning) {
                if (await updateRequest(false, false)) {
                    if (formPatientInfo.lawFirm || formPatientInfo.phoneNumber) {
                        if (currentRequest.request.userID === session.userID && (session.phone !== formPatientInfo.phoneNumber || session.lawFirm !== formPatientInfo.lawFirm || session.firstName !== formPatientInfo.userFirstName || session.lastName !== formPatientInfo.userLastName)) {
                            // needs improvement...
                            await updateUser({
                                firstName: formPatientInfo.userFirstName,
                                lastName: formPatientInfo.userLastName,
                                phone: formPatientInfo.phoneNumber,
                                lawFirm: formPatientInfo.lawFirm,
                            });
                        }
                    }

                    history("/builder/review");
                } else {
                    methods.setError("email", {
                        type: "manual",
                        message: "fail",
                    });
                }
            }
        }

        setLoading(false);
    };

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <Link to="/builder/package_search">&lt; Package Search</Link>
            </div>
            <div className="card content-card mb-5">
                <FormProvider {...methods}>
                    <form className="standard-mcp-form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="row g-0">
                            <div className="col-lg-6 col-12" style={{ borderRight: midSizeScreen ? "" : "1px solid #e1e5ec", paddingRight: midSizeScreen ? "" : "40px" }}>
                                <div className="d-flex flex-row mb-3">
                                    <span className="faq-contact-link" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#sos">
                                        <div className="me-2" style={{ display: "inline-block" }}>
                                            <span>
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </span>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                fontSize: "0.9em",
                                                fontWeight: "500",
                                                paddingTop: "1px",
                                                display: phoneSize ? "inline" : "inline-block",
                                            }}
                                        >
                                            <span>NOT WHAT YOU WERE LOOKING FOR? CONTACT US FOR HELP</span>
                                        </div>
                                    </span>
                                </div>
                                <label htmlFor="report-title" className="form-label access-input-label-style">
                                    Report Title / Doctor Info / Patient & Case Information
                                </label>
                                <input
                                    type="text"
                                    className={"form-control access-input-style " + (methods.formState.errors.reportTitle ? "is-invalid" : "")}
                                    id="report-title"
                                    placeholder="e.g. Based on treatment recommendations from Dr. Rosen on Jan. 25, 2021"
                                    defaultValue={formPatientInfo.reportTitle ?? ""}
                                    aria-describedby="report-title-help"
                                    {...methods.register("reportTitle")}
                                    onChange={(e) => {
                                        updateFormPatientInfo({ reportTitle: e.target.value });
                                    }}
                                />
                                <div className="invalid-feedback">{methods.formState.errors.reportTitle?.message}</div>
                                <div id="report-title-help" className="form-text" style={{ marginTop: "8px" }}>
                                    Describe who recommended the treatment and when. Make sure to proof read it! Whatever you write here will go in the report.
                                </div>
                                <FormPatient validAddress={validAddress} setValidAddress={setValidAddress} warning={warning} />
                            </div>
                            <div className="col-lg-6 col-12" style={{ paddingLeft: midSizeScreen ? "" : "40px", marginTop: midSizeScreen ? "40px" : "" }}>
                                <FormUser />
                                <FormSharedContacts />
                                <SharedList />
                                <div className="row" style={{ marginTop: "40px" }}>
                                    <h3 className="mt-0 mb-3">Expert Engagement</h3>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="m-0">Do you need to engage an expert with your report? If so, please switch to an Expert level report.</p>
                                    </div>
                                </div>
                                <button type="button" className="btn access-secondary-button" style={{ marginTop: "24px" }} data-bs-toggle="modal" data-bs-target="#engage-expert">
                                    Engage an Expert
                                </button>
                                {/* blank row to seperate buttons */}
                                <div className="row"></div>
                                <div style={{ display: "none" }}>
                                    <DueDatePicker product={"type_product_medical_cost_projection_standard"} />
                                </div>
                                <button className="btn access-primary-button" style={{ marginTop: "40px" }} type="submit">
                                    {loading ? <Oval color="white" height={20} width={20} /> : <>{phoneSize ? "SAVE & REVIEW REPORT" : "SAVE INFO AND REVIEW REPORT"}</>}
                                </button>
                            </div>
                            <SOSModal standard={true} pro={false} expert={false} />
                            <EngageExpertModal product={"type_product_medical_cost_projection_standard"} />
                        </div>
                    </form>
                </FormProvider>
            </div>
        </>
    );
};
