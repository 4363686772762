import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner";

export const FormEula = (props) => {
    const packageID = props.packageID;

    const { signEula, session, addRequestPackages } = useContext(ContextGlobal);

    const validationSchema = Yup.object().shape({
        initial: Yup.string().required().label("Initial"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async () => {
        $("#closemodal").trigger("click");
        reset();
        await signEula(session.tempUserID);
        if (packageID !== null) {
            await addRequestPackages(packageID);
        }
        props.setAddToLoad(false);
    };

    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCheck = (e) => {
        setCheck(e.target.checked);
    };

    useEffect(() => {
        if (session.isSignedEula && !session.userID) {
            $("#closemodal").trigger("click");
        }
    }, [session.isSignedEula]);

    const phoneScreen = useMediaPredicate("(max-width: 450px)");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row d-flex align-items-end">
                <div className={phoneScreen ? "col-12" : "col-8"}>
                    <input className="form-check-input access-input-checkbox" type="checkbox" value="" id="eula-check" onChange={handleCheck} />
                    <label className="form-check-label" htmlFor="eula-check" style={{ paddingLeft: 10, marginTop: 3, fontSize: phoneScreen ? "13px" : "" }}>
                        I agree to AccuMed's{" "}
                        <a href="/eula" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#1DB8EA" }}>
                            User Agreement
                        </a>
                    </label>
                </div>
                <div className={phoneScreen ? "col-6 mt-4" : "col-3"}>
                    <label htmlFor="initials" className="form-label access-input-label-style">
                        Initial
                    </label>
                    <input type="text" placeholder="Initial" className={"access-input-style form-control " + (errors.initial ? "is-invalid" : "")} id="initial" {...register("initial")} />
                    <div className="invalid-feedback">{errors.initial?.message}</div>
                </div>
                <div className={phoneScreen ? "col-6 d-flex" : "col-1 d-flex justify-content-end"}>
                    <Link to="/files/User Agreement - Access 2021.pdf" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFileDownload} style={{ color: "#1DB8EA", fontSize: "2em" }} />
                    </Link>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 d-grid">
                    <button id="closemodal" data-bs-dismiss="modal" style={{ display: "none" }}>
                        Close
                    </button>
                    <button className="btn access-primary-button" type="submit" disabled={!check}>
                        {loading ? <Oval color="white" height={20} width={20} /> : "AGREE"}
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-1" style={{ fontSize: "12px" }}>
                    Have an account?&nbsp;
                    <span style={{ color: "#1db8ea", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#login" data-bs-dismiss="modal">
                        Login
                    </span>
                </div>
            </div>
        </form>
    );
};
