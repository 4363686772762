import React, { useContext } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { useFormContext } from "react-hook-form";


export const FormReviewYourInfo = (props) => {

    const { formPatientInfoSections, formPatientInfo, updateFormPatientInfo } = useContext(ContextGlobal);

    const methods = useFormContext();

    return (
        <>
            <div className="row">
                <div className="col-12 mb-3">
                    <h3 style={{ display: "inline-block" }}>Your info</h3>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-xxl-1 d-none d-xxl-block"></div>
                <div style={{ paddingLeft: "0px" }} className="col-xxl-3 d-none d-xxl-block text-muted">
                    First Name
                </div>
                <div style={{ paddingLeft: "0px" }} className="col-xxl-3 d-none d-xxl-block text-muted">
                    Last Name
                </div>
                <div style={{ paddingLeft: "0px" }} className="col-xxl-5 d-none d-xxl-block text-muted">
                    Email
                </div>
            </div>
            <div className="row g-0">
                <div className="col-xxl-1 d-none d-xxl-block">
                    <h3>
                        <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                            <FontAwesomeIcon icon={faCircle} color="#B4EFC9" />
                            <FontAwesomeIcon icon={faUser} transform="shrink-6" color="#71E099" />
                        </span>
                    </h3>
                </div>
                <div
                    className="col-xxl-3 col-xl-6  col-12"
                    style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.yourInfo ? "0px" : "12px" }}
                >
                    <label htmlFor="user-first-name" className="form-label mb-0 text-muted d-xxl-none">
                        <small>First Name</small>
                    </label>
                    <input
                        type="text"
                        className={
                            "form-control access-input-style review-page "  + (methods.formState.errors.userFirstName ? "is-invalid" : "" )
                        }
                        id="user-first-name"
                        defaultValue={formPatientInfo.userFirstName ?? ""}
                        {...methods.register("userFirstName")}
                        onChange={(e) => updateFormPatientInfo({ userFirstName: e.target.value })}
                        readOnly={!formPatientInfoSections.yourInfo}
                        style={{ paddingLeft: !formPatientInfoSections.yourInfo ? "" : "12px" }}
                    />
                    <div className="invalid-feedback">{methods.formState.errors.userFirstName?.message}</div>
                </div>
                <div
                    className="col-xxl-3 col-xl-6  col-12"
                    style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.yourInfo ? "0px" : "12px" }}
                >
                    <label htmlFor="user-last-name" className="form-label mb-0 text-muted d-xxl-none">
                        <small>Last Name</small>
                    </label>
                    <input
                        type="text"
                        className={
                            "form-control access-input-style review-page "  + (methods.formState.errors.userLastName ? "is-invalid" : "" )
                        }
                        id="user-last-name"
                        defaultValue={formPatientInfo.userLastName ?? ""}
                        {...methods.register("userLastName")}
                        onChange={(e) => updateFormPatientInfo({ userLastName: e.target.value })}
                        readOnly={!formPatientInfoSections.yourInfo}
                        style={{ paddingLeft: !formPatientInfoSections.yourInfo ? "" : "12px" }}
                    />
                    <div className="invalid-feedback">{methods.formState.errors.userLastName?.message}</div>
                </div>
                <div className="col-xxl-5 col-xl-12 col-12" style={{ paddingLeft: "0px" }}>
                    <label htmlFor="email" className="form-label mb-0 text-muted d-xxl-none">
                        <small>Email</small>
                    </label>
                    <input
                        type="email"
                        className={"form-control access-input-style review-page "  + (methods.formState.errors.email ? "is-invalid" : "") }
                        id="email"
                        defaultValue={formPatientInfo.email ?? ""}
                        {...methods.register("email")}
                        onChange={(e) => updateFormPatientInfo({ email: e.target.value })}
                        readOnly={!formPatientInfoSections.yourInfo}
                        style={{ paddingLeft: !formPatientInfoSections.yourInfo ? "" : "12px" }}
                    />
                    <div className="invalid-feedback">{methods.formState.errors.email?.message}</div>
                </div>
            </div>
        </>
    );
}
