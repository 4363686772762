import React, { useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { ConfirmationModal } from "../Modals/ConfirmationModal";
import { ListReports } from "./ListReports";

export const MyReports = (props) => {
    const [select, setSelect] = useState(undefined);
    const [product, setProduct] = useState(undefined);

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    return (
        <div className="row g-0" style={{ color: "#000046" }}>
            <div className="col-lg-1 d-none d-md-block"></div>
            <div className="col-lg-10 col-md-12 d-flex align-items-baseline">
                <div
                    className="card content-card mb-md-5 mb-5 transparent"
                    style={{ width: "100%", padding: "0px", marginLeft: "0px", marginRight: "0px" }}
                >
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="menu">
                                <div className="card-body" style={{ fontSize: "1.4em " }}>
                                    <div style={{ marginTop: phoneSize ? "20px" : "85px" }}></div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: select === undefined && !product ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === undefined && !product ? "600" : "",
                                                    textDecorationThickness:
                                                        select === undefined && !product ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect(undefined);
                                                    setProduct(undefined);
                                                }}
                                            >
                                                All Reports
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: select === "draft" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "draft" ? "600" : "",
                                                    textDecorationThickness: select === "draft" ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("draft");
                                                    setProduct(undefined);
                                                }}
                                            >
                                                Drafts
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: select === "being-generated" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "being-generated" ? "600" : "",
                                                    textDecorationThickness: select === "being-generated" ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("being-generated");
                                                    setProduct(undefined);
                                                }}
                                            >
                                                In Progress
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: select === "report-ready" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "report-ready" ? "600" : "",
                                                    textDecorationThickness: select === "report-ready" ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("report-ready");
                                                    setProduct(undefined);
                                                }}
                                            >
                                                Report Ready
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration:
                                                        product === "futures" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: product === "futures" ? "600" : "",
                                                    textDecorationThickness:
                                                        product === "futures" ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setProduct("futures"); // 3 is typeRequestID for futures
                                                    setSelect(undefined);
                                                }}
                                            >
                                                Future Analysis
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration:
                                                        product === "historical" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: product === "historical" ? "600" : "",
                                                    textDecorationThickness:
                                                        product === "historical" ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setProduct("historical"); // 1 is typeRequestID for historical
                                                    setSelect(undefined);
                                                }}
                                            >
                                                Historical Analysis
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration:
                                                        product === "code-review" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: product === "code-review" ? "600" : "",
                                                    textDecorationThickness:
                                                        product === "code-review" ? "2px" : "",
                                                }}
                                                onClick={() => {
                                                    setProduct("code-review"); // 1 is typeRequestID for historical
                                                    setSelect(undefined);
                                                }}
                                            >
                                                Bill Analysis + Code Review
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: phoneSize ? "20px" : "50px"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 column-right-hand-side">
                            <div className="card-body" style={{ padding: 0 }}>
                                <ListReports select={select} product={product} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-1 d-none d-md-block"></div>
            <ConfirmationModal frontpage={true} />
        </div>
    );
};
