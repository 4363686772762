import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { UpdatePassword } from "../Auth/UpdatePassword";
import { useMediaPredicate } from "react-media-hook";

export const FormSettingsMyInfo = (props) => {
    const phoneScreen = useMediaPredicate("(max-width: 500px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");

    const { session, updateUser } = useContext(ContextGlobal);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        lawFirm: Yup.string().required().label("Company").nullable(),
    });

    const [edit, setEdit] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const {
        register,
        trigger,
        setValue,
        formState: { errors },
        watch,
    } = useForm({ mode: "onBlur", resolver: yupResolver(validationSchema) });

    const formData = watch();

    useEffect(() => {
        if (session.email !== undefined) {
            setValue("userID", session.userID);
            setValue("firstName", session.firstName);
            setValue("lastName", session.lastName);
            setValue("email", session.email);
            setValue("phone", session.phone);
            setValue("lawFirm", session.lawFirm);
            setValue("position", session.position);
        }
        // eslint-disable-next-line
    }, [session]);

    const triggerValidation = async () => {
        const validate = await trigger();
        return validate ? true : false;
    };

    const onSubmit = async () => {
        const validation = await triggerValidation();

        if (edit && validation) {
            updateUser({
                firstName: formData.firstName,
                lastName: formData.lastName,
                lawFirm: formData.lawFirm,
                phone: formData.phone,
                position: formData.position,
            });
        } else {
            for (const [key] of Object.entries(errors)) {
                setValue(key, session[key]);
                trigger(key);
            }
        }
        setEdit(!edit);
    };

    return (
        <div className="m-4">
            <div className="row">
                <input type="text" className={"form-control access-input-style " + (errors.firstName ? "is-invalid" : "")} id="userID" {...register("userID")} readOnly={!edit} style={{ display: "none" }} />
                <div className="col-6">
                    <label htmlFor="first-name" className="form-label access-input-label-style">
                        First Name{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input type="text" className={"form-control access-input-style " + (errors.firstName ? "is-invalid" : "")} id="first-name" defaultValue="" {...register("firstName")} readOnly={!edit} style={{ paddingLeft: !edit ? "0px" : "12px" }} />
                    <div className="invalid-feedback">{errors.firstName?.message}</div>
                </div>
                <div className="col-6">
                    <label htmlFor="last-name" className="form-label access-input-label-style">
                        Last Name{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input type="text" className={"form-control access-input-style " + (errors.lastName ? "is-invalid" : "")} id="last-name" defaultValue="" {...register("lastName")} readOnly={!edit} style={{ paddingLeft: !edit ? "0px" : "12px" }} />
                    <div className="invalid-feedback">{errors.lastName?.message}</div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <label htmlFor="email" className="form-label access-input-label-style">
                        Email{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input type="email" className={"form-control access-input-style " + (errors.email ? "is-invalid" : "")} id="email" defaultValue="" {...register("email")} readOnly={true} style={{ paddingLeft: !edit ? "0px" : "12px" }} />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <label htmlFor="phone-number" className="form-label access-input-label-style">
                        Phone Number
                    </label>
                    <input type="tel" className="form-control access-input-style " id="phone-number" defaultValue="" {...register("phone")} readOnly={!edit} style={{ paddingLeft: !edit ? "0px" : "12px" }} />
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <label htmlFor="law-firm" className="form-label access-input-label-style">
                        Company{" "}
                        <sup className="ms-1" style={{ color: "red" }}>
                            *
                        </sup>
                    </label>
                    <input type="text" className={"form-control access-input-style " + (errors.lawFirm ? "is-invalid" : "")} id="law-firm" defaultValue="" {...register("lawFirm")} readOnly={!edit} style={{ paddingLeft: !edit ? "0px" : "12px" }} />
                    <div className="invalid-feedback">{errors.lawFirm?.message}</div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <label htmlFor="position" className="form-label access-input-label-style">
                        Position (Lawyer, Paralegal, Case Manager, etc.)
                    </label>
                    <input type="text" className={"form-control access-input-style " + (errors.position ? "is-invalid" : "")} id="position" defaultValue="" {...register("position")} readOnly={!edit} style={{ paddingLeft: !edit ? "0px" : "12px" }} />
                    <div className="invalid-feedback">{errors.position?.message}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-12">
                    {edit ? (
                        <button className="btn access-primary-button" style={{ marginTop: "40px" }} onClick={() => onSubmit()} disabled={Object.keys(errors).length > 0}>
                            SAVE MY INFO
                        </button>
                    ) : (
                        <button className="btn access-primary-button" style={{ marginTop: "40px" }} onClick={() => onSubmit()}>
                            EDIT MY INFO
                        </button>
                    )}
                    <p>
                        <small style={{ color: "#1db8ea", fontSize: 12 }}>
                            <sup className="ms-1" style={{ color: "red" }}>
                                *
                            </sup>{" "}
                            Required information
                        </small>
                    </p>
                </div>
                <div className={"col-xl-6 col-12 text-lg-start text-xl-end"}>
                    {session.isGuest ? null : (
                        // <a
                        //     className="btn access-primary-blue"
                        //     style={{ marginTop: "40px", paddingTop: "10px" }}
                        //     href="/resetpassword?set=true"
                        // >
                        //     SET PASSWORD
                        // </a>
                        <button
                            className="btn access-primary-blue"
                            style={{ marginTop: "40px" }}
                            data-bs-toggle="modal"
                            data-bs-target="#updatePassword"
                            onClick={(e) => {
                                setResetForm(true);
                            }}
                        >
                            UPDATE PASSWORD
                        </button>
                    )}
                </div>
            </div>
            <UpdatePassword resetForm={resetForm} setResetForm={setResetForm} />
        </div>
    );
};
