import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";
import { FormUserContact } from "./FormUserContact";
import { Oval } from "react-loader-spinner";
import ReactPaginate from "react-paginate";

export const FormSettingContacts = (props) => {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
    });

    const { contactList, addToContactList, suggestedContactList, addSuggestedContact } = useContext(ContextGlobal);
    // console.log("contact list: ", contactList);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({ mode: "onBlur", resolver: yupResolver(validationSchema) });

    const onSubmit = async (data) => {
        setLoading(true);
        await addToContactList(data);
        setValue("firstName", "");
        setValue("lastName", "");
        setValue("email", "");
        setLoading(false);
    };

    const [open, setOpen] = useState();
    const [plusHover, setPlusHover] = useState();
    const [loading, setLoading] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        if (suggestedContactList.length > 0) {
            setPageCount(Math.ceil(suggestedContactList.length / 10));
        } else {
            setPageCount(0);
        }
    }, [suggestedContactList]);

    const handlePageClick = (data) => {
        setSelectedPage(data.selected);
        setOffset(Math.ceil(data.selected * 10));
    };

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const largeSizeScreen = useMediaPredicate("(max-width: 1199px)");

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="mx-4 mt-4">
                <div className="row d-flex">
                    <div className="col-xl-3 col-md-6 col-12 flex-fill">
                        <label htmlFor="contact-first-name" className="form-label access-input-label-style">
                            First Name{" "}
                            <sup className="ms-1" style={{ color: "red" }}>
                                *
                            </sup>
                        </label>
                        <input placeholder="First Name" type="text" className={"form-control access-input-style " + (errors.firstName ? "is-invalid" : "")} id="contact-first-name" defaultValue="" {...register("firstName")} />
                        <div className="invalid-feedback">{errors.firstName?.message}</div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 flex-fill">
                        <label htmlFor="contact-last-name" className="form-label access-input-label-style">
                            Last Name{" "}
                            <sup className="ms-1" style={{ color: "red" }}>
                                *
                            </sup>
                        </label>
                        <input placeholder="Last Name" type="text" className={"form-control access-input-style " + (errors.lastName ? "is-invalid" : "")} id="contact-last-name" defaultValue="" {...register("lastName")} />
                        <div className="invalid-feedback">{errors.lastName?.message}</div>
                    </div>
                    <div className="col-xl-3 col-md-12 col-12 mt-xl-0 mt-md-2 flex-fill">
                        <label htmlFor="contact-email" className="form-label access-input-label-style">
                            Email{" "}
                            <sup className="ms-1" style={{ color: "red" }}>
                                *
                            </sup>
                        </label>
                        <input type="text" placeholder="Email" className={"form-control access-input-style " + (errors.email ? "is-invalid" : "")} id="contact-email" defaultValue="" {...register("email")} />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                    <div className="col-xl-1 col-md-12 col-12 flex-fill my-2">
                        <button
                            type="submit"
                            className="btn access-primary-button"
                            style={{
                                marginTop: "24px",
                            }}
                        >
                            {loading ? <Oval color="white" height={20} width={20} /> : "ADD"}
                        </button>
                    </div>
                </div>
            </form>
            <div className="mx-4 mb-4">
                <div style={{ marginTop: "40px", marginBottom: "24px", font: "600 15pt Montserrat" }}>My Contacts</div>
                {contactList.length > 0 && !largeSizeScreen && (
                    <div className="row g-0 d-flex mb-2" style={{ color: "#1DB8EA" }}>
                        <div className="col-1"></div>
                        <div className="col-3">First Name</div>
                        <div className="col-3">Last Name</div>
                        <div className="col-4">Email</div>
                        <div className="col-1 flex-fill"></div>
                    </div>
                )}
                {contactList.length > 0 ? contactList.map((contact, index) => <FormUserContact open={open} setOpen={setOpen} contact={contact} index={index} />) : <p>No contacts added</p>}
                <div style={{ marginTop: "55px", marginBottom: "24px", font: "600 15pt Montserrat" }}>Suggested Contacts</div>
                {(suggestedContactList.length && !largeSizeScreen) > 0 && (
                    <div className="row g-0 d-flex mb-2" style={{ color: "#1DB8EA" }}>
                        <div className="col-1"></div>
                        <div className="col-3">First Name</div>
                        <div className="col-3">Last Name</div>
                        <div className="col-4">Email</div>
                        <div className="col-1"></div>
                    </div>
                )}
                {suggestedContactList.length > 0 ? (
                    suggestedContactList.slice(offset, offset + 10).map((contact, index) => (
                        <div className="row g-0 d-flex mb-2 shared-contact-list" key={index} style={{ marginLeft: 0 }}>
                            <div className="col-xl-1 d-none d-xl-block">
                                <h3 style={{ marginTop: "2px", marginBottom: "0px" }}>
                                    <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                        <FontAwesomeIcon icon={faCircle} color={sharedIcons[index % sharedIcons.length].background} />
                                        <FontAwesomeIcon icon={faUser} transform="shrink-6" color={sharedIcons[index % sharedIcons.length].icon} />
                                    </span>
                                </h3>
                            </div>
                            <div className="col-xl-3 col-md-6 col-6" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <div
                                    style={{
                                        borderBottom: "2px solid #1db8ea",
                                        paddingBottom: "0.375rem",
                                        paddingTop: "0.375rem",
                                    }}
                                >
                                    {contact.firstName}
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-6" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <div
                                    style={{
                                        borderBottom: "2px solid #1db8ea",
                                        paddingBottom: "0.375rem",
                                        paddingTop: "0.375rem",
                                    }}
                                >
                                    {contact.lastName}
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-12 col-12 pt-xl-0 pt-2" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <div
                                    style={{
                                        borderBottom: "2px solid #1db8ea",
                                        paddingBottom: "0.375rem",
                                        paddingTop: "0.375rem",
                                    }}
                                >
                                    {contact.email}
                                </div>
                            </div>
                            <div className={"col-xl-1 col-md-12 col-12 text-end text-xl-start" + (midSizeScreen ? "offset-10" : "")}>
                                {contactList.some((myContact) => {
                                    return myContact.email === contact.email;
                                }) ? null : (
                                    <span className="fa-layers fa-fw fa-center-icon" style={{ cursor: "pointer" }} onClick={(e) => addSuggestedContact(contact.userID)} onMouseEnter={(e) => setPlusHover(index)} onMouseLeave={(e) => setPlusHover()}>
                                        <FontAwesomeIcon style={{ display: plusHover !== index ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                                        <FontAwesomeIcon color={plusHover === index ? "white" : "#1db8ea"} transform="shrink-8" icon={faPlus} />
                                    </span>
                                )}
                            </div>
                            <div className="d-xl-none mt-4"></div>
                        </div>
                    ))
                ) : (
                    <p>No contacts added</p>
                )}
                {suggestedContactList.length > 10 && <ReactPaginate previousLabel={"previous"} nextLabel={"next"} breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={"pagination"} activeClassName={"active"} forcePage={selectedPage} />}
            </div>
        </>
    );
};
