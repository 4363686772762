import React, { useContext, useEffect, useState } from "react";

import { ContextGlobal } from "../../contexts/ContextGlobal";
import { Oval } from "react-loader-spinner";

export const DownloadReportsModal = (props) => {
    const id = props.id;
    const [documents, setDocuments] = useState([]);
    const [chosenDocs, setChosenDocs] = useState([]);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingAllDownload, setLoadingAllDownload] = useState(false);

    const { loadRequest, currentRequest, downloadMultipleS3 } = useContext(ContextGlobal);

    useEffect(() => {
        async function getData() {
            await loadRequest(id);
        }

        if (id !== undefined) {
            getData();
        }
    }, [id]);

    useEffect(() => {
        if (currentRequest.document && Object.keys(currentRequest.document).length != 0) {
            setDocuments(currentRequest.document);
        } else {
            setDocuments([]);
        }
    }, [currentRequest]);

    const handleCheck = (e, doc) => {
        if (e.target.checked) {
            setChosenDocs([...chosenDocs, doc]);
        } else {
            const filtered = chosenDocs.filter((chosen) => doc.id != chosen.id);
            setChosenDocs(filtered);
        }
    };
    const handleMultipleDownload = async () => {
        setLoadingDownload(true);
        await downloadMultipleS3(chosenDocs);
        setLoadingDownload(false);
    };

    const handleAllDownload = async () => {
        setLoadingAllDownload(true);
        await downloadMultipleS3(documents);
        setLoadingAllDownload(false);
    };

    return (
        <div className="modal fade" id="download-docs" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "650px" }}>
                <div className="modal-content modal-eula-sign">
                    <div className="modal-header justify-content-center" style={{ borderBottom: "none", padding: 0 }}>
                        <h3 className="modal-title w-100 text-center" style={{ fontWeight: "700", color: "#000064" }}>
                            Select docs for download.
                        </h3>
                    </div>
                    <div className="modal-body">
                        {currentRequest.document ? (
                            <>
                                {documents.map((doc, index) => (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-text clear-input">
                                                        <input
                                                            className="form-check-input mt-0"
                                                            type="checkbox"
                                                            value={doc.id}
                                                            aria-label="Doc name checkbox"
                                                            onClick={(e) => {
                                                                handleCheck(e, doc);
                                                            }}
                                                        />
                                                    </div>
                                                    <span className="form-control" aria-label="Doc name">
                                                        {doc.name}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <button
                                            className="btn access-primary-button"
                                            onClick={(e) => {
                                                handleMultipleDownload();
                                            }}
                                        >
                                            {loadingDownload ? <Oval color="white" height={20} width={20} /> : "DOWNLOAD SELECTED"}
                                        </button>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button
                                            className="btn access-primary-blue"
                                            onClick={(e) => {
                                                handleAllDownload();
                                            }}
                                        >
                                            {loadingAllDownload ? <Oval color="white" height={20} width={20} /> : "DOWNLOAD ALL"}
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
