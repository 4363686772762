import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "250px",
};

const setCenter = (lat, long) => {
    const center = {
        lat: Number(lat),
        lng: Number(long),
    };
    return center;
};

const Map = (props) => {
    // console.log(props.latLong);
    const libaries = ["places"];
    const center = setCenter(props.latLong.lat, props.latLong.long);

    return (
        <LoadScript googleMapsApiKey="AIzaSyDL3z1KGSpAPVawCDPTbRTKSEAjezZHd-E" libraries={libaries}>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
};

export default Map;
