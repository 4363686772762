import { useContext, useState } from "react";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ContextGlobal } from "../../contexts/ContextGlobal";

export const ConfirmationModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const { session, sendConfirmEmail } = useContext(ContextGlobal);

    const handleClick = async () => {
        setLoading(true);
        const res = await sendConfirmEmail({
            email: session.email,
            type: "email_confirmation",
        });
        setLoading(false);
        if (res) {
            setSent(true);
        }
    };

    return (
        <div className="modal fade" id="confirmation_email" tabIndex="-1" aria-hidden="true" data-bs-backdrop={"static"} data-bs-keyboard={"false"}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-eula-sign">
                    {props.frontpage ? <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" /> : null}
                    <div className="modal-header justify-content-center" style={{ borderBottom: "none", padding: 0 }}>
                        <div className="row">
                            <div className="col-12">
                                <h4 style={{ marginBottom: "16px", font: "700 17pt Montserrat" }}>
                                    <span style={{ color: "#000046", font: "700 20pt Montserrat" }}>Please Verify Email</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">We'll send you an email with a link. Click the link to verify your email address!</div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button
                                    className="btn access-primary-button"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        handleClick();
                                    }}
                                    disabled={sent}
                                >
                                    {loading ? <Oval color="white" height={20} width={20} /> : <>{sent ? "Email has been sent" : "SEND LINK"}</>}
                                </button>
                                {sent ? (
                                    <div className="d-grid col-xxl-7 col-lg-7 col-md-8 col-10 mx-auto">
                                        <a href="/" className="btn access-large-button">
                                            BACK TO HOMEPAGE
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
