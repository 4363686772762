import React, { useEffect, useState, useContext, useRef, useLayoutEffect } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";
import { useMediaPredicate } from "react-media-hook";

export const SwitchProductButton = (props) => {
    const { currentRequest, createRequest, patchRequest } = useContext(ContextGlobal);

    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState("");
    const [name, setName] = useState("");
    const [link, setLink] = useState("");

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    const ref = useRef(null);

    useLayoutEffect(() => {
        ref.current.style.setProperty("font-size", "10pt", "important");
    }, []);

    useEffect(() => {
        setProduct(props.product);
        setName(props.name);
        setLink(props.link);
    }, [props]);

    const mobileName = (name) => {
        if (name !== "") {
            const nameArray = name.split(" ");
            // ["pro", "medical", "cost", "projection"]
            var tempName = nameArray[0].toUpperCase() + " ";
            nameArray.forEach((name, index) => {
                if (index > 0) {
                    tempName += name.charAt(0);
                }
            });
            return tempName;
        }
    };

    const closeRef = useRef(null);

    const closeModal = () => {
        closeRef.current.click();
    };

    return (
        <>
            <button
                className="btn access-primary-button flex-fill"
                type="button"
                onClick={async (e) => {
                    setLoading(true);
                    if (currentRequest.request?.requestID) {
                        await patchRequest(currentRequest.request.requestID, "typeProduct", product);
                    } else {
                        await createRequest(product);
                    }
                    setLoading(false);
                    closeModal();
                    history(link);
                }}
                ref={ref}
            >
                {loading ? <Oval color="white" height={20} width={20} /> : <>SWITCH TO {phoneSize ? mobileName(name) : name.toUpperCase()} REPORT</>}
            </button>
            {/* To close modal */}
            <button style={{ display: "none" }} data-bs-dismiss="modal" ref={closeRef}></button>
        </>
    );
};
