import React, { useContext, useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import GeneralQuestions from "./GeneralQuestions.js";
import ExpertQuestions from "./ExpertQuestions.js";
import LifeCareQuestions from "./LifeCareQuestions.js";
import { ContextGlobal } from "../../contexts/ContextGlobal.js";

import { SwitchTransition, CSSTransition } from "react-transition-group";

export const WrapperFaq = (props) => {
    const { setActivePage } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("faq");
        // eslint-disable-next-line
    }, []);

    const history = useNavigate();
    const [select, setSelect] = useState("general");

    const selectRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash !== "") {
            setSelect(location.hash.replace(/[#]/g, ""));
        } else {
            selectRef.current.click();
        }
    }, [location]);

    return (
        <div className="row g-0">
            <div className="col-md-10 offset-md-1 col-12">
                <div className="card card-product-no-height mb-5 transparent">
                    <div className="row g-0">
                        {/* Questions */}
                        <div className="col-xl-3 col-12">
                            <div className="menu" style={{ height: "100%" }}>
                                <div className="card-body sticky-top" style={{ fontSize: "1.4em", padding: "40px" }}>
                                    {/* <div className="row g-0 mt-2 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "all" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "all" ? "600" : "",
                                                    color: "#000046"
                                                }}
                                                onClick={() => {
                                                    setSelect("all");
                                                }}
                                            >
                                                All Questions
                                            </span>
                                        </div>
                                    </div> */}
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "general" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "general" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("general");
                                                }}
                                                ref={selectRef}
                                            >
                                                <a href="#general" style={{ textDecoration: "none", color: "#000046" }}>
                                                    General Questions
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "expert" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "expert" ? "600" : "",
                                                    color: "#000046",
                                                }}
                                                onClick={() => {
                                                    setSelect("expert");
                                                }}
                                            >
                                                <a href="#expert" style={{ textDecoration: "none", color: "#000046" }}>
                                                    Expert Services
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="row g-0 mb-4 mx-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "fair" ? "underline" : "",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "fair" ? "700" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("fair");
                                                }}
                                            >
                                                Other Sources for Estimates FairHealth
                                            </span>
                                        </div>
                                    </div> */}
                                    <a href="/contact-us" rel="noopener noreferrer" target="_blank" className="faq-contact-link">
                                        <div className="d-flex flex-row mb-3">
                                            <div className="me-2 mx-2">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </div>
                                            <div className="p-0" style={{ fontSize: "0.6em", fontWeight: "500" }}>
                                                NOT WHAT YOU WERE LOOKING FOR? <br /> CONTACT US FOR HELP
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Content */}
                        {/* Should swap out depending on the links above */}
                        <div className="col-xl-9 col-12 column-right-hand-side">
                            <div className="card-body" id="general" style={{ padding: "40px" }}>
                                <>
                                    <GeneralQuestions />
                                    <ExpertQuestions />
                                    {/* {select === "general" || select === "all" ? <GeneralQuestions /> : null} */}
                                    {/* {select === "expert" || select === "all" ? <ExpertQuestions select={select} /> : null} */}
                                    {/* {select === "other" || select === "all" ? <LifeCareQuestions select={select} /> : null} */}
                                    {/* {select === "fair" || select === "all" ? <FairHealthQuestions /> : null} */}
                                </>
                            </div>
                        </div>
                        {/* <SwitchTransition>
                            <CSSTransition
                                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                classNames="fade-faq"
                                key={select}
                            >
                                <div className="col-xl-9 col-12 column-right-hand-side">
                                    <div className="card-body" style={{ padding: "40px" }}>
                                        <>
                                            {select === "general" || select === "all" ? <GeneralQuestions /> : null}
                                            {select === "expert" || select === "all" ? <ExpertQuestions select={select} /> : null}
                                            {select === "other" || select === "all" ? <LifeCareQuestions select={select} /> : null}
                                            {select === "fair" || select === "all" ? <FairHealthQuestions /> : null}
                                        </>
                                    </div>
                                </div>
                            </CSSTransition>
                        </SwitchTransition> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
