import React, { useState, useContext, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { Oval } from "react-loader-spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Set Password form for users setting up their accounts from our links

export const SetPassword = (props) => {
    const [token, setToken] = useState();
    const [validated, setValidated] = useState(null);
    const { validateUserToken, resetPassword, session, removeSession } = useContext(ContextGlobal);

    const location = useLocation();
    const query = useQuery();
    const history = useNavigate();

    useEffect(() => {
        async function validateToken(token) {
            const res = await validateUserToken(token);
            // console.log("validate token: ", res);
            setValidated(res);
        }

        if (location.search !== "") {
            const token = query.get("token");
            validateToken(token);
            setToken(token);
        }
    }, [location]);

    useEffect(() => {
        if (session.email) {
            removeSession();
        }
    }, [session]);

    const validationSchema = Yup.object().shape({
        password: Yup.string().required().label("New Password"),
        confirmPassword: Yup.string().required().label("Confirm Password"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
    } = useForm({
        mode: "onSubmit",
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [redirect, setRedirect] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setConfirm(false);
        if (data.confirmPassword === data.password) {
            setLoading(true);
            const response = await resetPassword({
                token: token,
                password: { password: data.confirmPassword },
            });
            setLoading(false);
            if (response) {
                setUpdated(true);
                setRedirect(true);
                setTimeout(() => {
                    setRedirect(false);
                    history("/");
                }, 5000); // 5 seconds
            } else {
                setUpdated(false);
            }
        } else {
            setError("password");
            setError("confirmPassword");
            setConfirm(true);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xxl-3 col-lg-2 col-md-1"></div>
                <div className="col-xxl-6 col-lg-8 col-md-10 col-12">
                    <div className="card content-card" style={{ marginTop: "20vh", minHeight: "30vh" }}>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4 style={{ marginBottom: "16px", font: "700 17pt Montserrat" }}>
                                    <span style={{ color: "#000046", font: "700 20pt Montserrat" }}>Set password</span>
                                </h4>
                            </div>
                        </div>
                        {/* Waiting for validation on Token */}
                        {validated === null ? (
                            <div className="row">
                                <div className="col-12">
                                    <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
                                </div>
                            </div>
                        ) : null}
                        {/* Token successfully validated */}
                        {validated === true && !updated ? (
                            <>
                                <div className="row">
                                    <div className="col-12 pass-wrapper">
                                        <label style={{ marginBottom: "0.2rem" }} className="form-label access-input-label-style">
                                            Password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                placeholder="Password"
                                                type={showPassword ? "text" : "password"}
                                                className={"form-control access-input-style " + (errors.password ? "is-invalid" : "")}
                                                id="password"
                                                defaultValue={""}
                                                autocomplete="new-password"
                                                {...register("password")}
                                                aria-describedby="new-password-eye"
                                                // onChange={(e) => setNewPassword(e.target.value )}
                                            />
                                            <span className={"input-group-text show-hide-password" + (errors.password ? "-invalid" : "")} id="new-password-eye">
                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showPassword ? faEyeSlash : faEye} onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} />
                                            </span>
                                            <div className="invalid-feedback">{errors.password?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 pass-wrapper">
                                        <label style={{ marginBottom: "0.2rem" }} className="form-label access-input-label-style">
                                            Confirm Password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                placeholder="Confirm Password"
                                                type={showConfirm ? "text" : "password"}
                                                className={"form-control access-input-style " + (errors.confirmPassword ? "is-invalid" : "")}
                                                id="confirm-password"
                                                defaultValue={""}
                                                autocomplete="new-password"
                                                {...register("confirmPassword")}
                                                aria-describedby="confirm-password-eye"
                                                // onChange={(e) => handleCompare(e.target.value )}
                                            />
                                            <span className={"input-group-text show-hide-password" + (errors.confirmPassword ? "-invalid" : "")} id="confirm-password-eye">
                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={"#a0a0a0"} icon={showConfirm ? faEyeSlash : faEye} onClick={() => (showConfirm ? setShowConfirm(false) : setShowConfirm(true))} />
                                            </span>
                                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                        </div>
                                        {confirm ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "0.95em",
                                                    color: "#bb0046",
                                                }}
                                            >
                                                Both passwords must match!
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <button className="btn access-primary-button" style={{ width: "100%" }}>
                                            {loading ? <Oval color="white" height={20} width={20} /> : "SET"}
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* Failed to validate Token */}
                        {/* figure out how to close Login modal */}
                        {validated === false ? (
                            <div className="row">
                                <div className="col-12">
                                    We apologize, but this link has EXPIRED. If you need to reset your password please&nbsp;
                                    <Link to="/resetpassword" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                        <span style={{ color: "rgb(29, 184, 234)", cursor: "pointer" }}>click here.</span>
                                    </Link>
                                </div>
                                <div className="d-grid col-xxl-7 col-lg-7 col-md-8 col-10 mx-auto">
                                    <a href="/" className="btn access-large-button">
                                        HOME
                                    </a>
                                </div>
                            </div>
                        ) : null}
                        {/* Successfully updated password */}
                        {updated === true ? (
                            <div className="row mt-4">
                                <div className="col-12 text-center">Password successfully set!</div>
                                <div className="col-12 text-center">You will be redirected to the Home page. Please log in</div>
                                {redirect ? (
                                    <div className="col-12">
                                        <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
                                    </div>
                                ) : null}
                                {/* <div className="col-12 text-center mt-4">
                                    <a
                                        href="/"
                                        className="btn access-large-button"
                                        style={{
                                            textDecoration: "none",
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                            width: "50%",
                                        }}
                                    >
                                        HOME
                                    </a>
                                </div> */}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col-xxl-3 col-lg-2 col-md-1"></div>
            </div>
        </form>
    );
};
