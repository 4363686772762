import React, { useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { DownloadSampleModal } from "../Modals/DownloadSampleModal";

export const SampleProducts = (props) => {

    const [product, setProduct] = useState('');

    const phoneSize = props.phoneSize;
    const midSizeScreen = props.midSizeScreen;
    const laptopSize = props.laptopSize;

    return (
        <>
            <div id="samples"></div>
            <div className="row g-0 mb-3 mx-2">
                <div className="col-md-12 mt-5 faq-headings" style={{ color: "#000046" }}>
                    Sample Products:
                    <hr/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-5 offset-lg-1 d-flex flex-column justify-content-center">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">Standard Medical Cost Projection</h3>
                            <b>Fast, easy, inexpensive</b>
                            <p className="card-text">
                                You pick the treatment you want to see. Search our system of over 800 medical
                                services. This is the fastest turnaround at the best price.
                            </p>
                            <button 
                                className="btn access-primary-button" 
                                data-bs-toggle="modal" 
                                data-bs-target="#download-sample-modal" 
                                style={{ textDecoration: "none", color: "white", width: "100%" }}
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    setProduct("type_product_medical_cost_projection_standard");
                                }}
                            >
                                View Sample Report
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                    <div className="d-flex justify-content-center">
                        {phoneSize ? (
                            <img src="/img/Access_Homepage SMCP Report Image SM.png" className="img-fluid" alt="Standard MCP"/>
                        ) : (
                            <img src="/img/Access_Homepage SMCP Report Image.png" className="img-fluid d-block" alt="..."/>
                        )}
                    </div>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-4 offset-lg-1">
                    <div className="d-flex justify-content-center">
                        {phoneSize ? (
                            <img src="/img/Access_Homepage PMCP Report Image SM.png" className="img-fluid" alt="Pro MCP"/>
                        ) : (
                            <img src="/img/Access_Homepage PMCP Report Image.png" className="img-fluid d-block" alt="..."/>
                        )}
                    </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">Pro Medical Cost Projection</h3>
                            <b>Estimates built by Certified Professional Coders</b>
                            <p className="card-text">
                                Not sure what treatment is needed? Leave it to the pros. Send us the doctor's
                                recommendation and we'll take care of the rest.
                            </p>
                            <button 
                                className="btn access-primary-button" 
                                data-bs-toggle="modal" 
                                data-bs-target="#download-sample-modal" 
                                style={{ textDecoration: "none", color: "white", width: "100%" }}
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    setProduct("type_product_medical_cost_projection_pro");
                                }}
                            >
                                View Sample Report
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <DownloadSampleModal product={product} />
        </>
    )
}
