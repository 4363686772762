import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';

export const WhoWeAre = (props) => {

    return (
        <>
            <div className="row g-0 mb-3 mt-2 mx-2">
                <div className="col-md-12 faq-headings" style={{ color: "#000046" }}>
                    We Are...
                </div>
                <div className="col-12 mt-4">
                    AccuMed is a technology company, focused on helping attorneys establish the reasonable value of medical damages in personal injury cases. 
                    With expertise in both future medical cost projections, and the valuation and benchmarking of historical billed charges, 
                    we are uniquely positioned to help you at any point in your case, from initial demands, settlement negotiations and mediation, 
                    all the way through to litigation and even subrogation.
                    <br />
                    <br />
                    Having worked on approx. 7,500 cases, and been disclosed on over 3,000 cases over the last 10 years, AccuMed and its team of medical coding 
                    and valuation experts are well positioned to help you achieve the best possible outcome for your client. 
                    Whether you need a comprehensive medical cost projection for future treatment that can stand up in court; 
                    or are dealing with a medical provider charging inflated amounts; even tackling another billing expert that is unfairly repricing 
                    your client's medical bills, we can help.
                    <br />
                    <br />
                    What differentiates AccuMed from other companies in the space? AccuMed provides you with the ability to get a 'look' at the numbers 
                    before an expert is involved (and at a price point far below retaining an expert). Given the data and technology platform AccuMed has built, 
                    and the accompanying standardization of processes and protocols, AccuMed is able to provide reports based on standard expert methodologies 
                    before an expert is engaged. When cases progress to litigation, experts familiar with AccuMed's technology platform are available. 
                    Experts add an additional level of robust scrutiny to work done, re-issue reports accordingly and provide their own opinions of 
                    reasonableness based on the data sourced for your specific case.
                    <br />
                    <br />
                    While usual and customary are well defined mathematical terms, informed opinions regarding the reasonable value of medical treatment require 
                    a whole host of other skills; including valuation and benchmarking, an appreciation of market economics, an understanding of the medical 
                    industry at large and an awareness of 'peers' in the valuation space. AccuMed provides immediate visibility into underlying source data 
                    and analytics methodologies, and as such, protects their experts and indirectly, your case from any charge of 'hearsay.'
                    <br />
                    <br />
                    Let us help you tell a simple, clear and compelling story in a space that many people, including both legal professionals and the clients 
                    they represent find incredibly confusing. We can paint a picture together that achieves best outcomes for you and your client.
                </div>
            </div>
        </>
    )

}
