import React from "react";

export const ServerError = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-lg-2 d-none d-md-block"></div>
                <div className="col-lg-8 col-md-12 col-xs-12 mb-4 mb-md-0">
                    <div className="card card-product" style={{ padding: "60px" }}>
                        <img src="/img/500_internal_error.png" className="card-img-top mx-auto d-block" alt="500 Internal Server Error" style={{ maxWidth: "65%" }} />
                        <div className="card-body text-center mt-5">
                            <div className="d-grid col-xxl-7 col-lg-7 col-md-8 col-10 mx-auto">
                                <a href="/" className="btn access-large-button">
                                    BACK TO HOMEPAGE
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 d-none d-md-block"></div>
            </div>
            <div className="row">
                <div className="col-12"></div>
            </div>
        </>
    );
};
