import React, { useContext, useState, useEffect, useRef } from "react";
import { faFileDownload, faTimes, faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { Oval } from "react-loader-spinner";
import { DownloadReportsModal } from "../Modals/DownloadReportsModal";

export const ListReports = (props) => {
    const { requests, searchRequests, loadRequest, deleteRequest, downloadS3, session, setActivePage, requestLoading } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("myReports");
    }, []);

    const loginRef = useRef(null);

    // useEffect(() => {
    //     if (!session.userID && requests.length == 0) {
    //         loginRef.current.click();
    //     }
    // }, []);

    const beingGenerated = "being-generated";
    const reportReady = "report-ready";
    const mapStatus = {
        draft: { text: "Not Submitted", color: "#fac7d6", textColor: "#b7003d" },
        [beingGenerated]: { text: "In Progress", color: "#dcf8e6", textColor: "#20ad7e" }, // has been submitted to viv but waiting for it to be finished
        [reportReady]: { text: "Report Ready", color: "#d2f1fb", textColor: "#15b5e9" }, // status of 10
        complete: { text: "Report Ready", color: "#d2f1fb", textColor: "#15b5e9" }, // status of 100
        cancelled: { text: "Cancelled", color: "#ffffff", textColor: "#323232" },
    };

    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [selectedPage, setSelectedPage] = useState(null);
    const midSizeScreen = useMediaPredicate("(max-width: 992px)");
    const [loading, setLoading] = useState(false);
    const [delLoading, setDelLoading] = useState();
    const [requestID, setRequestID] = useState();
    const confirmButton = useRef(null);

    const { register, handleSubmit } = useForm();

    const mapProduct = {
        type_product_medical_cost_projection_standard: "Standard Medical Cost Projection",
        type_product_medical_cost_projection_pro: "Pro Medical Cost Projection",
        type_product_medical_cost_projection_expert: "Expert Medical Cost Projection",
        type_product_medical_bill_analysis_pro: "Pro Bill Analysis",
        type_product_medical_bill_analysis_expert: "Expert Bill Analysis",
        type_product_medical_bill_analysis_standard: "Standard Bill Analysis",
        type_product_bill_analysis_with_code_review_pro: "Pro Bill Analysis & Code Review",
        type_product_bill_analysis_with_code_review_expert: "Expert Bill Analysis & Code Review",
        // Legacy Support For Now
        bill_value_auto: "Snapshot",
        future_needs_standard: "Future Needs",
        future_needs_custom: "Future Needs Custom",
        bill_value: "BillValue",
        workers_compensation_standard: "Worker's Standard",
        workers_compensation_custom: "Worker's Custom",
    };

    var listRequests = props.select !== undefined ? requests.filter((request) => request.request_status.slug === props.select) : requests.filter((request) => request.request_status.slug !== "draft");

    listRequests = listRequests.filter((request) => {
        if (props.product !== undefined) {
            if (request.type_product !== undefined && request.type_product !== null) {
                if (props.product == "futures") {
                    return request.type_product.slug == "future_needs_standard" || request.type_product.slug == "future_needs_custom" || request.type_product.slug == "type_product_medical_cost_projection_standard" || request.type_product.slug == "type_product_medical_cost_projection_pro" || request.type_product.slug == "type_product_medical_cost_projection_expert";
                }
                if (props.product == "historical") {
                    return request.type_product.slug == "bill_analysis" || request.type_product.slug == "bill_value_auto" || request.type_product.slug == "bill_value" || request.type_product.slug == "type_product_medical_bill_analysis_standard" || request.type_product.slug == "type_product_medical_bill_analysis_pro" || request.type_product.slug == "type_product_medical_bill_analysis_expert" || request.type_product.slug == "type_product_bill_analysis_with_code_review_pro" || request.type_product.slug == "type_product_bill_analysis_with_code_review_expert";
                }
                if (props.product == "code-review") {
                    return request.type_product.slug == "type_product_bill_analysis_with_code_review_pro" || request.type_product.slug == "type_product_bill_analysis_with_code_review_expert";
                }
                return request.type_product.slug === props.product;
            }
        } else {
            return true;
        }

        return false;
    });

    // listRequests = listRequests.sort(function compareFn(a, b) {
    //     return b.request.updatedAt - a.request.updatedAt;
    // })

    useEffect(() => {
        if (listRequests.length > 0) {
            if (midSizeScreen) {
                setPageCount(Math.ceil(listRequests.length / 4));
            } else {
                setPageCount(Math.ceil(listRequests.length / 10));
            }
        } else {
            setPageCount(0);
        }
    }, [listRequests, midSizeScreen]);

    const handlePageClick = (data) => {
        setSelectedPage(data.selected);
        if (midSizeScreen) {
            setOffset(Math.ceil(data.selected * 4));
        } else {
            setOffset(Math.ceil(data.selected * 10));
        }
    };

    const history = useNavigate();

    const handleClick = async (id) => {
        setLoading(true);
        await loadRequest(id);
        history("/request/" + id);
        setLoading(false);
    };

    const handleEdit = async (id) => {
        setLoading(true);

        const request = await loadRequest(id);

        // MCP
        if (request?.type_product?.slug === "type_product_medical_cost_projection_standard" || request?.type_product?.slug === "future_needs_standard") {
            if (request.request.patientID && request.request_package.length !== 0) {
                history("/builder/standard_mcp");
            } else {
                history("/builder/package_search");
            }
        }

        if (request?.type_product?.slug === "type_product_medical_cost_projection_pro" || request?.type_product?.slug === "future_needs_custom") {
            history("/builder/pro_mcp");
        }

        if (request?.type_product?.slug === "type_product_medical_cost_projection_expert") {
            history("/builder/expert_mcp");
        }

        // auto bill analysis
        if (request?.type_product?.slug === "bill_value_auto") {
            if (request.request_attachment.length == 0) {
                history("/builder/snapshot_upload");
            } else {
                if (request.request_attachment[0].metadata != "1") {
                    history("/builder/snapshot_upload");
                } else {
                    history({
                        pathname: "/builder/snapshot",
                        state: {
                            fromBillUpload: true,
                        },
                    });
                }
            }
        }

        // BA
        if (request?.type_product?.slug === "type_product_medical_bill_analysis_standard") {
            history("/builder/standard_bill_analysis");
        }

        if (request?.type_product?.slug === "type_product_medical_bill_analysis_pro" || request?.type_product?.slug === "bill_value") {
            history("/builder/pro_bill_analysis");
        }

        if (request?.type_product?.slug === "type_product_medical_bill_analysis_expert") {
            history("/builder/expert_bill_analysis");
        }

        if (request?.type_product?.slug === "type_product_bill_analysis_with_code_review_pro") {
            history("/builder/pro_bill_analysis_code_review");
        }

        if (request?.type_product?.slug === "type_product_bill_analysis_with_code_review_expert") {
            history("/builder/expert_bill_analysis_code_review");
        }

        setLoading(false);
    };

    const onSubmit = (data) => {
        searchRequests(data.terms);
    };

    return loading ? (
        <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
    ) : (
        <div className="p-4 mx-4">
            <div className="row" style={{ font: "700 24pt Montserrat", marginTop: "30px" }}>
                My Reports
            </div>
            <div className="row my-3">
                <div className="col-12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={"input-group"} style={{ marginBottom: "8px" }}>
                            <input
                                type="text"
                                className={"package-search-form-input form-control "} // + (errors.terms ? "is-invalid" : "")}
                                placeholder="Search your reports"
                                {...register("terms")}
                            />
                            <button className="btn btn-outline-secondary package-search-button" type="submit">
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {requestLoading ? (
                <>
                    <Oval color="#00BFFF" height={100} width={100} className="text-center mt-4" />
                </>
            ) : (
                <>
                    {requests.length == 0 && !session.email ? (
                        <div className="row">
                            <div className="col-md-12">
                                <span style={{ cursor: "pointer", color: "#1db8ea" }} data-bs-toggle="modal" data-bs-target="#login">
                                    Login
                                </span>{" "}
                                to see your reports! Don't have an account? Create one
                                <span style={{ cursor: "pointer", color: "#1db8ea" }} data-bs-toggle="modal" data-bs-target="#signup">
                                    {" "}
                                    here
                                </span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {!midSizeScreen && (
                                <div className="row text-muted">
                                    <div className="col-xl-2 col-lg-3">
                                        <small>Status</small>
                                    </div>
                                    <div className="col-xl-2 col-lg-4">
                                        <small>Product</small>
                                    </div>
                                    <div className="col-xl-2 col-lg-4">
                                        <small>Patient Name</small>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 d-lg-none d-xl-block">
                                        <small>Report Title</small>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 d-lg-none d-xl-block">
                                        <small>Submitted</small>
                                    </div>
                                    <div className="col-xl-1 col-lg-1"></div>
                                </div>
                            )}
                            {listRequests.length !== 0 &&
                                listRequests.slice(offset, offset + (midSizeScreen ? 4 : 10)).map((request, index) => (
                                    <div
                                        key={index}
                                        className="row mb-2 py-1"
                                        style={{ backgroundColor: mapStatus[request.request_status.slug].color, cursor: "pointer" }}
                                        onClick={(e) => {
                                            if (request.request_status.slug === "draft") {
                                                handleEdit(request.request.requestID);
                                            } else {
                                                if (request.request_status.slug !== "cancelled") {
                                                    handleClick(request.request.requestID);
                                                }
                                            }
                                        }}
                                    >
                                        <div className="col-xl-2 col-lg-3 col-12" style={{ color: mapStatus[request.request_status.slug].textColor }}>
                                            {mapStatus[request.request_status.slug].text}
                                        </div>
                                        <div className="col-xl-2 col-lg-4 col-12" style={{ color: "#323232" }}>
                                            {request.type_product ? mapProduct[request.type_product.slug] : request.type_request.type}
                                        </div>
                                        {request.patient ? (
                                            <>
                                                <div className="col-xl-2 col-lg-4 col-12" style={{ fontWeight: 500, color: "#323232" }}>
                                                    {request.patient.firstName + " " + request.patient.lastName}
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-12 d-lg-none d-xl-block" style={{ color: "#323232" }}>
                                                    {request.request.customNote}
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-12 d-lg-none d-xl-block" style={{ color: "#323232" }}>
                                                    <span>{("0" + (new Date(request.request.updatedAt * 1000).getMonth() + 1)).slice(-2) + "/" + ("0" + new Date(request.request.updatedAt * 1000).getDate()).slice(-2) + "/" + ("0" + new Date(request.request.updatedAt * 1000).getFullYear()).slice(-2)}</span>
                                                    {midSizeScreen && (
                                                        <span style={{ float: "right" }}>
                                                            {request.request_status.slug === "report-ready" ? (
                                                                <span
                                                                    className="download-report"
                                                                    style={{ color: mapStatus[request.request_status.slug].textColor }}
                                                                    onClick={async (e) => {
                                                                        e.stopPropagation();
                                                                        // setDelLoading(request.request.requestID);
                                                                        // await downloadS3(request.request.requestID);
                                                                        // setDelLoading();
                                                                        if (request.document.length > 1) {
                                                                            setRequestID(request.request.requestID);
                                                                        } else {
                                                                            setDelLoading(request.request.requestID);
                                                                            await downloadS3(request.request.requestID);
                                                                            setDelLoading();
                                                                        }
                                                                    }}
                                                                    data-bs-toggle={request.document.length > 1 ? "modal" : ""}
                                                                    data-bs-target={request.document.length > 1 ? "#download-docs" : ""}
                                                                >
                                                                    {delLoading === request.request.requestID ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faFileDownload} />}
                                                                </span>
                                                            ) : request.request_status.slug === "draft" ? (
                                                                <span className="remove-report" style={{ color: mapStatus[request.request_status.slug].textColor }}>
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="col-xl-7 col-lg-7 col-12" style={{ fontWeight: 500, color: "#323232" }}>
                                                **Form not complete - missing information**
                                                {midSizeScreen ? (
                                                    <>
                                                        {request.request_status.slug === "draft" ? (
                                                            <span
                                                                className="remove-report"
                                                                style={{ float: "right", color: mapStatus[request.request_status.slug].textColor }}
                                                                onClick={async (e) => {
                                                                    if (request.request_status.slug === "draft") {
                                                                        e.stopPropagation();
                                                                        if (window.confirm("Are you sure you want to delete this Report?")) {
                                                                            setDelLoading(request.request.requestID);
                                                                            await deleteRequest(request.request.requestID);
                                                                            setDelLoading();
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {delLoading === request.request.requestID ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTimes} />}
                                                            </span>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                            </div>
                                        )}

                                        {!midSizeScreen && (
                                            <div className="col-md-1 d-none d-sm-block text-end">
                                                {request.request_status.slug === "report-ready" ? (
                                                    <span
                                                        className="download-report"
                                                        style={{ color: mapStatus[request.request_status.slug].textColor }}
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            // setDelLoading(request.request.requestID);
                                                            // await downloadS3(request.request.requestID);
                                                            // setDelLoading();
                                                            if (session.isConfirmed) {
                                                                if (request.document.length > 1) {
                                                                    setRequestID(request.request.requestID);
                                                                } else {
                                                                    setDelLoading(request.request.requestID);
                                                                    await downloadS3(request.request.requestID);
                                                                    setDelLoading();
                                                                }
                                                            }
                                                        }}
                                                        data-bs-toggle={!session.isConfirmed ? "modal" : request.document.length > 1 ? "modal" : ""}
                                                        data-bs-target={!session.isConfirmed ? "#confirmation_email" : request.document.length > 1 ? "#download-docs" : ""}
                                                    >
                                                        {delLoading === request.request.requestID ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faFileDownload} />}
                                                    </span>
                                                ) : request.request_status.slug === "draft" ? (
                                                    <span
                                                        className="remove-report"
                                                        style={{ color: mapStatus[request.request_status.slug].textColor }}
                                                        onClick={async (e) => {
                                                            if (request.request_status.slug === "draft") {
                                                                e.stopPropagation();
                                                                if (window.confirm("Are you sure you want to delete this Report?")) {
                                                                    setDelLoading(request.request.requestID);
                                                                    await deleteRequest(request.request.requestID);
                                                                    setDelLoading();
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {delLoading === request.request.requestID ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTimes} />}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            {listRequests.length > 10 && <ReactPaginate previousLabel={"previous"} nextLabel={"next"} breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={"pagination"} activeClassName={"active"} forcePage={selectedPage} />}
                            <DownloadReportsModal id={requestID} />
                        </>
                    )}
                </>
            )}
            <button data-bs-toggle="modal" data-bs-target="#login" style={{ display: "none" }} ref={loginRef}></button>
        </div>
    );
};
