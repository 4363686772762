import React, { useContext, useEffect, useState, useRef } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faUser, faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import { Oval } from "react-loader-spinner";
import { SwapToCustom } from "../Modals/SwapToCustom";

const libraries = ["places"];

export const FormReview = (props) => {
    const validationSchema = Yup.object().shape({
        // reportTitle: Yup.string().required().label("Report Title"),
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        dateOfLoss: Yup.string().required().label("Date of Loss"),
        sex: Yup.string().required().max(1, "Sex is a required field").label("Sex"),
        location: Yup.string().required().label("Treatment location"),
        userFirstName: Yup.string().required().label("First Name"),
        userLastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        // lawFirm: Yup.string().required().label("Law Firm"),
        contacts: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required().label("First Name"),
                lastName: Yup.string().required().label("Last Name"),
                email: Yup.string().required().email().label("Email"),
            })
        ),
    });

    const {
        register,
        setValue,
        reset,
        watch,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onBlur", resolver: yupResolver(validationSchema) });

    const watchFields = watch(["location", "latitude", "longitude"]);

    const { formPatientInfo, updateFormPatientInfo, sharedList, clearState, formPatientInfoSections, updateFormPatientInfoSections, updateFormContactInfo, deleteFromSharedList, updateRequest, session, currentRequest } = useContext(ContextGlobal);
    // console.log("pt info: ", formPatientInfo);
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const customSwitch = useRef(null);

    const onSubmit = async (e) => {
        // e.preventDefault();

        // true validate if the updated lat / long exists or if the current lat/long is valid
        const validate = (watchFields[1] === "" && watchFields[2] === "") || (formPatientInfo.longitude === "" && formPatientInfo.latitude === "") ? false : true;

        if (!validate) {
            setValue("location", "");
            setValidAddress(false);
        } else {
            if (currentRequest.request_package.length !== 0) {
                setLoading(true);
                await updateRequest(true, false);
                setLoading(false);
                clearState();
                // go to Thank you page if they are no longer a guest
                if (session.isConfirmed || session.isGuest == 0) {
                    history("/builder/thank_you");
                } else {
                    // pass down to form set password...?
                    if (session.isGuest != 0 && !session.requestUserID) {
                        history("/builder/thank_you?isConfirmed=false");
                    }

                    // both userIDs present, submitting for someone else, just say thank you
                    if (session.requestUserID != undefined && session.tempUserID) {
                        history("/builder/thank_you");
                    }
                }
            } else {
                customSwitch.current.click();
            }
        }
    };

    const [autocomplete, setAutoComplete] = useState(null);
    const [validAddress, setValidAddress] = useState(true);

    const onLoad = (auto) => {
        setAutoComplete(auto);
    };

    const onPlaceChanged = () => {
        let lat = "";
        let long = "";
        let city = "";
        let state = "";
        let zip = "";

        if (autocomplete !== null) {
            const place = autocomplete.getPlace();

            if (place?.address_components) {
                setValidAddress(true);
                const address = place.address_components[0].long_name + " " + place.address_components[1].short_name;
                setValue("address", address);

                place.address_components.forEach((data) => {
                    if (data.types[0] === "locality") {
                        city = data.long_name;
                        setValue("city", data.long_name);
                    } else if (data.types[0] === "administrative_area_level_1") {
                        state = data.short_name;
                        setValue("state", data.short_name);
                    } else if (data.types[0] === "postal_code") {
                        zip = data.long_name;
                        setValue("zip", data.long_name);
                    }
                });

                if (place.geometry !== undefined) {
                    lat = place.geometry.location.lat();
                    long = place.geometry.location.lng();
                    setValue("latitude", lat);
                    setValue("longitude", long);
                }
                const formatted_address = address + ", " + city + ", " + state + ", " + zip;
                updateFormPatientInfo({
                    location: formatted_address,
                    address: address,
                    city: city,
                    state: state,
                    zip: zip,
                    latitude: lat,
                    longitude: long,
                });
            } else {
                // user clicked enter instead of choosing an option
                // remove text from input box
                setValidAddress(false);
                reset({
                    location: "",
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                    latitude: "",
                    longitude: "",
                });
            }
        } else {
            // console.log("Autocomplete is not loaded yet!");
        }
    };

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    const [hoverTitle, setHoverTitle] = useState(false);
    const [hoverPatient, setHoverPatient] = useState(false);
    const [checkState, setCheckState] = useState(false);
    const [removeShare, setRemoveShared] = useState(false);

    const handleCheck = (e) => {
        if (e.target.checked) {
            // patch flag in API
            setCheckState(true);
        } else {
            setCheckState(false);
        }
    };

    useEffect(() => {
        setValue("lawFirm", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.lawFirm : session.lawFirm);
        setValue("userFirstName", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.userFirstName : session.firstName);
        setValue("userLastName", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.userLastName : session.lastName);
        setValue("phoneNumber", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.phoneNumber : session.phone);
        setValue("email", currentRequest.request?.userID && currentRequest.request?.userID !== session.userID ? formPatientInfo.email : session.email);
        // eslint-disable-next-line
    }, []);

    const [dol, setDoL] = useState(null);
    useEffect(() => {
        if (formPatientInfo.dateOfLoss !== undefined) {
            setDoL(new Date(formPatientInfo.dateOfLoss));
            setValue("dateOfLoss", formPatientInfo.dateOfLoss, {
                isDirty: true,
            });
        }
        // eslint-disable-next-line
    }, [setDoL, formPatientInfo]);

    return (
        <>
            <form className="review-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <label htmlFor="report-title" className="form-label text-muted">
                            <small>Report Title</small>
                        </label>
                        <span
                            className="fa-layers fa-fw fa-center-icon link-primary float-end"
                            style={{ cursor: "pointer", marginRight: "0px" }}
                            onClick={(e) =>
                                !errors.reportTitle
                                    ? updateFormPatientInfoSections({
                                          reportTitle: !formPatientInfoSections.reportTitle,
                                      })
                                    : ""
                            }
                            onMouseEnter={(e) => setHoverTitle(true)}
                            onMouseLeave={(e) => setHoverTitle(false)}
                        >
                            <FontAwesomeIcon style={{ display: !hoverTitle ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                            <FontAwesomeIcon icon={formPatientInfoSections.reportTitle ? faCheck : faPen} transform="shrink-8" className="share-remove" color={hoverTitle ? "white" : "#1db8ea"} />
                        </span>
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-md-12 col-12">
                        <input type="text" className={"form-control access-input-style review-page" + (errors.reportTitle ? "is-invalid" : "")} id="report-title" defaultValue={formPatientInfo.reportTitle ?? ""} aria-describedby="report-title-help" {...register("reportTitle")} onChange={(e) => updateFormPatientInfo({ reportTitle: e.target.value })} readOnly={!formPatientInfoSections.reportTitle} style={{ paddingLeft: !formPatientInfoSections.reportTitle ? "" : "12px" }} placeholder="e.g. Based on treatment recommendations from Dr. Rosen on Jan. 25, 2021" />
                    </div>
                </div>
                <div className="invalid-feedback">{errors.reportTitle?.message}</div>
                <div className="row g-0">
                    <div className="col-12 mt-5 mb-3">
                        <h3 style={{ display: "inline-block" }}>Patient info</h3>
                        <span
                            className="fa-layers fa-fw fa-center-icon link-primary float-end"
                            style={{ cursor: "pointer", marginRight: "0px" }}
                            onClick={(e) =>
                                !errors.firstName && !errors.lastName && !errors.dateOfLoss && !errors.sex && !errors.location
                                    ? updateFormPatientInfoSections({
                                          patientInfo: !formPatientInfoSections.patientInfo,
                                      })
                                    : ""
                            }
                            onMouseEnter={(e) => setHoverPatient(true)}
                            onMouseLeave={(e) => setHoverPatient(false)}
                        >
                            <FontAwesomeIcon style={{ display: !hoverPatient ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                            <FontAwesomeIcon icon={formPatientInfoSections.patientInfo ? faCheck : faPen} transform="shrink-8" className="share-remove" color={hoverPatient ? "white" : "#1db8ea"} />
                        </span>
                    </div>
                </div>
                <div className="text-end"></div>
                <div className="row g-0">
                    <div className="col-xxl-3 col-xl-6 col-12" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.patientInfo ? "0px" : "12px" }}>
                        <label htmlFor="first-name" className="form-label mb-0 text-muted">
                            <small>First Name</small>
                        </label>
                        <input type="text" className={"form-control access-input-style review-page " + (errors.firstName ? "is-invalid" : "")} id="first-name" defaultValue={formPatientInfo.firstName ?? ""} {...register("firstName")} onChange={(e) => updateFormPatientInfo({ firstName: e.target.value })} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }} />
                        <div className="invalid-feedback">{errors.firstName?.message}</div>
                    </div>
                    <div className="col-xxl-3 col-xl-6 col-12" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.patientInfo ? "0px" : "12px" }}>
                        <label htmlFor="last-name" className="form-label mb-0 text-muted">
                            <small>Last Name</small>
                        </label>
                        <input type="text" className={"form-control access-input-style review-page " + (errors.lastName ? "is-invalid" : "")} id="last-name" defaultValue={formPatientInfo.lastName ?? ""} {...register("lastName")} onChange={(e) => updateFormPatientInfo({ lastName: e.target.value })} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }} />
                        <div className="invalid-feedback">{errors.lastName?.message}</div>
                    </div>
                    <div className="col-xxl-1 d-none d-xxl-block"></div>
                    <div className="col-xxl-3 col-xl-6 col-9" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.patientInfo ? "0px" : "12px" }}>
                        <label htmlFor="date-of-loss" className="form-label mb-0 text-muted">
                            <small>Date of Loss</small>
                        </label>
                        <Controller
                            control={control}
                            name="dateOfLoss"
                            render={({ field }) => (
                                <DatePicker
                                    name={field.name}
                                    className={"form-control access-input-style " + (errors.dateOfLoss ? "is-invalid" : "")}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Choose date"
                                    onChange={(e) => {
                                        setValue("dateOfLoss", e.toLocaleString().split(",")[0], {
                                            shouldDirty: true,
                                        });
                                        setDoL(e);
                                        updateFormPatientInfo({ dateOfLoss: e.toLocaleString().split(",")[0] });
                                    }}
                                    selected={dol}
                                    maxDate={new Date()}
                                    readOnly={!formPatientInfoSections.patientInfo}
                                    style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }}
                                />
                            )}
                        />
                        {/* <input
                        type="date"
                        className={"form-control access-input-style review-page " + (errors.dateOfLoss ? "is-invalid" : "")}
                        id="date-of-loss"
                        defaultValue={formPatientInfo.dateOfLoss ?? ""}
                        {...register("dateOfLoss")}
                        onChange={(e) => updateFormPatientInfo({ dateOfLoss: e.target.value })}
                        readOnly={!formPatientInfoSections.patientInfo}
                        style={{ paddingLeft: !formPatientInfoSections.patientInfo ?  "" : "12px" }}
                    /> */}
                        <div className="invalid-feedback">{errors.dateOfLoss?.message}</div>
                    </div>
                    <div className="col-xxl-2 col-xl-6 col-3 ">
                        <label htmlFor="sex" className="form-label mb-0 text-muted">
                            <small>Sex</small>
                        </label>
                        <select
                            className={"form-select access-input-style review-page " + (errors.sex ? "is-invalid" : "")}
                            id="sex"
                            {...register("sex")}
                            onChange={(e) => updateFormPatientInfo({ sex: e.target.value })}
                            defaultValue={formPatientInfo.sex ?? "Select"}
                            readOnly={!formPatientInfoSections.patientInfo}
                            style={{
                                paddingLeft: !formPatientInfoSections.patientInfo ? "0px" : "12px",
                                background: !formPatientInfoSections.patientInfo ? "none" : "",
                            }}
                        >
                            <option disabled>Select</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                        </select>
                        <div className="invalid-feedback">{errors.sex?.message}</div>
                    </div>
                </div>
                <div className="d-flex row mt-4">
                    <label htmlFor="treatment-location" className="form-label mb-0 text-muted">
                        <small>Treatment Location</small>
                    </label>
                    <LoadScript googleMapsApiKey="AIzaSyDL3z1KGSpAPVawCDPTbRTKSEAjezZHd-E" libraries={libraries}>
                        <>
                            <Autocomplete
                                onLoad={onLoad}
                                onPlaceChanged={onPlaceChanged}
                                readOnly={!formPatientInfoSections.patientInfo}
                                // fields=""
                            >
                                <>
                                    <input type="text" className={"form-control access-input-style review-page " + (errors.location ? "is-invalid" : "")} id="treatment-location" defaultValue={formPatientInfo.location ?? ""} {...register("location")} onChange={(e) => updateFormPatientInfo({ location: e.target.value })} readOnly={!formPatientInfoSections.patientInfo} style={{ paddingLeft: !formPatientInfoSections.patientInfo ? "" : "12px" }} />
                                    <div className="invalid-feedback">{errors.location?.message}</div>
                                </>
                            </Autocomplete>
                        </>
                    </LoadScript>
                    {!validAddress && (
                        <div className="row">
                            <div className="col-12">
                                <div className="" id="" style={{ color: "#dc3545" }}>
                                    Please choose location from drop down
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* hidden fields */}
                <div>
                    <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.address ?? ""} {...register("address")} />
                    <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.city ?? ""} {...register("city")} />
                    <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.state ?? ""} {...register("state")} />
                    <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.zip ?? ""} {...register("zip")} />
                    <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.latitude ?? ""} {...register("latitude")} />
                    <input type="text" style={{ display: "none" }} defaultValue={formPatientInfo.longitude ?? ""} {...register("longitude")} />
                </div>
                <div className="row">
                    <div className="col-12 mt-5 mb-3">
                        <h3 style={{ display: "inline-block" }}>Your info</h3>
                        {/* <span
                        className="fa-layers fa-fw fa-center-icon link-primary float-end"
                        style={{ cursor: "pointer", marginRight: "0px" }}
                        onClick={(e) =>
                            !errors.userFirstName && !errors.userLastName && !errors.email
                                ? updateFormPatientInfoSections({
                                      yourInfo: !formPatientInfoSections.yourInfo,
                                  })
                                : ""
                        }
                        onMouseEnter={(e) => setHover(true)}
                        onMouseLeave={(e) => setHover(false)}
                    >
                        <FontAwesomeIcon style={{ display: !hover ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                        <FontAwesomeIcon
                            icon={formPatientInfoSections.yourInfo ? faCheck : faPen}
                            transform="shrink-8"
                            className="share-remove"
                            color={hover ? "white" : "#1db8ea"}
                        />
                    </span> */}
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-xxl-1 d-none d-xxl-block"></div>
                    <div style={{ paddingLeft: "0px" }} className="col-xxl-3 d-none d-xxl-block text-muted">
                        First Name
                    </div>
                    <div style={{ paddingLeft: "0px" }} className="col-xxl-3 d-none d-xxl-block text-muted">
                        Last Name
                    </div>
                    <div style={{ paddingLeft: "0px" }} className="col-xxl-5 d-none d-xxl-block text-muted">
                        Email
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-xxl-1 d-none d-xxl-block">
                        <h3>
                            <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                <FontAwesomeIcon icon={faCircle} color="#B4EFC9" />
                                <FontAwesomeIcon icon={faUser} transform="shrink-6" color="#71E099" />
                            </span>
                        </h3>
                    </div>
                    <div className="col-xxl-3 col-xl-6  col-12" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.yourInfo ? "0px" : "12px" }}>
                        <label htmlFor="user-first-name" className="form-label mb-0 text-muted d-xxl-none">
                            <small>First Name</small>
                        </label>
                        <input type="text" className={"form-control access-input-style review-page " + (errors.userFirstName ? "is-invalid" : "")} id="user-first-name" defaultValue={formPatientInfo.userFirstName ?? ""} {...register("userFirstName")} onChange={(e) => updateFormPatientInfo({ userFirstName: e.target.value })} readOnly={!formPatientInfoSections.yourInfo} style={{ paddingLeft: !formPatientInfoSections.yourInfo ? "" : "12px" }} />
                        <div className="invalid-feedback">{errors.userFirstName?.message}</div>
                    </div>
                    <div className="col-xxl-3 col-xl-6  col-12" style={{ paddingLeft: "0px", paddingRight: !formPatientInfoSections.yourInfo ? "0px" : "12px" }}>
                        <label htmlFor="user-last-name" className="form-label mb-0 text-muted d-xxl-none">
                            <small>Last Name</small>
                        </label>
                        <input type="text" className={"form-control access-input-style review-page " + (errors.userLastName ? "is-invalid" : "")} id="user-last-name" defaultValue={formPatientInfo.userLastName ?? ""} {...register("userLastName")} onChange={(e) => updateFormPatientInfo({ userLastName: e.target.value })} readOnly={!formPatientInfoSections.yourInfo} style={{ paddingLeft: !formPatientInfoSections.yourInfo ? "" : "12px" }} />
                        <div className="invalid-feedback">{errors.userLastName?.message}</div>
                    </div>
                    <div className="col-xxl-5 col-xl-12 col-12" style={{ paddingLeft: "0px" }}>
                        <label htmlFor="email" className="form-label mb-0 text-muted d-xxl-none">
                            <small>Email</small>
                        </label>
                        <input type="email" className={"form-control access-input-style review-page " + (errors.email ? "is-invalid" : "")} id="email" defaultValue={formPatientInfo.email ?? ""} {...register("email")} onChange={(e) => updateFormPatientInfo({ email: e.target.value })} readOnly={!formPatientInfoSections.yourInfo} style={{ paddingLeft: !formPatientInfoSections.yourInfo ? "" : "12px" }} />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                </div>
                {sharedList.length > 0 ? (
                    <>
                        <div className="row">
                            <div className="col-12 mt-5 mb-3">
                                <h3 style={{ display: "inline-block" }}>Report will be shared with</h3>
                            </div>
                        </div>
                        <div className="row g-0 mb-0">
                            <div className="col-xxl-1 d-none d-xxl-block"></div>
                            <div className="col-xxl-3 d-none d-xxl-block text-muted" style={{ paddingLeft: "0px" }}>
                                First Name
                            </div>
                            <div className="col-xxl-3 d-none d-xxl-block text-muted" style={{ paddingLeft: "0px" }}>
                                Last Name
                            </div>
                            <div className="col-xxl-5 d-none d-xxl-block text-muted" style={{ paddingLeft: "0px" }}>
                                Email
                            </div>
                        </div>
                    </>
                ) : null}
                {sharedList.map((contact, index) => (
                    <div className="row mb-2" key={index}>
                        <div className="col-xxl-1 d-none d-xxl-block" style={{ paddingRight: "16px" }}>
                            <h3>
                                <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                    <FontAwesomeIcon icon={faCircle} color={sharedIcons[index % sharedIcons.length].background} />
                                    <FontAwesomeIcon icon={faUser} transform="shrink-6" color={sharedIcons[index % sharedIcons.length].icon} />
                                </span>
                            </h3>
                        </div>
                        <div
                            className="col-xxl-3 col-xl-6 col-12"
                            style={{
                                paddingRight: !formPatientInfoSections.sharedList ? "0px" : "",
                            }}
                        >
                            <label htmlFor={"shared-first-name-" + index} className="form-label mb-0 text-muted d-xxl-none">
                                <small>First Name</small>
                            </label>
                            <input type="text" className={"form-control access-input-style review-page " + (errors.contacts?.[index]?.firstName ? "is-invalid" : "")} id={"shared-first-name-" + index} value={contact.firstName ?? ""} {...register("contacts[" + index + "].firstName")} readOnly={!formPatientInfoSections.sharedList} onChange={(e) => updateFormContactInfo({ id: contact.id, firstName: e.target.value })} style={{ paddingLeft: !formPatientInfoSections.sharedList ? "" : "12px" }} />
                            <div className="invalid-feedback">{errors.contacts?.[index]?.firstName?.message}</div>
                        </div>
                        <div
                            className="col-xxl-3 col-xl-6 col-12"
                            style={{
                                paddingLeft: "0px",
                                paddingRight: !formPatientInfoSections.sharedList ? "0px" : "12px",
                            }}
                        >
                            <label htmlFor={"shared-last-name-" + index} className="form-label mb-0 text-muted d-xxl-none">
                                <small>Last Name</small>
                            </label>
                            <input type="text" className={"form-control access-input-style review-page " + (errors.contacts?.[index]?.lastName ? "is-invalid" : "")} id={"shared-last-name-" + index} value={contact.lastName ?? ""} {...register("contacts[" + index + "].lastName")} readOnly={!formPatientInfoSections.sharedList} onChange={(e) => updateFormContactInfo({ id: contact.id, lastName: e.target.value })} style={{ paddingLeft: !formPatientInfoSections.sharedList ? "" : "12px" }} />
                            <div className="invalid-feedback">{errors.contacts?.[index]?.lastName?.message}</div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-11 col-11"
                            style={{
                                paddingLeft: "0px",
                                paddingRight: !formPatientInfoSections.sharedList ? "0px" : "12px",
                            }}
                        >
                            <label htmlFor={"shared-email-" + index} className="form-label mb-0 text-muted d-xxl-none">
                                <small>Email</small>
                            </label>
                            <input type="email" className={"form-control access-input-style review-page " + (errors.contacts?.[index]?.email ? "is-invalid" : "")} id={"shared-email-" + index} value={contact.email ?? ""} {...register("contacts[" + index + "].email")} readOnly={!formPatientInfoSections.sharedList} onChange={(e) => updateFormContactInfo({ id: contact.id, email: e.target.value })} style={{ paddingLeft: !formPatientInfoSections.sharedList ? "" : "12px" }} />
                            <div className="invalid-feedback">{errors.contacts?.[index]?.email?.message}</div>
                        </div>
                        <div className="col-xx-1 col-xl-1 col-1" style={{ paddingLeft: "0px" }}>
                            <span className="fa-layers fa-fw fa-center-icon float-end" style={{ cursor: "pointer", marginRight: "0px" }} onClick={(e) => deleteFromSharedList(contact.requestSubscriptionID)} onMouseEnter={(e) => setRemoveShared(index)} onMouseLeave={(e) => setRemoveShared()}>
                                <FontAwesomeIcon style={{ display: removeShare !== index ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                                <FontAwesomeIcon icon={faTimes} transform="shrink-6" className="share-remove" color={removeShare === index ? "white" : "#1db8ea"} />
                            </span>
                        </div>
                    </div>
                ))}
                <div className="row mt-5">
                    <div className="col-md-12">
                        <p>I understand that this report cannot be used in a trial and does not meet Expert Disclosure requirements.</p>
                        <p>An AccuMed Expert was not consulted and has not reviewed or opined to this report. It is solely for use in the furtherance of settlement negotiations.</p>
                        <p>If Expert services are required, I will contact AccuMed at submit@accumedintel.com or 844-307-4487</p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">Due Date: {formPatientInfo.dueDate}</div>
                </div>
                <div className="d-flex row g-0">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="agreeCheck"
                            checked={checkState}
                            onChange={(e) => {
                                handleCheck(e);
                            }}
                        />
                        <label className="form-check-label" htmlFor="agreeCheck" style={{ marginTop: "3px" }}>
                            I understand
                        </label>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-md-4">
                        <label htmlFor="promo-code" className="form-label mb-0 text-muted" style={{ display: "none" }}>
                            <small>Promo Code</small>
                        </label>
                        <input type="text" className={"form-control access-input-style review-page"} id="promo-code" defaultValue={formPatientInfo.promoCode ?? ""} {...register("promoCode")} onChange={(e) => updateFormPatientInfo({ promoCode: e.target.value })} style={{ paddingLeft: "12px", display: "none" }} placeholder="Promo Code" />
                    </div>
                </div>

                <button className="btn access-primary-button mt-4" disabled={!checkState} onClick={handleSubmit}>
                    {loading ? <Oval color="white" height={20} width={20} /> : session.email || session.requestUserID ? "SUBMIT STANDARD REPORT" : "SUBMIT AND CREATE ACCOUNT"}
                </button>
            </form>
            <button ref={customSwitch} data-bs-toggle="modal" data-bs-target="#custom-switch" style={{ display: "none" }}></button>
            <SwapToCustom />
        </>
    );
};
