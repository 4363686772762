import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext, Controller } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";

export const DueDatePicker = (props) => {
    const { updateFormPatientInfo } = useContext(ContextGlobal);
    const methods = useFormContext();

    const [startDate, setStartDate] = useState(null);
    const [highlightDates, setHighlightDates] = useState();

    const [min, setMin] = useState(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)); // new min
    useEffect(() => {
        const day = new Date().getDay();
        if (day === 5) {
            setMin(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)); // if friday choose mon
        }
        if (day === 6) {
            setMin(new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)); // if saturday choose mon
        }
    }, [setMin]);

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    // Office Holidays (day's AccuMed is closed)
    // New Year’s Day Sun, Jan 1, 2023
    // Memorial Day May 29, 2023
    // Independence Day July 4, 2023
    // Labor Day September 4, 2023
    // Thanksgiving Day Thu, Nov 23, 2023
    // Day Following Thanksgiving Fid, Nov 24, 2023
    // Christmas Eve December 24, 2023
    // Christmas Day December 25, 2023
    // Office Closed After Christmas December 26, 2023
    // New Year's Day January 1, 2023

    const stringOfficeHolidays = ["Mon Sep 02 2024, Thu Nov 28 2024, Fri Nov 29 2024, Tue Dec 24 2024, Wed Dec 25 2024"];

    const officeHolidays = [Date.parse("Mon Sep 02 2024"), Date.parse("Thu Nov 28 2024"), Date.parse("Fri Nov 29 2024"), Date.parse("Tue Dec 24 2024"), Date.parse("Wed Dec 25 2024"), Date.parse("Wed Jan 01 2025")];

    const handleDate = (type, e) => {
        const rushDates = Object.values(highlightDates[0]);

        const rushChosen = rushDates[0].some((date) => {
            if (e.toLocaleString().split(",")[0] === date.toLocaleString().split(",")[0]) {
                return true;
            } else {
                return false;
            }
        });

        props.setRush(rushChosen); // to display the message
        // we need to then setValue here to push onto Table.
        methods.setValue("isRush", rushChosen, {
            shouldDirty: true,
        });
        updateFormPatientInfo({ isRush: rushChosen });

        methods.setValue("dueDate", e.toLocaleString().split(",")[0], {
            shouldDirty: true,
        });
        setStartDate(e);
    };

    /**
     *  S MCP        -> 1-2 business days
     *  S BA         -> 5   business days
     *  P MCP        -> 5-7 business days
     *  P BA         -> 14  business days
     *  E MCP / E BA / P BA + CR
     *               -> 21  business days
     *  E BA + CR / E MCP + E BA
     *               -> 28  business days
     */

    const [businessDays, setBusinessDays] = useState(null);
    const [due, setDue] = useState(null);
    const [rushArray, setRushArray] = useState([]);

    useEffect(() => {
        switch (props.product) {
            case "type_product_medical_cost_projection_standard":
                setBusinessDays(1);
                setDue(2);
                break;
            case "type_product_medical_cost_projection_pro":
                setBusinessDays(6);
                setDue(7);
                break;
            case "type_product_medical_cost_projection_expert":
                setBusinessDays(20);
                setDue(21);
                break;
            case "type_product_medical_bill_analysis_standard":
                setBusinessDays(4);
                setDue(5);
                break;
            case "type_product_medical_bill_analysis_pro":
                setBusinessDays(13);
                setDue(14);
                break;
            case "type_product_medical_bill_analysis_expert":
                setBusinessDays(20);
                setDue(21);
                break;
            case "type_product_bill_analysis_with_code_review_pro":
                setBusinessDays(20);
                setDue(21);
                break;
            case "type_product_bill_analysis_with_code_review_expert":
                setBusinessDays(27);
                setDue(28);
                break;
            default:
                break;
        }
    }, [props.product]);

    useEffect(() => {
        const highlights = [];
        var i = 0;
        var weekay = 1;

        // set highlight array for rush days, the total rush days should be {businessDays} - 1
        while (i < businessDays) {
            const day = new Date(Date.now() + weekay * 24 * 60 * 60 * 1000);

            if (day.toString().indexOf("Sat") == -1 && day.toString().indexOf("Sun") == -1) {
                highlights.push(day);
            }
            weekay++;
            i++;
        }
        if (highlights.length > 2) {
            //ignore the standard products
            setRushArray([...rushArray, ...highlights]);
        }
    }, [businessDays]);

    useEffect(() => {
        const day = new Date().getDay();

        var dueDay = due;
        var dueDateString = new Date(Date.now() + due * 24 * 60 * 60 * 1000);

        var holiday = false;
        // if the due day lands on a holiday, add 1 day...
        stringOfficeHolidays.forEach((holiday) => {
            if (dueDateString.toString().indexOf(holiday) != -1) {
                holiday = true;
                // if the holiday is on a Friday, add 3 days...
                if (dueDateString.toString().indexOf("Fri") != -1) {
                    dueDay = dueDay + 3;
                } else if (dueDateString.toString().indexOf("Sat") != -1) {
                    dueDay = dueDay + 2;
                } else {
                    dueDay = dueDay + 1;
                }
            }
        });

        // holiday logic already adds the required time to the dueDay variable
        // so no reason to do it again...
        if (!holiday) {
            // if the due day lands on Sat, add 2 days...
            if (dueDateString.toString().indexOf("Sat") != -1) {
                dueDay = dueDay + 2;
            }
            // if the due day lands on Sun, add 1 day...
            if (dueDateString.toString().indexOf("Sun") != -1) {
                // if the product is SMCP add an extra day...
                if (props.product == "type_product_medical_cost_projection_standard") {
                    dueDay = dueDay + 2;
                } else {
                    dueDay = dueDay + 1;
                }
            }
        }

        if (day === 0) {
            // sunday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        } else if (day === 1) {
            // monday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + due * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + due * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + due * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        } else if (day === 2) {
            // tuesday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        } else if (day === 3) {
            // wednesday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        } else if (day === 4) {
            //thursday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        } else if (day === 5) {
            // friday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        } else {
            // saturday
            setHighlightDates([
                {
                    "react-datepicker__day--highlighted-custom": rushArray,
                },
            ]);
            setStartDate(new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000));
            methods.setValue("dueDate", new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0], {
                shouldDirty: true,
            });
            updateFormPatientInfo({
                dueDate: new Date(Date.now() + dueDay * 24 * 60 * 60 * 1000).toLocaleString().split(",")[0],
            });
        }
        // eslint-disable-next-line
    }, [rushArray, due, setHighlightDates, setStartDate]);

    return (
        <>
            <div className="row mt-2">
                <div className="col-md-4 col-12">
                    <label htmlFor="due-date" className="form-label access-input-label-style">
                        Set due date
                    </label>
                    <Controller
                        control={methods.control}
                        name="dueDate"
                        render={({ field }) => (
                            <DatePicker
                                name={field.name}
                                className={"form-control access-input-style " + (methods.formState.errors.dueDate ? "is-invalid" : "")}
                                dateFormat="MM/dd/yyyy"
                                placeholderText={"Choose date"}
                                onChange={(e) => {
                                    handleDate("due", e);
                                    updateFormPatientInfo({
                                        dueDate: e.toLocaleString().split(",")[0],
                                    });
                                    methods.trigger("dueDate");
                                }}
                                selected={startDate}
                                minDate={min}
                                highlightDates={highlightDates}
                                filterDate={isWeekday}
                                excludeDates={officeHolidays}
                                // onFocus={e => e.target.blur()} // to disable user input
                            />
                        )}
                    />
                    <div className="invalid-feedback">{methods.formState.errors.dueDate?.message}</div>
                </div>
            </div>
            {props.rush && (
                <div className="row mt-2">
                    <div className="col-md-12">
                        <p className="m-0" style={{ color: "#bb0046" }}>
                            A rush fee of $1000 may apply. Please{" "}
                            <a href="/contact-us" rel="noopener noreferrer" target="_blank">
                                contact us
                            </a>{" "}
                            after submission to ensure we can meet your deadline
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};
