import React from "react";
import { Link } from "react-router-dom";

export const ProductComparison = (props) => {

    const phoneSize = props.phoneSize;
    const midSizeScreen = props.midSizeScreen;
    const laptopSize = props.laptopSize;

    return (
        <>
            <div id="comparison"></div>
            <div className="row g-0 mb-3 mt-5 mx-2">
                <div className="col-md-12 mt-5 faq-headings" style={{ color: "#000046" }}>
                    Product Comparison
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table excel-table-striped">
                            <thead>
                                <tr style={{color: "#1db8ea", font: "700 14pt Montserrat"}}>
                                    {phoneSize ? (null) : (
                                        <th style={{ width: "40%" }}></th>
                                    )}
                                    <th style={{ textAlign: "center", width: "20%" }}>STANDARD</th>
                                    <th style={{ textAlign: "center", width: "20%" }}>PRO</th>
                                    <th style={{ textAlign: "center", width: "20%" }}>EXPERT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Effective for Mediation & Settletment:
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>Effective for Mediation & Settletment</td>
                                    )}
                                    {/* <td>
                                        <span className="fa-layers fa-fw fa-center-icon text-center">
                                            <FontAwesomeIcon icon={faCircle} color="#1db8ea" />
                                            <FontAwesomeIcon icon={faCheck} transform="shrink-8" className="share-remove"/>
                                        </span>
                                    </td> */}
                                    <td style={{ textAlign: "center" }} >
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                    <td style={{ textAlign: "center" }} >
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                    <td style={{ textAlign: "center" }} >
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                </tr>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Provides Reasonable Value of Medical Charges:
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>Provides Reasonable Value of Medical Charges</td>
                                    )}
                                    <td style={{ textAlign: "center" }} >
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                    <td style={{ textAlign: "center" }} >
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                    <td style={{ textAlign: "center" }} >
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                </tr>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Self-Guided, Fast, Inexpensive:
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>Self-Guided, Fast, Inexpensive</td>
                                    )}
                                    <td style={{ textAlign: "center" }}>
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                </tr>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Built by AccuMed Professionals:
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>Built by AccuMed Professionals</td>
                                    )}
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                </tr>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Built by Expert Witness:
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>Built by Expert Witness</td>
                                    )}
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                </tr>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Ability to Retain Expert Witness:
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>Ability to Retain Expert Witness</td>
                                    )}
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                </tr>
                                {phoneSize ? (
                                    <tr>
                                        <td colSpan={3} style={{ font: "600 italic 10pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "" }}>
                                            Expert Opinion, Expert Report and Rule26(a)(2)
                                            <br/>
                                            Documents Available Upon Request
                                        </td>
                                    </tr>
                                ) : (null)}
                                <tr>
                                    {phoneSize ? (null) : (
                                        <td style={{ font: "600 italic 11pt Montserrat", fontStyle: "italic", verticalAlign: "middle", paddingLeft: "5%" }}>
                                            Expert Opinion, Expert Report and Rule26(a)(2)
                                            <br/>
                                            Documents Available Upon Request
                                        </td>
                                    )}
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {phoneSize ? (<img src="/img/checkmark_gs.png" style={{opacity: "0.7", height: phoneSize ? "4vh" :  "5vh"}} />) : (null)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <img src="/img/checkmark.png" style={{height: phoneSize ? "4vh" :  "5vh"}} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
