import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { FormPassword } from "./FormPassword";

export const FormVerifyEmail = (props) => {
    const { verifyEmail } = useContext(ContextGlobal);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [verifiedEmail, setVerifiedEmail] = useState(null);
    const [guest, setGuest] = useState(false);

    useEffect(() => {
        if (props.closed) {
            setVerifiedEmail(false);
            props.setClosed(false);
            // setEmail(null);
            reset();
        }
    }, [props.closed]);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({ mode: "onSubmit" });

    const onSubmitEmail = async (data) => {
        setLoading(true);
        const res = await verifyEmail(data);
        setLoading(false);

        if (res?.success != false) {
            if (res.isGuest) {
                setGuest(true);
            } else {
                setGuest(false);
            }
            reset();
            setVerifiedEmail(true);
            setEmail(data);
        } else {
            setError("email", { message: "Couldn't find your Access account" });
        }
    };

    return (
        <>
            {!verifiedEmail ? (
                <form onSubmit={handleSubmit(onSubmitEmail)}>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="login-email" className="form-label access-input-label-style">
                                Email
                            </label>
                            <input type="email" placeholder="Email" className={"form-control access-input-style login " + (errors.email ? "is-invalid" : "")} id="login-email" {...register("email", { required: "Please enter your email." })} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 mt-1" style={{ fontSize: "12px" }}>
                            Don't remember your password?&nbsp;
                            <Link to="/resetpassword" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <span style={{ color: "rgb(29, 184, 234)", cursor: "pointer" }}>Click here.</span>
                            </Link>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12">
                            <button id="closelogin" type="button" data-bs-dismiss="modal" style={{ display: "none" }}>
                                Close
                            </button>
                            <button className="btn access-primary-button mt-4" type="submit" style={{ width: "100%" }}>
                                {loading ? <Oval color="white" height={20} width={20} /> : "NEXT"}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-3" style={{ fontWeight: "600", fontSize: "14px" }}>
                            Don't have an account?&nbsp;
                            <span style={{ color: "rgb(29, 184, 234)", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#signup" data-bs-dismiss="modal">
                                Create one!
                            </span>
                        </div>
                    </div>
                </form>
            ) : (
                <FormPassword email={email} setEmail={setEmail} setVerifiedEmail={setVerifiedEmail} closed={props.closed} setClosed={props.setClosed} guest={guest} setGuest={setGuest} />
            )}
        </>
    );
};
