import React, { useContext, useEffect, useState } from "react";

import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ContextGlobal } from "../../contexts/ContextGlobal.js";
import { FormSettingsMyInfo } from "../Forms/FormSettingsMyInformation.js";
import { FormSettingContacts } from "../Forms/FormSettingsContacts.js";
import { Eula } from "./Eula.js";

export const Settings = (props) => {
    const { setActivePage } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("settings");
    }, []);

    const [select, setSelect] = useState("my_information");
    // console.log("select: " + select);
    return (
        <div className="row g-0">
            <div className="col-md-1"></div>
            <div className="col-md-10">
                <div className="card content-card mb-5 transparent" style={{ padding: "0px" }}>
                    <div className="row g-0">
                        <div className="col-xl-3 col-12">
                            <div className="menu">
                                <div className="card-body" style={{ fontSize: "1.4em " }}>
                                    <div className="row mt-2 mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "my_information" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "my_information" ? "600" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("my_information");
                                                }}
                                            >
                                                My Information
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "contacts" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "contacts" ? "600" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("contacts");
                                                }}
                                            >
                                                Contacts
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-12">
                                            <span
                                                className="left-heading"
                                                style={{
                                                    cursor: "pointer",
                                                    textDecorationLine: select === "eula" ? "underline" : "",
                                                    textDecorationThickness: "2px",
                                                    textUnderlineOffset: "0.25em",
                                                    fontWeight: select === "eula" ? "600" : "",
                                                }}
                                                onClick={() => {
                                                    setSelect("eula");
                                                }}
                                            >
                                                User Agreement
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SwitchTransition>
                            <CSSTransition addEndListener={(node, done) => node.addEventListener("transitionend", done, false)} classNames="fade-faq" key={select}>
                                <div className="col-xl-9 col-12 column-right-hand-side">
                                    <div className="card-body">
                                        {select === "my_information" ? <FormSettingsMyInfo /> : null}
                                        {select === "contacts" ? <FormSettingContacts /> : null}
                                        {select === "eula" ? <Eula /> : null}
                                    </div>
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                </div>
            </div>
            <div className="col-md-1"></div>
        </div>
    );
};
