import React, { useContext, useEffect, useState } from "react";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import DatePicker from "react-datepicker";
import Map from "../Map/Map";
import { Oval } from "react-loader-spinner"; // quick fix for me
import { useMedia, useMediaPredicate } from "react-media-hook";

export const SubmittedDetails = (props) => {
    let { id } = useParams();
    const { currentRequest, requestPackages, loadRequest, downloadFiles } = useContext(ContextGlobal);
    const phoneScreen = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(currentRequest).length === 0) {
            loadRequest(id);
        }
    }, []);

    if (Object.keys(currentRequest).length === 0) {
        return (
            <div className="text-center">
                <Oval color="white" height={100} width={100} />
            </div>
        );
    }

    const handleDownload = async (attachment) => {
        setLoading(attachment.requestAttachmentID);
        await downloadFiles(attachment);
        setLoading(null);
    };

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <Link to={"/request/" + id}>&lt; Report Page</Link>
            </div>
            <div className="card content-card">
                <div className="row g-0">
                    <div className="col-md-6 col-12" style={{ borderRight: phoneScreen ? "" : "1px solid #e1e5ec", paddingRight: phoneScreen ? "" : "40px" }}>
                        <div className="row d-flex align-items-end mb-4">
                            <div className="col-8">
                                <h3 className="mb-0">{currentRequest.request.typeProduct === "type_product_medical_cost_projection_standard" ? "Treatments you selected" : "Documents you uploaded"}</h3>
                            </div>
                            <div className="col-4 text-muted text-end">{"(Submitted on " + new Date(currentRequest.request.updatedAt * 1000).toISOString().substring(0, 10) + ")"}</div>
                        </div>
                        <div className="mb-4">
                            {currentRequest.request.typeProduct === "type_product_medical_cost_projection_pro" ? (
                                <div className="row-striped p-4">
                                    <h5 className="mb-4">You attached</h5>
                                    {currentRequest.request_attachment.map((attachment, index) => (
                                        <div className="row" key={index}>
                                            <div className="col-10">
                                                <p style={{ paddingLeft: 40 }}>{attachment.name}</p>
                                            </div>
                                            <div className="col">
                                                {loading == attachment.requestAttachmentID ? (
                                                    <Oval color="#00BFFF" height={20} width={20} />
                                                ) : (
                                                    <span onClick={(e) => handleDownload(attachment)}>
                                                        <FontAwesomeIcon icon={faFileDownload} style={{ color: "#1db8ea", cursor: "pointer" }} />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {currentRequest.request.typeProductID === 3
                                ? requestPackages.map((packageRow, index) => (
                                      <div key={index} className="row-striped p-4">
                                          <div className="row">
                                              <div className="col-md-11 col-10">
                                                  <h4 style={{ fontWeight: "500" }}>{packageRow.request_package.title}</h4>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-md-12 mb-2">
                                                  <small className="text-muted">
                                                      {packageRow.request_package.multiplier + " "}
                                                      {packageRow.request_package.multiplier > 1 || packageRow.request_package.multiplier === 0 ? "occurrences" : "occurrence"}
                                                      {packageRow.request_package.multiplierText ? " (" + packageRow.request_package.multiplierText + ")" : ""}
                                                  </small>
                                              </div>
                                          </div>
                                          {!phoneScreen ? (
                                              <>
                                                  {packageRow.request_bill.map((bill, indexBill) => (
                                                      <div className="ms-4 mb-1" key={indexBill} style={{ width: "80%", textDecoration: bill.isIncluded ? "" : "line-through" }}>
                                                          {bill.title}
                                                      </div>
                                                  ))}
                                              </>
                                          ) : null}
                                      </div>
                                  ))
                                : null}
                            {currentRequest.request.typeProductID === 4 || currentRequest.request.typeProductID === 2 ? (
                                <div className="row-striped p-4">
                                    <h5 className="mb-4">You attached</h5>
                                    {currentRequest.request_attachment.length != 0 ? (
                                        <>
                                            {currentRequest.request_attachment.map((attachment, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-10">
                                                        <p style={{ paddingLeft: 40 }}>{attachment.name}</p>
                                                    </div>
                                                    <div className="col">
                                                        {loading == attachment.requestAttachmentID ? (
                                                            <Oval color="#00BFFF" height={20} width={20} />
                                                        ) : (
                                                            <span onClick={(e) => handleDownload(attachment)}>
                                                                <FontAwesomeIcon icon={faFileDownload} style={{ color: "#1db8ea", cursor: "pointer" }} />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ paddingLeft: 40, marginBottom: 0 }}>No attachments on this report</p>
                                                <span style={{ fontSize: "12px", paddingLeft: 40 }}>
                                                    If you believe this to be a mistake,{" "}
                                                    <a href="/contact-us" rel="noopener noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                                                        contact us
                                                    </a>{" "}
                                                    for assistance.
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6 col-12" style={{ paddingLeft: "40px" }}>
                        <form className="review-form">
                            {currentRequest.type_product?.slug !== "type_product_medical_bill_analysis_standard" ? (
                                <>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <label htmlFor="report-title" className="form-label text-muted">
                                                <small>Report Title</small>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <input type="text" className={"form-control access-input-style"} id="report-title" aria-describedby="report-title-help" readOnly value={currentRequest.request.customNote} />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            <div className="row">
                                <div className="col-12 mt-5 mb-4">
                                    <h3>Patient Info</h3>
                                </div>
                            </div>
                            <div className="text-end"></div>
                            <div className="row">
                                <div className="col-xxl-3 col-xl-6 col-12" style={{ paddingRight: "0px" }}>
                                    <label htmlFor="first-name" className="form-label mb-0 text-muted">
                                        <small>First Name</small>
                                    </label>
                                    <input type="text" className={"form-control access-input-style"} id="first-name" readOnly value={currentRequest.patient.firstName} />
                                </div>
                                <div
                                    className="col-xxl-3 col-xl-6 col-12"
                                    style={{
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <label htmlFor="last-name" className="form-label mb-0 text-muted">
                                        <small>Last Name</small>
                                    </label>
                                    <input type="text" className={"form-control access-input-style"} id="last-name" readOnly value={currentRequest.patient.lastName} />
                                </div>
                                <div className="col-xxl-1 d-none d-xxl-block"></div>
                                <div
                                    className="col-xxl-3 col-xl-6 col-9"
                                    style={{
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <label htmlFor="date-of-loss" className="form-label mb-0 text-muted">
                                        <small>Date of Loss</small>
                                    </label>
                                    <DatePicker name={"date-of-loss"} className={"form-control access-input-style"} dateFormat="MM/dd/yyyy" selected={new Date(currentRequest.request.dateOfLoss)} minDate={new Date("1970-01-02")} maxDate={new Date()} readOnly />
                                </div>
                                <div className="col-xxl-2 col-xl-6 col-3" style={{ paddingLeft: "0px" }}>
                                    <label htmlFor="sex" className="form-label mb-0 text-muted">
                                        <small>Sex</small>
                                    </label>
                                    <select className={"form-select access-input-style"} id="sex" readOnly value={currentRequest.patient.sex} style={{ background: "none", paddingLeft: "0px" }}>
                                        <option disabled>Select</option>
                                        <option value="M">M</option>
                                        <option value="F">F</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex row mt-4" style={{ marginBottom: "40px" }}>
                                <div className="col">
                                    <label htmlFor="treatment-location" className="form-label mb-0 text-muted">
                                        <small>Treatment Location</small>
                                    </label>

                                    <input type="text" className={"form-control access-input-style "} id="treatment-location" readOnly value="Test Location" />
                                </div>
                            </div>
                            <div className="mt-4 card d-flex align-items-center" style={{ width: "100%", height: 200 }}>
                                <Map latLong={{ lat: 39.56664158466453, long: -104.85997523052264 }} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
