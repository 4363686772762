const reorder = (state) => state.map((packageRow, index) => ({ ...packageRow, orderID: index }));

const calculateQuantity = (packageObj) => {
    var multiplier, multiplierText;
    const dayValues = {
        day: 1,
        week: 7,
        month: 30,
        quarter: 90,
        year: 365,
    };

    if (!packageObj.frequency) {
        packageObj.frequency = "day"; // default value - .frequency = null sometimes
    }

    if (packageObj.frequency === undefined && packageObj.frequencyMultiplier > 1 && packageObj.period === undefined && packageObj.duration > 1) {
        multiplier = "Incomplete:";
        multiplierText = "Please fill in both frequency fields & duration fields";
        return {
            ...packageObj,
            multiplier,
            multiplierText,
        };
    }

    if (packageObj.frequency === undefined && packageObj.frequencyMultiplier > 1) {
        multiplier = "Incomplete:";
        multiplierText = "Please fill in both frequency fields";
        return {
            ...packageObj,
            multiplier,
            multiplierText,
        };
    }

    if (packageObj.period === undefined && packageObj.duration > 1) {
        multiplier = "Incomplete:";
        multiplierText = "Please fill in both duration fields";
        return {
            ...packageObj,
            multiplier,
            multiplierText,
        };
    }

    if (packageObj.frequency && !packageObj.frequencyMultiplier) {
        packageObj.frequencyMultiplier = 1;
    }

    if (packageObj.period && !packageObj.duration) {
        packageObj.duration = 1;
    }

    multiplier = packageObj.quantity;
    multiplierText = "";

    if (packageObj.frequencyMultiplier && packageObj.frequency == null) {
        packageObj.frequency = "day";
    }

    if (packageObj.frequency && packageObj.period) {
        const frequencyDays = (packageObj.frequencyMultiplier ?? 1) * dayValues[packageObj.frequency ?? "day"];
        const durationDays = (packageObj.duration ?? 1) * dayValues[packageObj.period ?? "day"];

        multiplier = packageObj.quantity * Math.floor(durationDays / frequencyDays);
        multiplier = multiplier > 0 && isFinite(multiplier) ? multiplier : 0;

        if (packageObj.frequencyMultiplier && packageObj.duration) {
            var quantity = "";
            if (packageObj.quantity === 1) {
                quantity = "once ";
            } else if (packageObj.quantity === 2) {
                quantity = "twice ";
            } else {
                quantity = packageObj.quantity + " times ";
            }

            var frequency = "";
            if (packageObj.frequencyMultiplier === 1) {
                frequency = packageObj.frequency + " ";
            } else {
                frequency = packageObj.frequencyMultiplier + " " + packageObj.frequency + "s ";
            }

            var duration = "";
            if (packageObj.duration === 1) {
                duration = "a " + packageObj.period;
            } else {
                duration = packageObj.duration + " " + packageObj.period + "s";
            }

            multiplierText = quantity + "every " + frequency + "for " + duration;
        }
    }

    return {
        ...packageObj,
        multiplier,
        multiplierText,
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_PACKAGE": {
            // allow for the same package to be added
            // if (!state.some((packageRow) => packageRow.id===action.payload.id)) {
            // console.log(action.payload);
            return reorder(
                state.concat({
                    ...action.payload,
                    isForm: false,
                    isExpanded: false,
                    isFormReview: false,
                })
            );
            // }

            // break;
        }

        case "ADD_PACKAGES": {
            // allow for the same package to be added
            // if (!state.some((packageRow) => packageRow.id===action.payload.id)) {
            // console.log(action.payload);

            return action.payload.map((p, i) => ({
                ...p,
                isForm: false,
                isExpanded: false,
                isFormReview: false,
                orderID: i,
            }));
            // }

            // break;
        }

        case "UPDATE_PACKAGE": {
            return state.map((row) => {
                if (row.request_package.requestPackageID === action.payload.requestPackageID) {
                    // determine what is being updated...
                    if (action.payload.bill !== undefined) {
                        row.request_bill.forEach((bill) => {
                            if (bill.requestBillID === action.payload.bill) {
                                bill.isIncluded = action.payload.include;
                            }
                        });

                        return row;
                    } else {
                        const multiplier = calculateQuantity({ ...action.payload.updated_request_package });
                        action.payload.api("post", "/request_package/" + action.payload.requestPackageID, {
                            request_package: {
                                multiplier: parseInt(multiplier.multiplier),
                                multiplierText: multiplier.multiplierText,
                            },
                        });

                        return {
                            ...row,
                            request_package: {
                                ...row.request_package,
                                ...multiplier,
                            },
                        };
                    }
                } else {
                    return row;
                }
            });
        }

        case "REORDER_PACKAGES": {
            const [removed] = state.splice(action.payload.startIndex, 1);
            state.splice(action.payload.endIndex, 0, removed);

            return reorder(state);
        }

        case "DELETE_PACKAGE": {
            return reorder(state.filter((packageRow) => packageRow.request_package.requestPackageID !== action.payload.requestPackageID));
        }

        case "CLEAR_ALL": {
            return [];
        }

        default: {
            return state;
        }
    }
};

export default reducer;
