const reducer = (state, action) => {
    switch (action.type) {
        case "SET_FEEDBACK": {
            return state.concat( [action.payload] )
        }
        case "DELETE_FEEDBACK": {
            return state.filter(
                (feedback, index) => index !== action.payload
            )
        }
        default: {
            return state;
        }
    }
};

export default reducer;
