import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUser, faCircle, faAddressBook } from "@fortawesome/free-solid-svg-icons"
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { ContactBookModal } from "../../Modals/ContactBookModal";

export const SharedList = (props) => {

    const { sharedList, updateFormContactInfo, session, deleteFromSharedList } = useContext(ContextGlobal);

    const [hover, setHover] = useState();

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    const contactRef = useRef([]);

    const handleDeleteShared = async (id, email) => {
        contactRef.current.forEach((ref, index) => {
            if (ref.value === email) {
                ref.checked = false;
                ref.disabled = false; // un disable
            }
        });
        deleteFromSharedList(id);
    };

    return (
        <>
            <h3 className="mb-4" style={{ marginTop: "60px" }}>
                Report will be shared with
            </h3>
            {sharedList.length > 0 && (
                <div className="row mb-2 text-muted">
                    <div className="col-2 offset-1 d-none d-sm-block" style={{ paddingLeft: "0px" }}>
                        First Name
                    </div>
                    <div className="col-2 d-none d-sm-block" style={{ paddingLeft: "0px" }}>
                        Last Name
                    </div>
                    <div className="col-6 d-none d-sm-block" style={{ paddingLeft: "0px" }}>
                        Email
                    </div>
                </div>
            )}
            {sharedList.length > 0 ? (
                sharedList.map((contact, index) => (
                    <div className="row mb-2" key={index}>
                        <div className="col-md-1 col-2 text-end" style={{ paddingRight: "16px" }}>
                            <span>
                                <h3 style={{ marginTop: "2px", marginBottom: "0px", }} >
                                    <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                        <FontAwesomeIcon icon={faCircle} color={sharedIcons[index % sharedIcons.length].background} />
                                        <FontAwesomeIcon icon={faUser} transform="shrink-6" color={sharedIcons[index % sharedIcons.length].icon} />
                                    </span>
                                </h3>
                            </span>
                        </div>
                        <div className="col-md-2 d-none d-sm-block" style={{ paddingLeft: "0px", paddingRight: "0px", }} >
                            <input
                                type="text"
                                className="form-control access-input-style"
                                id={"shared-first-name-" + index}
                                value={contact.firstName ?? ""}
                                readOnly
                                onChange={(e) =>
                                    updateFormContactInfo({
                                        id: contact.contactID,
                                        firstName: e.target.value,
                                    })
                                }
                                style={{ paddingLeft: "0px" }}
                            />
                        </div>
                        <div className="col-md-2 d-none d-sm-block" style={{ paddingLeft: "0px", paddingRight: "0px", }} >
                            <input
                                type="text"
                                className="form-control access-input-style "
                                id={"shared-last-name-" + index}
                                value={contact.lastName ?? ""}
                                readOnly
                                onChange={(e) =>
                                    updateFormContactInfo({
                                        id: contact.contactID,
                                        lastName: e.target.value,
                                    })
                                }
                                style={{ paddingLeft: "0px" }}
                            />
                        </div>
                        <div className="col-md-6 col-8" style={{ paddingLeft: "0px" }}>
                            <input
                                type="email"
                                className="form-control access-input-style "
                                id={"shared-email-" + index}
                                value={contact.email ?? ""}
                                readOnly
                                onChange={(e) =>
                                    updateFormContactInfo({
                                        id: contact.contactID,
                                        email: e.target.value,
                                    })
                                }
                                style={{ paddingLeft: "0px" }}
                            />
                        </div>
                        <div className="col-1" style={{ paddingLeft: "0px" }}>
                            <span
                                className="fa-layers fa-fw fa-center-icon"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleDeleteShared(contact.requestSubscriptionID, contact.email)}
                                onMouseEnter={(e) => setHover(index)}
                                onMouseLeave={(e) => setHover()}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        display: hover !== index ? "none" : "",
                                    }}
                                    icon={faCircle}
                                    color="#1db8ea"
                                />
                                <FontAwesomeIcon icon={faTimes} transform="shrink-6" className="share-remove" color={hover === index ? "white" : "#1db8ea"} />
                            </span>
                        </div>
                    </div>
                ))
            ) : (
                <p>No contacts added</p>
            )}
            {session.email && (
                <div
                    className="d-flex flex-row mt-4"
                    style={{
                        color: "#1db8ea",
                        font: "600 0.9rem Montserrat",
                    }}
                >
                    <div style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#address-book">
                        <span className="me-2">
                            <FontAwesomeIcon icon={faAddressBook} />
                        </span>
                        OPEN CONTACT BOOK
                    </div>
                </div>
            )}
            <ContactBookModal contactRef={contactRef} />
        </>
    )

};
