import React, { useEffect, useState, useRef, useContext } from "react";

import { ContextGlobal } from "../../contexts/ContextGlobal.js";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";

export const DownloadSampleModal = (props) => {
    // if session -> have the email field filled in already
    const { downloadSample, session } = useContext(ContextGlobal);

    const history = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [closed, setClosed] = useState(false);
    const [success, setSuccess] = useState(false); // to stop from downloading sample without entering email

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({ mode: "onSubmit" });

    const download = useRef(null);
    const closeRef = useRef();
    const [product, setProduct] = useState("");
    const [link, setLink] = useState(""); // download link for the product

    useEffect(() => {
        switch (props.product) {
            case "type_product_medical_cost_projection_standard":
                setProduct("Standard Medical Cost Projection");
                setLink("/files/Sample - Standard Medical Cost Projection - Ron Burgundy - 05-26-2023.pdf");
                break;
            case "type_product_medical_cost_projection_pro":
                setProduct("Pro Medical Cost Projection");
                setLink("/files/Sample - Pro Medical Cost Projection - Ron Burgundy - 11-13-2023.pdf");
                break;
            // case 'type_product_medical_cost_projection_expert':
            //     setLink("");
            //     break;
            // case 'type_product_medical_bill_analysis_standard':
            //     setLink("");
            //     break;
            // case 'type_product_medical_bill_analysis_pro':
            //     setLink("");
            //     break;
            // case 'type_product_medical_bill_analysis_expert':
            //     setLink("");
            //     break;
            // case 'type_product_bill_analysis_with_code_review_pro':
            //     setLink("");
            //     break;
            // case 'type_product_bill_analysis_with_code_review_expert':
            //     setLink("");
            //     break;
            default:
                break;
        }
    }, [props.product]);

    const onSubmitEmail = async (data) => {
        // -- Constant Contact Form --
        const emailCC = document.getElementById("email_address_0");
        emailCC.value = data.email;
        document.querySelector(".ctct-form-button").click();

        setLoading(true);
        data.product = product;
        data.sample = true;
        await downloadSample(data);
        reset({
            email: "",
        });
        setLoading(false);
        closeRef.current.click();
        setSuccess(true); // allow button to work?
    };

    useEffect(() => {
        if (success) {
            download.current.click();
            setSuccess(false);
        }
    }, [success]);

    return (
        <div className="modal fade" id="download-sample-modal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "700px" }}>
                <div className="modal-content modal-eula-sign">
                    <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" onClick={() => setClosed(true)} />
                    <div className="modal-header" style={{ borderBottom: "none", padding: 0 }}>
                        <h3 className="modal-title w-100 text-center" style={{ fontWeight: "700", color: "#000064" }}>
                            Enter your email to view a Sample Report
                        </h3>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmitEmail)}>
                            <div className="row">
                                <div className="col-10 offset-md-1">
                                    <input type="text" placeholder="Email" className={"form-control access-input-style " + (errors.email ? "is-invalid" : "")} id="email" {...register("email", { required: "Please enter your email." })} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <button className="btn access-secondary-button" style={{ textDecoration: "none", color: "white", width: "82%" }}>
                                        {loading ? <Oval color="white" height={20} width={20} /> : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                        {success ? (
                            <Link style={{ display: "none" }} to={link} target="_blank" rel="noopener noreferrer">
                                <button ref={download}></button>
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="ctct-inline-form" style={{ display: "none" }} data-form-id="446f78c8-ca25-4d97-94d3-898d411b7712"></div>
            <button data-bs-dismiss="modal" style={{ display: "none" }} ref={closeRef}></button>
        </div>
    );
};
