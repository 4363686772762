import { faAddressBook, faCircle, faFileDownload, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner"; // quick fix for me
import { ConfirmationModal } from "../Modals/ConfirmationModal";
import { Tooltip } from "react-bootstrap";

export const Submitted = (props) => {
    const phoneScreen = useMediaPredicate("(max-width: 450px)");

    const validationSchema = Yup.object().shape({
        contactFirstName: Yup.string().label("First Name"),
        contactLastName: Yup.string().label("Last Name"),
        contactEmail: Yup.string().email().label("Email"),
    });

    let { id } = useParams();
    const { session, formPatientInfo, updateFormPatientInfo, addToSharedList, currentRequest, requestPackages, contactList, sharedList, deleteFromSharedList, loadRequest, downloadFiles, downloadS3, downloadMultipleS3 } = useContext(ContextGlobal);

    useEffect(() => {
        if (Object.keys(currentRequest).length === 0) {
            loadRequest(id);
        }
    }, []);

    const {
        register,
        formState: { errors },
        setValue,
        setError,
        trigger,
    } = useForm({ mode: "all", resolver: yupResolver(validationSchema) });

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    const handleAddToSharedList = (e) => {
        e.preventDefault();

        if (!formPatientInfo.contactFirstName) {
            setError("contactFirstName", { type: "manual", message: "First Name is a required field" });
        }
        if (!formPatientInfo.contactLastName) {
            setError("contactLastName", { type: "manual", message: "Last Name is a required field" });
        }
        if (!formPatientInfo.contactEmail) {
            setError("contactEmail", { type: "manual", message: "Email is a required field" });
        }

        if (!errors.contactFirstName && !errors.contactLastName && !errors.contactEmail) {
            addToSharedList([
                {
                    firstName: formPatientInfo.contactFirstName,
                    lastName: formPatientInfo.contactLastName,
                    email: formPatientInfo.contactEmail,
                },
            ]);
            setValue("contactFirstName", "");
            setValue("contactLastName", "");
            setValue("contactEmail", "");
        }
    };

    const [sharedContacts, setSharedContacts] = useState([]);
    const contactRef = useRef([]);

    useEffect(() => {
        // check the correct contacts in the contact book
        if (sharedList.length !== 0) {
            contactRef.current.forEach((ref, index) => {
                sharedList.forEach((shared, index) => {
                    if (shared.email === ref.value) {
                        ref.disabled = true;
                        ref.checked = true;
                    }
                });
            });
        }
    }, [sharedList, contactRef]);

    const handleCheck = async (e, id, index) => {
        if (e.target.checked) {
            // add to share list
            const filtered = contactList.filter((contact, i) => id === contact.contactID);
            setSharedContacts([...sharedContacts, ...filtered]);
        } else {
            // remove from temp sharedContacts array when unchecking before clicking 'share' button
            const filtered = sharedContacts.filter((shared) => contactRef.current[index].value !== shared.email);
            setSharedContacts(filtered);
        }
    };

    const handleShare = async () => {
        addToSharedList(sharedContacts);
        // disable added contacts
        contactRef.current.forEach((ref, index) => {
            sharedContacts.forEach((shared, index) => {
                if (shared.email === ref.value) {
                    ref.disabled = true;
                }
            });
        });
        setSharedContacts([]); // clears the temp array used to push to sharedList
    };

    const handleDeleteShared = async (id, email) => {
        contactRef.current.forEach((ref, index) => {
            if (ref.value === email) {
                ref.checked = false;
                ref.disabled = false; // un disable
            }
        });
        deleteFromSharedList(id);
    };

    const [hover, setHover] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [loadingXFile, setLoadingXFile] = useState(null);

    const modalButton = useRef(null);

    useEffect(() => {
        if (!session.isConfirmed && modalButton.current != null) {
            modalButton.current.click();
        }

        // componentWillUnmount()
        return () => {
            // remove bootstrap modal-backdrop
            if (document.getElementsByClassName("modal-backdrop")[0] != undefined && document.getElementsByClassName("modal-open")[0] != undefined) {
                document.getElementsByClassName("modal-open")[0].classList.remove("modal-open");
                document.getElementsByClassName("modal-backdrop")[0].classList.remove("modal-backdrop");
                document.getElementsByClassName("fade")[0].classList.remove("fade");
                document.getElementsByClassName("show")[0].classList.remove("show");
            }
        };
    }, [session]);

    const handleDownload = async (attachment) => {
        setLoading(attachment.requestAttachmentID);
        await downloadFiles(attachment);
        setLoading(null);
    };

    const handleReport = async () => {
        setLoadingFile(true);
        await downloadS3();
        setLoadingFile(false);
    };

    // quick fix for me
    if (Object.keys(currentRequest).length === 0) {
        return (
            <div className="text-center">
                <Oval color="white" height={100} width={100} />
            </div>
        );
    }

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <Link to={"/my_reports"}>&lt; My Reports</Link>
            </div>
            <div className="card content-card" style={{ borderRadius: "20px" }}>
                <div className="row">
                    {/* Left Side */}
                    <div className="col-md-6 col-12" style={{ borderRight: phoneScreen ? "" : "1px solid #e1e5ec", paddingRight: phoneScreen ? "" : "40px" }}>
                        <div className="row d-flex align-items-end mb-4">
                            {currentRequest.request_status.slug === "being-generated" ? (
                                <>
                                    <div className="col-8">
                                        <h3 className="mb-0">We're finishing your report!</h3>
                                    </div>
                                    <div className="col-4 text-muted text-end">{"(Submitted on " + new Date(currentRequest.request.updatedAt * 1000).toISOString().substring(0, 10) + ")"}</div>
                                </>
                            ) : currentRequest.request_status.slug === "report-ready" ? (
                                <>
                                    <div className="row">
                                        <h3 className="mb-0 row" style={{ color: "#1db8ea" }}>
                                            <div className={phoneScreen ? "col-10" : "col-7"}>Download your finished report &nbsp;</div>
                                            <div className={phoneScreen ? "col-2 mt-2" : "col-2"}>
                                                {loadingFile ? (
                                                    <Oval color="#00BFFF" height={20} width={20} />
                                                ) : (
                                                    <span style={{ cursor: "pointer" }} onClick={(e) => handleReport()}>
                                                        <FontAwesomeIcon icon={faFileDownload} />
                                                    </span>
                                                )}
                                            </div>
                                        </h3>
                                    </div>
                                    <div className="row">
                                        <p style={{ color: "#000046", fontSize: "16px", fontWeight: "500" }}>{currentRequest.request.customNote}</p>
                                    </div>
                                    <div className="row">
                                        {currentRequest.document.length > 1 && (
                                            <>
                                                {currentRequest.document.map((doc, index) => (
                                                    <div className="col-12 mb-2" key={index}>
                                                        <span
                                                            className="doc-link"
                                                            onClick={async () => {
                                                                setLoadingXFile(doc.id);
                                                                await downloadMultipleS3([doc]);
                                                                setLoadingXFile(null);
                                                            }}
                                                        >
                                                            {doc.name}
                                                        </span>
                                                        {loadingXFile == doc.id ? (
                                                            <Oval
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "15px",
                                                                }}
                                                                color="#1db8ea"
                                                                height={20}
                                                                width={20}
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))}
                                                <div className="col-12 mb-2">
                                                    <span
                                                        className="doc-link"
                                                        onClick={async () => {
                                                            setLoadingXFile("all");
                                                            await downloadMultipleS3(currentRequest.document);
                                                            setLoadingXFile(null);
                                                        }}
                                                    >
                                                        Download All
                                                    </span>
                                                    {loadingXFile === "all" ? (
                                                        <Oval
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: "15px",
                                                            }}
                                                            color="#1db8ea"
                                                            height={20}
                                                            width={20}
                                                        />
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="row mb-3">
                                        <span style={{ fontSize: "15px" }}>
                                            NEED TO SEE WHAT YOU SUBMITTED?{" "}
                                            <Link className="faq-contact-link" to={"/request/details/" + id}>
                                                WE'VE GOT IT HERE
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="row">
                                        <h3 className="mb-0" style={{ color: "#000046" }}>
                                            Report summary
                                        </h3>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="mb-4">
                            {currentRequest.request.typeProduct === "type_product_medical_cost_projection_pro" ? (
                                <div className="row-striped p-4">
                                    <h5 className="mb-4">You attached</h5>
                                    {currentRequest.request_attachment.map((attachment, index) => (
                                        <div className="row" key={index}>
                                            <div className="col-10">
                                                <p style={{ paddingLeft: 40 }}>{attachment.name}</p>
                                            </div>
                                            <div className="col">
                                                {loading == attachment.requestAttachmentID ? (
                                                    <Oval color="#00BFFF" height={20} width={20} />
                                                ) : (
                                                    <span onClick={(e) => handleDownload(attachment)}>
                                                        <FontAwesomeIcon icon={faFileDownload} style={{ color: "#1db8ea", cursor: "pointer" }} />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {currentRequest.request.typeProductID === 3
                                ? requestPackages.map((packageRow, index) => (
                                      <div key={index} className="row-striped p-4">
                                          <div className="row">
                                              <div className="col-md-11 col-10">
                                                  <h4 style={{ fontWeight: "500" }}>{packageRow.request_package.title}</h4>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-md-12 mb-2">
                                                  <small className="text-muted">
                                                      {packageRow.request_package.multiplier + " "}
                                                      {packageRow.request_package.multiplier > 1 || packageRow.request_package.multiplier === 0 ? "occurrences" : "occurrence"}
                                                      {packageRow.request_package.multiplierText ? " (" + packageRow.request_package.multiplierText + ")" : ""}
                                                  </small>
                                              </div>
                                          </div>
                                          {!phoneScreen ? (
                                              <>
                                                  {packageRow.request_bill.map((bill, indexBill) => (
                                                      <div className="ms-4 mb-1" key={indexBill} style={{ width: "80%", textDecoration: bill.isIncluded ? "" : "line-through" }}>
                                                          {bill.title}
                                                      </div>
                                                  ))}
                                              </>
                                          ) : null}
                                      </div>
                                  ))
                                : null}
                            {currentRequest.request.typeProductID === 4 || currentRequest.request.typeProductID === 2 ? (
                                <div className="row-striped p-4">
                                    <h5 className="mb-4">You attached</h5>
                                    {currentRequest.request_attachment.length != 0 ? (
                                        <>
                                            {currentRequest.request_attachment.map((attachment, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-10">
                                                        <p style={{ paddingLeft: 40 }}>{attachment.name}</p>
                                                    </div>
                                                    <div className="col">
                                                        {loading == attachment.requestAttachmentID ? (
                                                            <Oval color="#00BFFF" height={20} width={20} />
                                                        ) : (
                                                            <span onClick={(e) => handleDownload(attachment)}>
                                                                <FontAwesomeIcon icon={faFileDownload} style={{ color: "#1db8ea", cursor: "pointer" }} />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ paddingLeft: 40, marginBottom: 0 }}>No attachments on this report</p>
                                                <span style={{ fontSize: "12px", paddingLeft: 40 }}>
                                                    If you believe this to be a mistake,{" "}
                                                    <a href="/contact-us" rel="noopener noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                                                        contact us
                                                    </a>{" "}
                                                    for assistance.
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {/* Right Side */}
                    <div className="col-md-6 col-12" style={{ paddingLeft: phoneScreen ? "" : "40px" }}>
                        <h3 className="mb-4">Share report with</h3>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <label htmlFor="contact-first-name" className="form-label access-input-label-style">
                                    First Name{" "}
                                    <sup className="ms-1" style={{ color: "red" }}>
                                        *
                                    </sup>
                                </label>
                                <input type="text" placeholder="First Name" className="form-control access-input-style" id="contact-first-name" {...register("contactFirstName")} onChange={(e) => updateFormPatientInfo({ contactFirstName: e.target.value })} />
                                <div className="invalid-feedback">{errors.contactFirstName?.message}</div>
                            </div>
                            <div className="col-md-3 col-12">
                                <label htmlFor="contact-last-name" className="form-label access-input-label-style">
                                    Last Name{" "}
                                    <sup className="ms-1" style={{ color: "red" }}>
                                        *
                                    </sup>
                                </label>
                                <input type="text" placeholder="Last Name" className="form-control access-input-style" id="contact-last-name" {...register("contactLastName")} onChange={(e) => updateFormPatientInfo({ contactLastName: e.target.value })} data-tip data-for="contact-last-name-tooltip" data-event="mouseenter" data-event-off="mouseleave" />
                                <ReactTooltip id="contact-last-name-tooltip" type="warning" effect="solid" multiline={true}>
                                    <span>
                                        We'll save your contacts so you won't
                                        <br />
                                        have to enter them next time
                                    </span>
                                </ReactTooltip>
                                <div className="invalid-feedback">{errors.contactLastName?.message}</div>
                            </div>
                            <div className="col-md-6 col-12">
                                <label htmlFor="contact-email" className="form-label access-input-label-style">
                                    Email{" "}
                                    <sup className="ms-1" style={{ color: "red" }}>
                                        *
                                    </sup>
                                </label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control access-input-style"
                                    id="contact-email"
                                    {...register("contactEmail")}
                                    onChange={(e) => {
                                        trigger("contactEmail");
                                        updateFormPatientInfo({ contactEmail: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="invalid-feedback">{errors.contactEmail?.message}</div>
                        </div>
                        <button type="button" className="btn access-secondary-button" style={{ marginTop: "24px", height: "24", width: 200, fontSize: "11pt" }} onClick={handleAddToSharedList}>
                            Add to share list
                        </button>
                        <h3 className="mb-4" style={{ marginTop: "60px" }}>
                            {currentRequest.request_status.slug === "being-generated" && "Report will be shared with"}
                            {currentRequest.request_status.slug === "report-ready" && "Report has been shared with"}
                        </h3>
                        {sharedList.length > 0 && (
                            <div className="row mb-2 text-muted">
                                <div className="col-3 offset-1 d-none d-sm-block" style={{ paddingLeft: "0px" }}>
                                    First Name
                                </div>
                                <div className="col-3 d-none d-sm-block" style={{ paddingLeft: "0px" }}>
                                    Last Name
                                </div>
                                <div className="col-4 d-none d-sm-block" style={{ paddingLeft: "0px" }}>
                                    Email
                                </div>
                            </div>
                        )}
                        {sharedList.length > 0 ? (
                            sharedList.map((contact, index) => (
                                <div className="row mb-2" key={index}>
                                    <div className="col-md-1 col-2 text-end" style={{ paddingRight: "16px" }}>
                                        <span>
                                            <h3 style={{ marginTop: "2px", marginBottom: "0px" }}>
                                                <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                                    <FontAwesomeIcon icon={faCircle} color={sharedIcons[index % sharedIcons.length].background} />
                                                    <FontAwesomeIcon icon={faUser} transform="shrink-6" color={sharedIcons[index % sharedIcons.length].icon} />
                                                </span>
                                            </h3>
                                        </span>
                                    </div>
                                    <div className="col-md-3 d-none d-sm-block" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <input type="text" className="form-control access-input-style border-down" id={"shared-first-name-" + index} value={contact.firstName ?? ""} readOnly style={{ paddingLeft: "0px" }} />
                                    </div>
                                    <div className="col-md-3 d-none d-sm-block" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <input type="text" className="form-control access-input-style border-down" id={"shared-last-name-" + index} value={contact.lastName ?? ""} readOnly style={{ paddingLeft: "0px" }} />
                                    </div>
                                    <div
                                        className="col-md-4 col-8"
                                        style={{
                                            paddingLeft: "0px",
                                        }}
                                    >
                                        <input type="email" className="form-control access-input-style border-down" id={"shared-email-" + index} value={contact.email ?? ""} readOnly style={{ paddingLeft: "0px" }} />
                                    </div>
                                    <div className="col-1">
                                        <span className="fa-layers fa-fw fa-center-icon" style={{ cursor: "pointer" }} onClick={(e) => handleDeleteShared(contact.requestSubscriptionID, contact.email)} onMouseEnter={(e) => setHover(index)} onMouseLeave={(e) => setHover()}>
                                            <FontAwesomeIcon style={{ display: hover !== index ? "none" : "" }} icon={faCircle} color="#1db8ea" />
                                            <FontAwesomeIcon icon={faTimes} transform="shrink-6" className="share-remove" color={hover === index ? "white" : "#1db8ea"} />
                                        </span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No contacts added</p>
                        )}
                        {session.email && (
                            <div className="d-flex flex-row mt-4" style={{ color: "#1db8ea", font: "600 0.9rem Montserrat" }}>
                                <div style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#address-book">
                                    <span className="me-2">
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </span>
                                    OPEN CONTACT BOOK
                                </div>
                            </div>
                        )}

                        {/* Contact Book */}
                        <div className="modal fade" id="address-book" tabIndex="-1" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "900px" }}>
                                <div className="modal-content modal-eula-sign">
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" />
                                    <div
                                        className="modal-header"
                                        style={{
                                            font: "700 1.2rem Montserrat",
                                            color: "#000064",
                                            borderBottom: "none",
                                        }}
                                    >
                                        <span style={{ marginLeft: "25px" }}>Your Contacts</span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row text-muted mb-2" style={{ fontSize: "0.9em" }}>
                                            <div style={{ paddingLeft: "0px" }} className="col-2"></div>
                                            <div style={{ paddingLeft: "0px" }} className="col-2">
                                                First Name
                                            </div>
                                            <div style={{ paddingLeft: "0px" }} className="col-3">
                                                Last Name
                                            </div>
                                            <div style={{ paddingLeft: "0px" }} className="col-5">
                                                Email
                                            </div>
                                        </div>
                                        {contactList.map((contact, index) => (
                                            <div key={index} className="d-flex row mb-3">
                                                <div className="col-2">
                                                    <input
                                                        className="form-check-input mx-3"
                                                        type="checkbox"
                                                        id={"contact-" + contact.contactID}
                                                        onChange={(e) => {
                                                            handleCheck(e, contact.contactID, index);
                                                        }}
                                                        ref={(ref) => {
                                                            contactRef.current[index] = ref;
                                                        }}
                                                        value={contact.email}
                                                    />
                                                    <label className="form-check-label" htmlFor={"contact-" + index}>
                                                        <h3>
                                                            <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                                                <FontAwesomeIcon icon={faCircle} color={sharedIcons[index % sharedIcons.length].background} />
                                                                <FontAwesomeIcon icon={faUser} transform="shrink-6" color={sharedIcons[index % sharedIcons.length].icon} />
                                                            </span>
                                                        </h3>
                                                    </label>
                                                </div>
                                                <div
                                                    className="col-2"
                                                    style={{
                                                        borderBottom: "2px solid #f2f4f7",
                                                        paddingLeft: "0px",
                                                        height: "fit-content",
                                                        paddingBottom: "0.375rem",
                                                        paddingTop: "0.375rem",
                                                    }}
                                                >
                                                    {contact.firstName}
                                                </div>
                                                <div
                                                    className="col-3"
                                                    style={{
                                                        borderBottom: "2px solid #f2f4f7",
                                                        paddingLeft: "0px",
                                                        height: "fit-content",
                                                        paddingBottom: "0.375rem",
                                                        paddingTop: "0.375rem",
                                                    }}
                                                >
                                                    {contact.lastName}
                                                </div>
                                                <div
                                                    className="col-5"
                                                    style={{
                                                        borderBottom: "2px solid #f2f4f7",
                                                        paddingLeft: "0px",
                                                        height: "fit-content",
                                                        paddingBottom: "0.375rem",
                                                        paddingTop: "0.375rem",
                                                    }}
                                                >
                                                    {contact.email}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <button
                                            style={{ marginLeft: "2.8em" }}
                                            type="button"
                                            className="btn access-secondary-button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                handleShare();
                                            }}
                                        >
                                            Share
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                data-bs-toggle="modal"
                data-bs-target="#confirmation_email"
                ref={modalButton}
                style={{
                    display: "none",
                }}
            ></button>
            <ConfirmationModal />
        </>
    );
};
