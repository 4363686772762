import React from "react";
import { Link } from "react-router-dom";

export const ProductsPricing = (props) => {

    const phoneSize = props.phoneSize;
    const midSizeScreen = props.midSizeScreen;
    const laptopSize = props.laptopSize;

    return (
        <>
            <div className="row g-0 mb-3 mx-2">
                <div className="col-md-12 faq-headings" style={{ color: "#000046" }}>
                    Products and Pricing
                </div>
                <div className="text-muted" style={{ fontSize: "13px" }}>
                    Rush fee of $1,000 may apply if results are needed quicker than our typical turnaround
                </div>
            </div>
            {/* Medical Cost Projection */}
            <div className="row g-0 mx-2 mb-3">
                <div className="col-md-12" style={{ fontSize: "14pt", fontWeight: "500", color: "#1db8ea"}}>
                    Future Analysis - <span style={{ fontWeight: "600" }}>Medical Cost Projection</span>
                </div>
                <div className="col-md-12">
                    A complete estimate of the reasonable value of future medical care as recommended by a medical professional
                </div>
            </div>
            <div className="row g-0 mx-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Standard</div>
                        <div className="col-md-2 col-3">$500</div>
                        <div className="col-md-5 col-9 fst-italic">1-2 day turnaround</div>
                    </div>
                </div>
            </div>
            <div className="row g-0 mx-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Pro</div>
                        <div className="col-md-2 col-3">$950</div>
                        <div className="col-md-5 col-9 fst-italic">5-7 day turnaround</div>
                    </div>
                </div>
            </div>
            <div className="row g-0 mx-2 mb-4">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Expert</div>
                        <div className="col-md-2 col-3">$2,950</div>
                        <div className="col-md-5 col-9 fst-italic">3 week turnaround</div>
                    </div>
                </div>
            </div>
            {/* Historical Analysis */}
            <div className="row g-0 mx-2 mb-3">
                <div className="col-md-12" style={{ fontSize: "14pt", fontWeight: "500", color: "#1db8ea"}}>
                    Historical Analysis - <span style={{ fontWeight: "600" }}>Medical Bill Analysis</span>
                </div>
                <div className="col-md-12">
                    Review of medical bills to establish reasonable value using our database of over 20 billion patient encounters to compare actual billed charges to other providers' fees in the same area.
                </div>
            </div>
            <div className="row g-0 mx-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Standard</div>
                        <div className="col-md-3">$100 + 1% of billed charges</div>
                        <div className="col-md-7 fst-italic">1 week turnaround <span className="text-muted">(for use on cases w/ &#60;$50k in bills)</span></div>
                    </div>
                </div>
            </div>
            <div className="row g-0 mx-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Pro</div>
                        <div className="col-md-3">$500 + 1% of billed charges</div>
                        <div className="col-md-7 fst-italic">2 week turnaround</div>
                    </div>
                </div>
            </div>
            <div className="row g-0 mx-2 mb-4">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Expert</div>
                        <div className="col-md-3">$1,750 + 1% of billed charges</div>
                        <div className="col-md-7 fst-italic">3 week turnaround</div>
                    </div>
                </div>
            </div>
            {/* Bill Analysis + Code Review */}
            <div className="row g-0 mx-2 mb-3">
                <div className="col-md-12" style={{ fontSize: "14pt", fontWeight: "500", color: "#1db8ea"}}>
                    Historical Analysis - <span style={{ fontWeight: "600" }}>Bill Analysis + Code Review</span>
                </div>
                <div className="col-md-12">
                    Audit of medical bills & records, associated medical coding, and billed charges to establish both medical coding compliance and reasonable value of billed charges in the area where treatment was received.
                </div>
            </div>
            <div className="row g-0 mx-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Pro</div>
                        <div className="col-md-3">$1000 + 2% of billed charges</div>
                        <div className="col-md-7 fst-italic">3 week turnaround</div>
                    </div>
                </div>
            </div>
            <div className="row g-0 mx-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className={"col-md-2 fw-bold" + (laptopSize ? "" : " text-end")}>Expert</div>
                        <div className="col-md-3">$2,250 + 2% of billed charges</div>
                        <div className="col-md-7 fst-italic">4 week turnaround</div>
                    </div>
                </div>
            </div>
            <div className="my-5 text-end">
                <Link to="/files/AccuMed Pricing Sheet - Nov 2023.pdf" target="_blank" rel="noopener noreferrer" style={{ color: "#1db8ea" }}>
                    <button className="btn access-primary-button">
                        DOWNLOAD PRICING SHEET
                    </button>
                </Link>
            </div>
        </>
    )
}
