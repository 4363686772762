import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { FormSignup } from "../Forms/FormSignup.js";

export const SignUpModal = (props) => {
    const history = useNavigate();
    const location = useLocation();

    return (
        <>
            <div className="modal fade" id="signup" tabIndex="-1" aria-hidden="true" data-bs-backdrop={location.pathname === "/builder/pro_mcp" ? "static" : "true"} data-bs-keyboard={location.pathname === "/builder/pro_mcp" ? "false" : "true"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-eula-sign">
                        {location.pathname === "/builder/pro_mcp" ? <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" onClick={(e) => history("/builder/package_search")} /> : <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="card-close" data-bs-dismiss="modal" />}
                        <div className="modal-header" style={{ borderBottom: "none", padding: 0 }}>
                            <h3 className="modal-title w-100 text-center mb-2" style={{ fontWeight: "700", color: "#000064" }}>
                                Make an account
                            </h3>
                        </div>
                        <div className="modal-body">
                            <FormSignup />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
