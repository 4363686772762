import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import ContextGlobalProvider from "./contexts/ContextGlobal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ContextGlobalProvider>
        <App />
    </ContextGlobalProvider>
);
