import React, { useContext } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { useFormContext } from "react-hook-form";

export const FormSharedContacts = (props) => {

    const {  formPatientInfo, updateFormPatientInfo, addToSharedList } = useContext(ContextGlobal);

    const methods = useFormContext();

    const handleAddToSharedList = (e) => {
        e.preventDefault();

        if (!formPatientInfo.contactFirstName) {
            methods.setError("contactFirstName", {
                type: "manual",
                message: "First Name is a required field",
            });
        }
        if (!formPatientInfo.contactLastName) {
            methods.setError("contactLastName", {
                type: "manual",
                message: "Last Name is a required field",
            });
        }
        if (!formPatientInfo.contactEmail) {
            methods.setError("contactEmail", {
                type: "manual",
                message: "Email is a required field",
            });
        }

        if (!methods.formState.errors.contactFirstName && !methods.formState.errors.contactLastName && !methods.formState.errors.contactEmail) {
            // needs to call a different function
            // we need to create the user and then add
            addToSharedList([
                {
                    firstName: formPatientInfo.contactFirstName,
                    lastName: formPatientInfo.contactLastName,
                    email: formPatientInfo.contactEmail,
                },
            ]);
            methods.setValue("contactFirstName", "");
            methods.setValue("contactLastName", "");
            methods.setValue("contactEmail", "");
        }
    };    

    return (
        <>
            <h3 className="mb-4" style={{ marginTop: "60px" }}>
                Share report with
            </h3>
            <div className="row">
                <div className="col-md-6 col-12">
                    <label htmlFor="contact-first-name" className="form-label access-input-label-style">
                        First Name
                    </label>
                    <input
                        type="text"
                        className={"form-control access-input-style " + (methods.formState.errors.contactFirstName ? "is-invalid" : "")}
                        placeholder={"First Name"}
                        id="contact-first-name"
                        defaultValue={formPatientInfo.contactFirstName ?? ""}
                        {...methods.register("contactFirstName")}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                contactFirstName: e.target.value,
                            })
                        }
                    />
                    <div className="invalid-feedback">{methods.formState.errors.contactFirstName?.message}</div>
                </div>
                <div className="col-md-6 col-12 mt-sm-0 mt-3">
                    <label htmlFor="contact-last-name" className="form-label access-input-label-style">
                        Last Name
                    </label>
                    <input
                        type="text"
                        className={"form-control access-input-style " + (methods.formState.errors.contactLastName ? "is-invalid" : "")}
                        placeholder={"last Name"}
                        id="contact-last-name"
                        defaultValue={formPatientInfo.contactLastName ?? ""}
                        {...methods.register("contactLastName")}
                        onChange={(e) =>
                            updateFormPatientInfo({
                                contactLastName: e.target.value,
                            })
                        }
                    />
                    <div className="invalid-feedback">{methods.formState.errors.contactLastName?.message}</div>
                </div>
                <div className="col-12 mt-3">
                    <label htmlFor="contact-email" className="form-label access-input-label-style">
                        Email
                    </label>
                    <input
                        type="email"
                        className={"form-control access-input-style " + (methods.formState.errors.contactEmail ? "is-invalid" : "")}
                        placeholder={"Email"}
                        id="contact-email"
                        defaultValue={formPatientInfo.contactEmail ?? ""}
                        {...methods.register("contactEmail")}
                        onChange={(e) => {
                            methods.trigger("contactEmail");
                            updateFormPatientInfo({
                                contactEmail: e.target.value,
                            });
                        }}
                    />
                    <div className="invalid-feedback">{methods.formState.errors.contactEmail?.message}</div>
                </div>
            </div>
            <button type="button" className="btn access-secondary-button" style={{ marginTop: "24px" }} onClick={handleAddToSharedList}>
                Add to share list
            </button>
        </>
    )
};
