const reducer = (state, action) => {
    switch (action.type) {
        case "SET_EXCEL": {
            return { ...action.payload };
        }

        case "CLEAR_EXCEL": {
            return {};
        }

        default: {
            return state;
        }
    }
};

export default reducer;
