const reducer = (state, action) => {
    switch (action.type) {
        case "SET_REQUESTS": {
            return action.payload;
        }
        case "PATCH_REQUEST": {
            return state.map(request => {
                if (request.request.requestID === action.payload.request.requestID) {
                    // found the request to update,
                   return request = action.payload;
                }
                return request;
            });

        }
        case "DELETE_REQUEST": {
            return state.filter(
                (request) => request.request.requestID !== action.payload
            )
        }
        default: {
            return state;
        }
    }
};

export default reducer;
