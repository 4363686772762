import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";
import { SwitchProductButton } from "../Buttons/SwitchProductButton.js";
import { StandardProductsInfo } from "../Tooltips/StandardProductsInfo.js";
import { ProProductsInfo } from "../Tooltips/ProProductInfo.js";
import { ExpertProductsInfo } from "../Tooltips/ExpertProductInfo.js";

export const SOSModalBV = (props) => {

    const message =
        <>
            <p>
                Quick & Cost-Effective Evaluation
            </p>
            <p>
                For use on cases with less than $40k in billed charges and no hospital bills. You capture the essential data from the bills and
                we provide the Usual, Customary, Reasonable (URC) analysis.
            </p>
            <ul style={{textAlign: "left"}}>
                <li>Great settlement tool for small cases</li>
                <li>Simple submission - just download the Bill Analysis Template, fill with required info from claim forms, and upload!</li>
                <li>1-2 day turnaround</li>
                <li>Switch to a Pro Report anytime</li>
            </ul>
        </>

    const proMessage = 
        <>
            <p style={{textAlign: "left"}}>
                Review of medical bills to establish reasonable value using our database of over 20 billion patient encounters to compare
                actual billed charges to other providers' fees in the same area. Pro Bill Analysis has a 2-week turnaround. 
                Cost is $500 + 1% of billed charges.
            </p>
        </>

    const expertMessage =
        <>
            <p style={{textAlign: "left"}}>
                Engage one of our qualified Experts and work directly with them as they analyze and establish the reasonable
                value of billed charges. Expert Engagement on a Bill Analysis has a 3-week turnaround, a total fee of $1,750 + 1%
                of the billed charges, and includes a complete Expert file that meets Expert Disclosure requirements.
            </p>
        </>

    // const emailRef = useRef(null);
    const faqRef = useRef(null);
    const sheetRef = useRef(null);

    const phoneSize = useMediaPredicate("(max-width: 450px)");

    useLayoutEffect(() => {
        // emailRef.current.style.setProperty("font-size", "10pt", "important");
        faqRef.current.style.setProperty("font-size", "10pt", "important");
        if (!phoneSize) {
            sheetRef.current.style.setProperty("font-size", "10pt", "important");
        } else {
            sheetRef.current.style.setProperty("font-size", "9pt", "important");
        }
    }, []);

    return (
        <>
            {/* will need to be fixed up for mobile */}
            <div className="modal fade" id="sos" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "700px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            className="card-close"
                            data-bs-dismiss="modal"
                        />
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h4
                                className={"modal-title w-100 " + (phoneSize ? "" : "text-center")}
                                style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                            >
                                Are you stuck? Have a quick question?
                                <br />
                                Just want to chat with some rad people?
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <h4
                                        style={{ font: phoneSize ? "700 1.2rem Montserrat" : "700 1.5rem Montserrat", color: "#000064" }}
                                        className="modal-title w-100 text-center"
                                    >
                                        We're here to help!
                                    </h4>
                                </div>
                            </div>
                            {props.standard ? (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_bill_analysis_pro"} 
                                                    name={"Pro Bill Analysis"}
                                                    link={"/builder/pro_bill_analysis"}
                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ProProductsInfo 
                                                message={proMessage}
                                                product={"type_product_medical_bill_analysis_pro"}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_bill_analysis_expert"}
                                                    name={"Expert Bill Analysis"}
                                                    link={"/builder/expert_bill_analysis"} //will change
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ExpertProductsInfo 
                                                message={expertMessage}
                                                product={"type_product_medical_bill_analysis_expert"}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (null)}
                            {props.pro ? (
                                <>
                                    {/* <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_bill_analysis_standard"}
                                                    name={"Standard Bill Analysis"}
                                                    link={"/builder/snapshot_upload"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <StandardProductsInfo
                                                product={"type_product_medical_bill_analysis_standard"}
                                                message={message}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_bill_analysis_expert"}
                                                    name={"Expert Bill Analysis"}
                                                    link={"/builder/expert_bill_analysis"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ExpertProductsInfo 
                                                product={"type_product_medical_bill_analysis_expert"}
                                                message={expertMessage}
                                            />
                                        </div>
                                    </div>
                                    
                                    
                                </>
                            ) : (null)}
                            {props.expert ? (
                                <>
                                    {/* <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_bill_analysis_standard"}
                                                    name={"Standard Bill Analysis"}
                                                    link={"/builder/snapshot_upload"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <StandardProductsInfo
                                                product={"type_product_medical_bill_analysis_standard"}
                                                message={message}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="row mt-4">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="d-flex justify-content-center mb-4">
                                                <SwitchProductButton 
                                                    product={"type_product_medical_bill_analysis_pro"} 
                                                    name={"Pro Bill Analysis"}
                                                    link={"/builder/pro_bill_analysis"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{ paddingTop: "4px" }}>
                                            <ProProductsInfo 
                                                message={proMessage}
                                                product={"type_product_medical_bill_analysis_pro"}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (null)}
                            <div className="row">
                                <div
                                    className="d-flex justify-content-center"
                                    style={{ font: "700 1.5rem Montserrat", color: "#000064" }}
                                >
                                    or
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center flex-fill">
                                        <a href="/contact-us" target="_blank" rel="noreferrer noopener" className="btn access-secondary-button" style={{ width: "100%" }}>
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center">
                                        <a
                                            href="/faq"
                                            target="_blank"
                                            className="btn access-secondary-button"
                                            type="button"
                                            ref={faqRef}
                                            style={{ width: "100%" }}
                                        >
                                            Visit our FAQ page
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <div className="d-flex justify-content-center">
                                        <a 
                                            ref={sheetRef}
                                            type="button"
                                            href="/files/AccuMed Pricing Sheet - June 2023.pdf"
                                            target="_blank" rel="noopener noreferrer"
                                            className="btn access-secondary-button" 
                                            style={{ textDecoration: "none", color: "white", width: "100%"}}
                                        >
                                            Download our product comparison sheet
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div
                                style={{ font: "600 0.8rem Montserrat" }}
                                className="d-flex justify-content-center mt-4"
                            >
                                (844) 307 4487
                            </div>
                            <div style={{ font: "600 0.8rem Montserrat" }} className="d-flex justify-content-center">
                                Mon-Fri: 8am - 5:30pm MT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
