import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { useDropzone } from "react-dropzone";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { BuilderBillUploadExcelValidation } from "../BuilderBillUploadExcelValidation";
import { SOSModalBV } from "../../Modals/SOSModalBV.js";
import { MaxAmountModal } from "../../Modals/MaxTotalModal";
import { Oval } from "react-loader-spinner";
import { FormPatientName } from "../../Forms/FormPatientName";

export const BuilderAutoBAUpload = (props) => {
    const { deleteExcel, uploadExcel, uploadProgress, setFeedback, excelFile, currentRequest } = useContext(ContextGlobal);

    const [allFiles, setAllFiles] = useState([]);
    const [uploadCompleted, setUploadCompleted] = useState(true);
    const [files, setFiles] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [fileHover, setFileHover] = useState(null);
    const [completedFiles, setCompletedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const maxTotalRef = useRef(null);

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 769px)");

    useEffect(() => {
        if (uploadProgress.progress >= 100) {
            setCompletedFiles([...completedFiles, uploadProgress.name]);
            setLoading(true);
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (allFiles.length > 0 && completedFiles.length == allFiles.length) {
            setUploadCompleted(true);
        }
    }, [completedFiles]);

    const [excelValidation, setExcelValidation] = useState({});
    const [rejectedFiles, setRejectedFiles] = useState(false);

    const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
        noClick: true,
        noKeyboard: true,
        multiple: false,
        accept: ".xlsx, .xls",
        onDrop: async (acceptedFiles) => {
            // setCompletedFiles([]);
            setLoading(true);
            const fileList = [];
            setAllFiles([...acceptedFiles]);
            setFiles([]); // not sure...
            setUploadCompleted(false);
            if (acceptedFiles.length !== 0) {
                setRejectedFiles(false);
            }
            for (const file of acceptedFiles) {
                fileList.push(file);
                setFiles([...fileList]);
                // get validation from excel upload
                setExcelValidation(await uploadExcel(file));
                setLoading(false);
            }
            setUploadCompleted(true);
        },
        disabled: disabled,
    });

    useEffect(() => {
        if (fileRejections.length !== 0) {
            setRejectedFiles(true);
            setFeedback({
                type: "error",
                heading: "ERROR",
                message: "Incorrect file type chosen...",
            });
        }
    }, [fileRejections]);

    const handleDeleteFile = async (path) => {
        setDeleteLoading(true);
        setFileHover(null);
        const requestID = currentRequest.request.requestID;
        if (await deleteExcel(path, requestID)) {
            setDeleteLoading(false);
            setFiles([]);
            setAllFiles([]);
        }
    };

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <a href="/" style={{ textDecoration: "none" }}>
                    &lt; Product Selection
                </a>
            </div>
            <div className="card content-card mb-5">
                <div className="row g-0">
                    <div className="col-md-6 col-12" style={{ borderRight: midSizeScreen ? "" : "1px solid #e1e5ec", paddingRight: midSizeScreen ? "" : "40px" }}>
                        <h3 className="">Get a Standard Medical Bill Analysis Report</h3>
                        <span className="" style={{ fontSize: "11pt", fontWeight: "500", color: "#1db8ea" }}>
                            COST: $100 + 1% OF BILLED CHARGES
                        </span>
                        <p style={{ fontSize: "11pt" }}>Quick & Cost-Effective Evaluation</p>
                        <p style={{ fontSize: "11pt" }}>For use on cases with less than $40k in billed charges and no hospital bills. You capture the essential data from the bills and we provide the Usual, Customary, Reasonable (URC) analysis.</p>
                        <ul style={{ fontSize: "11pt", paddingLeft: "20px" }}>
                            <li>Great settlement tool for small cases</li>
                            <li>Simple submission - just download the Bill Analysis Template, fill with required info from claim forms, and upload!</li>
                            <li>1-2 day turnaround</li>
                            <li>Switch to a Pro Report anytime</li>
                        </ul>
                        <div className="row">
                            <div className="col-md-7">
                                <Link to="/files/Bill Analysis Upload Template - Patient Name.xlsx" target="_blank" rel="noopener noreferrer">
                                    <button className="btn access-secondary-button" style={{ textDecoration: "none", color: "white", width: "100%" }}>
                                        Download Bill Analysis Template
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="row mt-3">
                            <div className="col-md-7">
                                <button className="btn access-secondary-button-flipped" disabled style={{width: "100%"}} >Download Data Capture Guide</button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-7">
                                <button className="btn access-secondary-button-flipped" disabled style={{width: "100%"}} >Download Template Example</button>
                            </div>
                        </div> */}
                        <div className="d-flex flex-row mt-4">
                            <span className="faq-contact-link" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#sos">
                                <div className="me-2" style={{ display: "inline-block" }}>
                                    <span>
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </span>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        fontSize: "0.9em",
                                        fontWeight: "500",
                                        paddingTop: "1px",
                                        display: phoneSize ? "inline" : "inline-block",
                                    }}
                                >
                                    <span>NOT WHAT YOU WERE LOOKING FOR? CONTACT US FOR HELP</span>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6 col-12" style={{ paddingLeft: midSizeScreen ? "" : "40px" }}>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <p>The Bill Analysis Template is labeled to guide your date capture from HCFA (CMS-1500) claim forms. Upon upload of a completed Bill Analysis Template, you'll get the okay (nice job, data capture pro!) or specific notes on errors to correct (don't worry, you can try again!)</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-7">
                                <Link to="/files/Data Capture Guide.pdf" target="_blank" rel="noopener noreferrer">
                                    <button className="btn access-secondary-button-flipped" style={{ textDecoration: "none", width: "100%" }}>
                                        Download Data Capture Guide
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-7">
                                <Link to="/files/Download Template Example.xlsx" target="_blank" rel="noopener noreferrer">
                                    <button className="btn access-secondary-button-flipped" style={{ textDecoration: "none", width: "100%" }}>
                                        Download Template Example
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-bs-toggle={"modal"} data-bs-target={"#max-total"} style={{ display: "none" }} ref={maxTotalRef}></button>
                <div className="row g-0 my-3">
                    <div className="row">
                        {Object.keys(excelFile) != 0 || (currentRequest.request_attachment && currentRequest.request_attachment.length != 0 ? currentRequest?.request_attachment[0]?.metadata == "1" : false) ? (
                            <div className="col-md-12">
                                <button className="btn access-danger-button" onClick={(e) => handleDeleteFile(excelFile.name)}>
                                    {deleteLoading ? <Oval color="white" height={20} width={20} /> : "Remove File"}
                                </button>
                                <span style={{ marginLeft: "10px" }}>
                                    Uploaded File: <span style={{ fontWeight: "500" }}>{excelFile.name ? excelFile.name : currentRequest.request_attachment[0].name}</span>
                                </span>
                            </div>
                        ) : null}
                    </div>
                    {Object.keys(excelFile) != 0 || (currentRequest.request_attachment && currentRequest.request_attachment.length != 0 ? currentRequest?.request_attachment[0]?.metadata == "1" : false) ? (
                        <BuilderBillUploadExcelValidation excelValidation={excelValidation} files={files} maxTotalRef={maxTotalRef} />
                    ) : (
                        <div
                            {...getRootProps({
                                className: "dropzone " + disabled ? "disabled" : "",
                            })}
                            className="col-md-12 mt-4"
                        >
                            {" "}
                            {/* Upload files zone */}
                            <div className="dropzone-container d-flex flex-column justify-content-center">
                                <input {...getInputProps()} />
                                {!loading ? (
                                    <>
                                        <div className="col-md-12 text-center" style={{ marginBottom: "10px" }}>
                                            <h3 className="" style={{ fontSize: "12pt" }}>
                                                DROP COMPLETED BILL ANALYSIS TEMPLATE
                                            </h3>
                                            <div className={"col-12 text-center" + (rejectedFiles ? " text-danger" : "")}>
                                                <em>Only *.xlsx and *.xls will be accepted</em>
                                            </div>
                                        </div>
                                        <div className="align-self-center">
                                            <button type="button" onClick={open} className="btn access-secondary-button" disabled={disabled}>
                                                Browse Computer
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <aside className="mt-4">
                                        {allFiles.map((file) => (
                                            <>
                                                {/* Progress Bar */}
                                                <div className="row justify-content-center">
                                                    <div className="col-md-8">
                                                        <div className="align-self-center progress mb-2" style={{ height: "25px" }}>
                                                            {file.path == uploadProgress.name ? (
                                                                <>
                                                                    <div
                                                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                                                        role="progressbar"
                                                                        aria-valuenow="100"
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                        style={{
                                                                            width: "100%",
                                                                            backgroundColor: "#00BFFF",
                                                                        }}
                                                                    >
                                                                        <span style={{ color: "#000046", fontSize: "16px", fontWeight: "500" }}>
                                                                            Please wait<span className="loading-dots"></span>
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            ) : completedFiles.includes(file.path) ? (
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    aria-valuenow="100"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{
                                                                        width: "100%",
                                                                        backgroundColor: "#049E8C",
                                                                    }}
                                                                >
                                                                    100%
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="progress-bar bg-success"
                                                                    role="progressbar"
                                                                    aria-valuenow="0"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{
                                                                        width: "0",
                                                                    }}
                                                                >
                                                                    0%
                                                                </div>
                                                            )}
                                                        </div>
                                                        {file.path == uploadProgress.name ? (
                                                            <div className="d-flex justify-content-center mt-2" style={{ fontWeight: "bold", fontSize: "25px" }}>
                                                                Reviewing, do not refresh page!
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </aside>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <SOSModalBV standard={true} pro={false} expert={false} />
                <MaxAmountModal allFiles={allFiles} handleDeleteFile={handleDeleteFile} />
            </div>
        </>
    );
};
