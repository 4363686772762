import React from "react";
import { Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";

export const Eula = (props) => {

    const phoneScreen = useMediaPredicate("(max-width: 500px)");

    return (
        <>
            <div className={phoneScreen ? "mt-2" : "mt-2 mx-4"}>
                <h5 style={{ font: "600 16pt Montserrat" }}>Current User Agreement</h5>
                <Link to="/files/User Agreement - Access 2021.pdf" target="_blank" rel="noopener noreferrer" download>
                    <button className="btn access-primary-button" style={{ marginTop: "24px" }}>
                        DOWNLOAD USER AGREEMENT
                    </button>
                </Link>
                <div className="mt-4">
                    TERMS OF USAGE
                    <br />
                    <br />
                    IMPORTANT NOTICE: THE ONLY PERSONS WHO ARE AUTHORIZED TO USE THIS SERVICE ARE THE PERMITTED USERS AS
                    DEFINED BELOW.
                    <br />
                    <br />
                    ACCUMED RESERVES THE RIGHT TO MONITOR ALL USAGE OF THIS SERVICE.
                    <br />
                    <br />
                    ANY PERSON WHO USES OR ATTEMPTS TO GAIN ACCESS TO THIS SERVICE WITHOUT AUTHORIZATION FROM ACCUMED,
                    AND ANY PERSON WHO AIDS AND ABETS ANY SUCH ACTION, WILL BE SUBJECT TO LEGAL ACTION OR, WHERE
                    APPLICABLE, CRIMINAL PROSECUTION.
                    <br />
                    <br />
                    This document is an agreement between you, as the end user of the software and/or content described
                    below, and AccuMed Healthcare Research, LLC (“AccuMed”). This website, including all of its
                    applications, services, programs, installers, utilities, contained files, databases, or integrated
                    third-party programs (referred to herein collectively as the “Service”) is designed to facilitate
                    the efficient electronic exchange of medical and legal documentation and information. Your use of
                    the Service is conditional upon your acceptance of all of the terms and conditions stated herein.
                    Your use of the Service will bind you to all of the stated terms and conditions. If you do not agree
                    to all of the stated terms and conditions, you must refrain from or discontinue using the Service.
                    In consideration of AccuMed's grant of the License described below, you agree as follows:
                    <br />
                    <br />
                    1. Service Ownership and Operation.
                    <br />
                    <br />
                    The Service is owned and operated exclusively by AccuMed. AccuMed has developed the Service for the
                    convenience of attorneys and healthcare providers who conduct business with AccuMed. AccuMed shall
                    not be required to make the Service available at all times or at your convenience. Access to the
                    Service may be suspended from time to time for any purpose including but not limited to maintenance,
                    repair or improvement, and may be discontinued at any time and for any reason by AccuMed in its sole
                    and absolute discretion, with or without notice. The Service and all copyrights, trademarks, patent
                    rights, moral rights and other intellectual property rights associated therewith are and shall be
                    the exclusive property of AccuMed. However, AccuMed does not claim to own the personal data
                    exchanged through your lawful use of the Service.
                    <br />
                    <br />
                    2. License. Your access to, and usage of, the Service is licensed and not sold.
                    <br />
                    <br />
                    Upon AccuMed's issuance to you of a user name and password to this web site, AccuMed has granted to
                    you a temporary, nonexclusive, revocable, royalty free license to use the Service, solely for the
                    Permitted Uses described below ("License"). You may not assign or sublicense your License except to
                    persons who are employed and supervised by you. You are strictly liable for any violations of this
                    agreement caused by the acts or omissions of your employees. AccuMed may, in its sole and absolute
                    discretion, require that each employee obtain his or her own user name and password.
                    <br />
                    <br />
                    3. Permitted Uses. Your License is limited to the following "Permitted Uses":
                    <br />
                    <br />
                    a. If you are an attorney, you may use the Service to submit and obtain information for the purpose
                    of providing legal services to your clients;
                    <br />
                    <br />
                    b. If you are a healthcare provider, you may use the Service to submit and obtain charge information
                    for the purpose of evaluating your current charge sheets.
                    <br />
                    <br />
                    Without limiting the effect of any other provision of this agreement, you agree that you will not,
                    directly or indirectly, do any of the following: (i) use the Service to design, develop or market a
                    competing product or service; (ii) disassemble or reverse engineer the Service; (iii) use the
                    Service for any illegal purpose; (iv) remove or alter any of the copyright notices or other
                    proprietary markings on any copies of the content; or (v) sub-license or otherwise distribute, or
                    give unauthorized access to the Service.
                    <br />
                    <br />
                    4. Disclaimers. AccuMed is not a bank, lender, healthcare provider, health plan, health plan
                    administrator, insurance provider, payer of benefits, or vendor of personal health records. AccuMed
                    is a data analytics service.
                    <br />
                    <br />
                    5. Representations and Warranties. As material inducement for AccuMed to grant a License to you, you
                    represent and warrant to AccuMed as follows:
                    <br />
                    <br />
                    a. If you are an attorney, that you are an attorney duly licensed to practice of law and in good
                    standing in all jurisdictions in which you are licensed;
                    <br />
                    <br />
                    b. That to the best of your knowledge, information and belief after the exercise of reasonable
                    diligence, the information you have submitted to AccuMed in the course of using the Service is
                    accurate and does not omit any material information.
                    <br />
                    <br />
                    6. Acceptable Use Policy. You agree not to engage in any unacceptable use of the Service, which
                    includes use of the Service to:
                    <br />
                    <br />
                    (i) submit information or documentation that you know to be false or misleading; (ii) transmit any
                    information that you are not entitled to possess under applicable law; (iii) transmit any electronic
                    message that is abusive or offensive in tone or content, as shall be determined in AccuMed's sole
                    and absolute discretion; (iv) engage in any other activity prohibited by reasonable acceptable use
                    policies hereafter published on this web site.
                    <br />
                    <br />
                    7. Revocation. AccuMed may revoke your License at any time, with notice to you, for any breach by
                    you or by any person employed by you of the terms of usage stated in this document.
                    <br />
                    <br />
                    8. DISCLAIMER OF WARRANTIES. THE SERVICE IS PROVIDED FOR YOUR USAGE "AS IS" WITHOUT WARRANTY OF ANY
                    KIND, EXPRESS OR IMPLIED. ACCUMED DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR
                    FREE, NOR DOES ACCUMED MAKE ANY PROMISE OR WARRANTY AS TO ANY RESULTS THAT MAY BE OBTAINED BY USE OF
                    THE SERVICE. ACCUMED PROVIDES NO OTHER WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED
                    TO, ANY IMPLIED WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. You acknowledge that the software
                    employed to operate or use the Service may be considered as pre-release or beta software and may
                    contain bugs, may not operate properly or perform all intended functions, may interfere with the
                    functioning of other software applications, and may cause errors, data loss or other problems.
                    AccuMed shall have no liability or responsibility for any incompatibility between the Service and
                    your computer system.
                    <br />
                    <br />
                    9. LIMITATION OF LIABILITY. ACCUMED SHALL NOT UNDER ANY CIRCUMSTANCES BE LIABLE TO YOU, YOUR CLIENT,
                    PATIENT OR ANY OTHER PERSON FOR ANY INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR
                    RELATED TO THE SERVICE, including but not limited to lost profits, loss of data, business
                    interruption, or any other commercial damages or losses arising out of or related to your use of (or
                    inability to use) the Service, however caused and regardless of the theory of liability, and even if
                    AccuMed has been notified of the possibility of such damage or loss.
                    <br />
                    <br />
                    10. Application of HIPAA. To the extent that the Federal Health Insurance Portability and
                    Accountability Act of 1996 and its implementing regulations (45 C.F.R. Parts 160-164) ("HIPAA")
                    applies to the information exchanged through the Service, you acknowledge and agree as follows:
                    <br />
                    <br />
                    a. The Federal Health Insurance Portability and Accountability Act of 1996 and its implementing
                    regulations (45 C.F.R. Parts 160-164) ("HIPAA") sets forth standards for protecting the privacy of
                    individually identifiable health information (the "Privacy Rule"). Capitalized terms used in this
                    Section 10 and not otherwise defined herein shall have the meanings set forth in HIPAA, which
                    definitions are hereby incorporated by reference. You acknowledge that AccuMed is a Business
                    Associate as defined by HIPAA and that AccuMed is not a Covered Entity under HIPAA. To the extent
                    that you or any of your affiliates is a Covered Entity under HIPAA, you are required to enter into
                    Business Associate agreements with all of your contractors, agents and related and unrelated third
                    parties that perform a function or activity on your behalf that involves individually identifiable
                    health information.
                    <br />
                    <br />
                    b. Use and Disclosure. AccuMed may use and disclose Protected Health Information received from you,
                    or received or generated by AccuMed on your behalf, to perform and deliver its services. AccuMed may
                    use such Information for AccuMed's own proper management and administration and for data compilation
                    services relating to your clients or patients, as the case may be, to the extent allowed by law. You
                    must obtain reasonable assurance from any person to whom you disclose Protected Health Information
                    that such person shall keep the information confidential according to applicable law and shall
                    notify AccuMed whenever such person becomes aware that the confidentiality of such information has
                    been breached.
                    <br />
                    <br />
                    c. AccuMed uses and discloses Protected Health Information only to the extent necessary in the
                    course of analyzing usual, customary and reasonable medical charges.
                    <br />
                    <br />
                    d. AccuMed will do the following with respect to Protected Health Information that it receives or
                    generates:
                    <br />
                    <br />
                    i. Use appropriate safeguards (including administrative, physical and technical safeguards that
                    reasonably and appropriately protect the confidentiality, integrity and availability of any such
                    information stored in electronic form) to prevent the unlawful use or disclosure of such
                    information;
                    <br />
                    <br />
                    ii. promptly report to the appropriate person any use or disclosure of Protected Health Information
                    not permitted by law and, with respect to electronic Protected Health Information, any Security
                    Incident (as that term is defined by the Section 164.304 of the Security Standards) of which AccuMed
                    becomes aware;
                    <br />
                    <br />
                    iii. ensure that any agents, including subcontractors, to whom a Business Associate provides such
                    information agreed to the restrictions and conditions of HIPAA to the extent required by law;
                    <br />
                    <br />
                    iv. mitigate, to the extent practicable, any harmful effect known to AccuMed of any use or
                    disclosure of Protected Health Information by AccuMed or such agents in violation of applicable law;
                    <br />
                    <br />
                    v. provide access to Protected Health Information to individuals to which access is permitted under
                    CFR §§ 164.524, 164.526 164.528, as they may be amended;
                    <br />
                    <br />
                    vi. to the extent required by law, make available AccuMed's internal practices, books and records,
                    policies and procedures relating to the use and disclosure of Protected Health Information;
                    <br />
                    <br />
                    vii. if the unlawful disclosure of Protected Health Information occurs at or by AccuMed, notify the
                    affected individual following the discovery of the breach without unreasonable delay and, in any
                    case, no later than 60 days following the discovery of the breach; and
                    <br />
                    <br />
                    viii. otherwise comply with the mandates of applicable law, rules and regulations.
                    <br />
                    <br />
                    e. You agree that if at any time you come into possession of Protected Health Information through
                    your use of this Service, and the Protected Health Information is unrelated to a matter in which you
                    have the right to receive such information, you will immediately notify AccuMed and undertake
                    remedial measures to eliminate your access to the information.
                    <br />
                    <br />
                    f. The provisions of this Section 10 are intended to satisfy the requirements of Business Associates
                    under the Privacy Standards and the Security Standards, the provisions of which are hereby
                    incorporated by this reference. This Section 10 shall be interpreted and applied in a manner so as
                    to satisfy such requirements.
                    <br />
                    <br />
                    11. Governing Law. This agreement shall be governed by and construed according to the laws of the
                    State of Colorado, without the application of any conflict of laws principles. The exclusive venue
                    for any legal proceedings arising from or related to this agreement shall be the state and federal
                    courts located within the City and County of Denver, Colorado.
                    <br />
                    <br />
                    12. Binding Agreement. This Agreement is binding upon, and shall inure to the benefit of, you and
                    AccuMed Healthcare Research, LLC and their respective heirs, personal representatives, executors and
                    administrators, successors and assigns.
                    <br />
                    <br />
                    13. No Third Party Beneficiaries. Notwithstanding any of the other provisions herein, there are no
                    intended third party beneficiaries of this agreement.
                    <br />
                    <br />
                    14. Assent. Your assent to this Agreement may be evidenced electronically, by handwritten signature,
                    by facsimile, or by any other expression of assent to the terms and conditions hereof, including but
                    not limited to your use of the Service.
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </>
    );
};
