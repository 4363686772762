import React from "react";

export const ContactThankYou = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-xxl-3 col-lg-2 col-md-1"></div>
                <div className="col-xxl-6 col-lg-8 col-md-10 col-12">
                    <div className="card content-card" style={{ marginTop: "5vh", minHeight: "30vh" }}>
                        <div className="d-flex row m-auto">
                            <div className="col-12">
                                <h1 className="contact-thank-you d-flex justify-content-center mb-4">Thank you!</h1>
                            </div>
                            <div className="d-grid col-xxl-8 col-lg-8 col-md-8 col-10 mx-auto">
                                <a href="/" className="btn access-large-button">
                                    HOME
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-lg-2 col-md-1"></div>
            </div>
        </>
    );
};
