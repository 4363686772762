import React, { useState, useEffect, useRef, useContext } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { Oval } from "react-loader-spinner";

export const SendResetEmail = (props) => {
    const { sendResetEmail } = useContext(ContextGlobal);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required().label("Email"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
    } = useForm({
        mode: "onSubmit",
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    });

    const closeRef = useRef(null);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await sendResetEmail(data);
        setLoading(false);
        if (res) {
            setSuccess(true);
            setFailed(false);
        } else {
            setSuccess(false);
            setFailed(true);
        }
    };

    useEffect(() => {
        closeRef.current.click();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-xxl-3 col-lg-2 col-md-1"></div>
                    <div className="col-xxl-6 col-lg-8 col-md-10 col-12">
                        <div className="card content-card" style={{ marginTop: "5vh", minHeight: "30vh" }}>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4 style={{ marginBottom: "16px", font: "700 17pt Montserrat" }}>
                                        <span style={{ color: "#000046", font: "700 20pt Montserrat" }}>Reset password</span>
                                    </h4>
                                </div>
                            </div>
                            {!success ? (
                                <>
                                    <div className="row">
                                        <div className="col-12 pass-wrapper">
                                            <label style={{ marginBottom: "0.2rem" }} className="form-label access-input-label-style">
                                                Email
                                            </label>
                                            <input placeholder="Email" type="email" className={"form-control access-input-style " + (errors.email ? "is-invalid" : "")} id="email" defaultValue={""} {...register("email")} />
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </div>
                                    </div>
                                    {failed === true ? (
                                        <div className="row mt-2">
                                            <div
                                                className="col-12"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "0.95em",
                                                    color: "#bb0046",
                                                }}
                                            >
                                                Email was not sent, please make sure it is entered correctly!
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <button className="btn access-primary-button" style={{ width: "100%" }}>
                                                {loading ? <Oval color="white" height={20} width={20} /> : "SEND RESET LINK"}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="row mt-4">
                                    <div className="col-12 text-center">Email has been successfully sent!</div>
                                    <div className="col-12 mb-3 text-center">Check mailbox for reset link.</div>
                                    <div className="d-grid col-xxl-7 col-lg-7 col-md-8 col-10 mx-auto">
                                        <a href="/" className="btn access-large-button">
                                            HOME
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-2 col-md-1"></div>
                </div>
            </form>
            <button data-bs-toggle={"modal"} data-bs-target={"#login"} data-bs-dismiss="modal" style={{ display: "none" }} ref={closeRef}></button>
        </>
    );
};
