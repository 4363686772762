import React, { useContext, useState, useRef } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faCircle } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";

import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FormReviewPatientInfo } from "../../Forms/Components/FormReviewPatientInfo";
import { FormReviewYourInfo } from "../../Forms/Components/FormReviewYourInfo";
import { FormReviewSharedContacts } from "../../Forms/Components/FormReviewSharedContacts";

export const BuilderAutoBAReview = (props) => {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        dateOfLoss: Yup.string().required().label("Date of Loss"),
        sex: Yup.string().required().max(1, "Sex is a required field").label("Sex"),
        location: Yup.string().required().label("Treatment location"),
        userFirstName: Yup.string().required().label("First Name"),
        userLastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        contacts: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required().label("First Name"),
                lastName: Yup.string().required().label("Last Name"),
                email: Yup.string().required().email().label("Email"),
            })
        ),
    });

    const methods = useForm({ mode: "onBlur", resolver: yupResolver(validationSchema) });
    const watchFields = methods.watch(["location", "latitude", "longitude"]);

    const { formPatientInfo, updateFormPatientInfo, clearState, formPatientInfoSections, updateFormPatientInfoSections, updateRequest, session, currentRequest, excelObj } = useContext(ContextGlobal);

    const midSizeScreen = useMediaPredicate("(max-width: 769px)");

    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const customSwitch = useRef(null);

    const [validAddress, setValidAddress] = useState(true);

    const onSubmit = async (e) => {
        // true validate if the updated lat / long exists or if the current lat/long is valid
        const validate = (watchFields[1] === "" && watchFields[2] === "") || (formPatientInfo.longitude === "" && formPatientInfo.latitude === "") ? false : true;

        if (!validate) {
            methods.setValue("location", "");
            setValidAddress(false);
        } else {
            // final check for excel file + valid file
            setLoading(true);
            await updateRequest(true, false);
            setLoading(false);
            clearState();
            // go to Thank you page if they are no longer a guest
            let path = "/builder/thank_you?";

            if (session.isGuest != 0 && !session.requestUserID) {
                path += "&isConfirmed=false";
            }

            history(path);
        }
    };

    const [hoverTitle, setHoverTitle] = useState(false);
    const [checkState, setCheckState] = useState(false);

    const handleCheck = (e) => {
        if (e.target.checked) {
            // patch flag in API
            setCheckState(true);
        } else {
            setCheckState(false);
        }
    };

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <Link
                    to={{
                        pathname: "/builder/snapshot",
                        state: {
                            fromBillUpload: true,
                        },
                    }}
                >
                    &lt; Patient Info
                </Link>
            </div>
            <div className="card content-card mb-5">
                <FormProvider {...methods}>
                    <form className="review-form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="row g-0">
                            <div className="col-md-6" style={{ borderRight: midSizeScreen ? "" : "1px solid #e1e5ec", paddingRight: midSizeScreen ? "" : "40px" }}>
                                <h3 className="mb-4">Review Upload Info</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        {currentRequest.request_attachment ? currentRequest.request_attachment[0].name : ""}
                                        <br />
                                        <span style={{ fontSize: "13px", color: "#049e8c" }}>
                                            <FontAwesomeIcon icon={faCheck} color={"#049e8c"} />
                                            &nbsp;File Validated
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        Total of Bills Scanned:
                                        <span style={{ fontWeight: "600" }}>{currentRequest.request_attachment && currentRequest.request_attachment.length != 0 ? (currentRequest.request_attachment[0].totalCharges ? " $" + parseFloat(currentRequest.request_attachment[0].totalCharges, 10).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "") : ""}</span>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <Link className="btn access-secondary-button" to={"/builder/snapshot_upload"} style={{ textDecoration: "none", color: "white" }}>
                                            Choose a different file
                                        </Link>
                                    </div>
                                </div>
                                <FormReviewPatientInfo validAddress={validAddress} setValidAddress={setValidAddress} />
                            </div>
                            <div className="col-md-6 col-12" style={{ paddingLeft: midSizeScreen ? "" : "40px", marginTop: midSizeScreen ? "16px" : "" }}>
                                <FormReviewYourInfo />
                                <FormReviewSharedContacts />
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <p>I understand that this report cannot be used in a trial.</p>
                                        <p>AccuMed's expert witness was not consulted and has not reviewed or opined on this report. Therefore, it is solely for use in the furtherance of settlement negotiations.</p>
                                        <p>If the services of an expert witness are required, I will contact AccuMed and request an expert review and Expert Disclosure package at submit@accumedintel.com or 844.307.4487</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12">Due Date: {formPatientInfo.dueDate}</div>
                                </div>
                                <div className="d-flex row g-0">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="agreeCheck"
                                            checked={checkState}
                                            onChange={(e) => {
                                                handleCheck(e);
                                            }}
                                            disabled={Object.keys(methods.formState.errors) != 0}
                                        />
                                        <label className="form-check-label" htmlFor="agreeCheck" style={{ marginTop: "3px" }}>
                                            I understand
                                        </label>
                                    </div>
                                </div>
                                <button className="btn access-primary-button mt-4" disabled={!checkState} type="submit">
                                    {loading ? <Oval color="white" height={20} width={20} /> : session.email || session.requestUserID ? "SUBMIT" : "SUBMIT AND CREATE ACCOUNT"}
                                </button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </>
    );
};
