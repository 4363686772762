const reorder = (state) => state.map((row, index) => ({ ...row, id: index }));

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_SHARED_LIST": {
            return reorder(state.concat(action.payload));
        }

        case "DELETE_CONTACT": {
            return reorder(
                state.filter((row) => {
                    return row.requestSubscriptionID !== action.payload.requestSubscriptionID;
                })
            );
        }

        case "UPDATE_CONTACT": {
            return state.map((contact) => (contact.id === action.payload.id ? { ...contact, ...action.payload } : contact));
        }

        case "CLEAR_ALL": {
            return [];
        }

        default: {
            return state;
        }
    }
};

export default reducer;
