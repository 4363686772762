import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "react-bootstrap";

export const ExpertProductsInfo = (props) => {
    const [hover, setHover] = useState(false);
    const [message, setMessage] = useState("");
    const [product, setProduct] = useState("");

    useEffect(() => {
        setMessage(props.message);
        setProduct(props.product);
    }, [props]);

    return (
        <span className="fa-layers fa-fw fa-center-icon" style={{ color: "#1DB8EA", cursor: "pointer" }} onMouseEnter={(e) => setHover(true)} onMouseLeave={(e) => setHover()}>
            <span data-tip="custom show" data-event="mouseenter" data-event-off="mouseleave" data-for="expert_info">
                <FontAwesomeIcon style={{ display: !hover ? "none" : "" }} transform="shrink-5" icon={faCircle} color="#1db8ea" />
                <FontAwesomeIcon icon={faInfo} transform="shrink-10" className="share-remove" color={hover ? "white" : "#1db8ea"} />
            </span>
            <ReactTooltip className="expert-info-tooltip" id="expert_info" place="right" globalEventOff="click" multiline={true} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                <span>{message}</span>
            </ReactTooltip>
        </span>
    );
};
