import React, { useState, useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { useMediaPredicate } from "react-media-hook";
import { Oval } from "react-loader-spinner";

import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { SOSModal } from "../../Modals/SOSModal";
import { FormPatient } from "../../Forms/Updated/FormPatient";
import { UploadDocuments } from "../../Forms/Updated/UploadDocuments";
import { FormUser } from "../../Forms/Updated/FormUser";
import { FormSharedContacts } from "../../Forms/Updated/FormShareContacts";
import { SharedList } from "../../Forms/Updated/SharedList";
import { DueDatePicker } from "../../DatePicker/DueDatePicker";

export const BuilderExpertMCP = (props) => {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        dateOfLoss: Yup.string().required().label("Date of Loss"),
        sex: Yup.string().required().max(1, "Sex is a required field").label("Sex"),
        location: Yup.string().required().label("Treatment location"),
        userFirstName: Yup.string().required().label("First Name"),
        userLastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        contacts: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required().label("First Name"),
                lastName: Yup.string().required().label("Last Name"),
                email: Yup.string().required().email().label("Email"),
            })
        ),
    });

    const { session, createRequest, formPatientInfo, updateFormPatientInfo, currentRequest, setActivePage, updateRequest, updateUser, clearState } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("");
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function create() {
            await createRequest("type_product_medical_cost_projection_expert");
        }

        if (Object.keys(currentRequest).length == 0) {
            create();
        }
    }, []);

    const [rush, setRush] = useState(false);

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 769px)");

    const history = useNavigate();
    const [loading, setLoading] = useState(false);

    const [validAddress, setValidAddress] = useState(true);
    const [uploadCompleted, setUploadCompleted] = useState(true);

    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema),
    });
    const watchFields = methods.watch(["location", "latitude", "longitude"]);

    useEffect(() => {
        if (session.email) {
            if (watchFields[3] !== "" && !session.lawFirm) {
                methods.setValue("lawFirm", watchFields[3], {
                    shouldDirty: true,
                });
            } else {
                methods.setValue("lawFirm", session.lawFirm, {
                    shouldDirty: true,
                });
            }
            updateFormPatientInfo({ lawFirm: session.lawFirm });

            methods.setValue("phoneNumber", session.phone, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ phone: session.phone });

            methods.setValue("userFirstName", session.firstName, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ userFirstName: session.firstName });

            methods.setValue("userLastName", session.lastName, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ userLastName: session.lastName });

            methods.setValue("email", session.email, {
                shouldDirty: true,
            });
            updateFormPatientInfo({ email: session.email });
        }
    }, [session]);

    const [warning, setWarning] = useState(false);

    const checkNames = async () => {
        if (formPatientInfo.firstName == formPatientInfo.userFirstName && formPatientInfo.lastName == formPatientInfo.userLastName) {
            methods.setError("firstName", {
                type: "manual",
                message: "Same first name as the user first name",
            });
            methods.setError("lastName", {
                type: "manual",
                message: "Same last name as the user last name",
            });
            setWarning(true);
            return false;
        }
        setWarning(false);
        return true;
    };

    const onSubmit = async (e) => {
        // true validate if the updated lat / long exists or if the current lat/long is valid
        const validate = (watchFields[1] === "" && watchFields[2] === "") || (formPatientInfo.longitude === "" && formPatientInfo.latitude === "") ? false : true;

        if (!validate) {
            methods.setValue("location", "");
            setValidAddress(false);
        } else {
            if ((await checkNames()) || warning) {
                //final check for excel file + valid file
                setLoading(true);
                await updateRequest(true, true);
                if (currentRequest.request.userID === session.userID && (session.phone !== formPatientInfo.phoneNumber || session.lawFirm !== formPatientInfo.lawFirm || session.firstName !== formPatientInfo.userFirstName || session.lastName !== formPatientInfo.userLastName)) {
                    await updateUser({
                        firstName: formPatientInfo.userFirstName,
                        lastName: formPatientInfo.userLastName,
                        phone: formPatientInfo.phone,
                        lawFirm: formPatientInfo.lawFirm,
                    });
                }
                setLoading(false);
                clearState();
                //go to Thank you page if they are no longer a guest
                let path = "/builder/thank_you?";
                if (rush) {
                    path += "&rush=true";
                }
                if (session.isGuest != 0 && !session.requestUserID) {
                    path += "&isConfirmed=false";
                }

                history(path);
            }
        }
    };

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <a href="/" style={{ textDecoration: "none" }}>
                    &lt; Product Selection
                </a>
            </div>
            <div className="card content-card mb-5">
                <FormProvider {...methods}>
                    <form className="expert-mcp-form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="row g-0">
                            <div className="col-lg-6 col-12" style={{ borderRight: midSizeScreen ? "" : "1px solid #e1e5ec", paddingRight: midSizeScreen ? "" : "40px" }}>
                                <h3 className="">Get an Expert Medical Cost Projection Report</h3>
                                <span className="" style={{ fontSize: "11pt", fontWeight: "500", color: "#1db8ea" }}>
                                    COST: $2,950
                                </span>
                                <p style={{ fontSize: "11pt" }}>AccuMed Experts are available for the needs of your case. Once you engage one of our qualified Experts, you will work with them directly to gain strategic insight on the value of future recommended treatment. Expert Medical Cost Projection costs $2,950 and has a 3-week turnaround time.</p>
                                <div className="d-flex flex-row mb-3">
                                    <span className="faq-contact-link" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#sos">
                                        <div className="me-2" style={{ display: "inline-block" }}>
                                            <span>
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </span>
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                fontSize: "0.9em",
                                                fontWeight: "500",
                                                paddingTop: "1px",
                                                display: phoneSize ? "inline" : "inline-block",
                                            }}
                                        >
                                            <span>NOT WHAT YOU WERE LOOKING FOR? CONTACT US FOR HELP</span>
                                        </div>
                                    </span>
                                </div>
                                <FormPatient validAddress={validAddress} setValidAddress={setValidAddress} warning={warning} />
                                <UploadDocuments uploadCompleted={uploadCompleted} setUploadCompleted={setUploadCompleted} />
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <h3 className="mb-4" style={{ marginTop: "35px" }}>
                                            Your comments
                                        </h3>
                                        <div>
                                            <textarea
                                                placeholder={"Your message"}
                                                rows="8"
                                                className={"access-textare-style form-control " + (methods.formState.errors.comment ? "is-invalid" : "")}
                                                {...methods.register("comment")}
                                                onChange={(e) =>
                                                    updateFormPatientInfo({
                                                        comment: e.target.value,
                                                    })
                                                }
                                                data-tip
                                                data-for="your-comment-tooltip"
                                                data-event="mouseenter"
                                                data-event-off="mouseleave"
                                            />
                                            <div className="invalid-feedback">{methods.formState.errors.comment?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12" style={{ paddingLeft: midSizeScreen ? "" : "40px", marginTop: midSizeScreen ? "40px" : "" }}>
                                <FormUser />
                                <FormSharedContacts />
                                <SharedList />
                                {/* Due Date Section*/}
                                <div className="row" style={{ marginTop: "40px" }}>
                                    <h3 className="mt-0 mb-3">Due date</h3>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="m-0">
                                            Turnaround time: Expert Medical Cost Analysis will be completed in 3 weeks.
                                            <br />A rush fee of $1,000 may apply if results are needed quicker than our typical turnaround time.
                                        </p>
                                    </div>
                                </div>
                                <DueDatePicker product={"type_product_medical_cost_projection_expert"} rush={rush} setRush={setRush} />
                                <button className="btn access-primary-button" style={{ marginTop: "40px" }} type="submit" disabled={!uploadCompleted}>
                                    {loading ? <Oval color="white" height={20} width={20} /> : uploadCompleted ? <>{phoneSize ? "SUBMIT EXPERT MCP" : "SUBMIT EXPERT MEDICAL COST PROJECTION"}</> : "UPLOADING FILES..."}
                                </button>
                            </div>
                        </div>
                        <SOSModal standard={false} pro={false} expert={true} />
                    </form>
                </FormProvider>
            </div>
        </>
    );
};
