import React, { useState, useContext } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faUser, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useFormContext } from "react-hook-form";


export const FormReviewSharedContacts = (props) => {

    const {
        sharedList,
        formPatientInfoSections,
        updateFormContactInfo,
        deleteFromSharedList,
    } = useContext(ContextGlobal);

    const methods = useFormContext();

    const [removeShare, setRemoveShared] = useState(false);

    const sharedIcons = [
        {
            background: "#D5FF92",
            icon: "#9FFF00",
        },
        {
            background: "#3B5583",
            icon: "#02245E",
        },
        {
            background: "#84D8F3",
            icon: "#1DB8EA",
        },
        {
            background: "#B4EFC9",
            icon: "#71E099",
        },
        {
            background: "#5CC0B4",
            icon: "#049E8C",
        },
        {
            background: "#8292D7",
            icon: "#4E65C7",
        },
    ];

    return (
        <>
            {sharedList.length > 0 ? (
                <>
                    <div className="row">
                        <div className="col-12 mt-5 mb-3">
                            <h3 style={{ display: "inline-block" }}>Report will be shared with</h3>
                        </div>
                    </div>
                    <div className="row g-0 mb-0">
                        <div className="col-xxl-1 d-none d-xxl-block"></div>
                        <div className="col-xxl-3 d-none d-xxl-block text-muted" style={{ paddingLeft: "0px" }}>
                            First Name
                        </div>
                        <div className="col-xxl-3 d-none d-xxl-block text-muted" style={{ paddingLeft: "0px" }}>
                            Last Name
                        </div>
                        <div className="col-xxl-5 d-none d-xxl-block text-muted" style={{ paddingLeft: "0px" }}>
                            Email
                        </div>
                    </div>
                </>
            ) : null}
            {sharedList.map((contact, index) => (
                <div className="row mb-2" key={index}>
                    <div className="col-xxl-1 d-none d-xxl-block" style={{ paddingRight: "16px" }}>
                        <h3>
                            <span className="fa-layers fa-fw fa-center-icon" style={{ fontSize: "1.55em" }}>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    color={sharedIcons[index % sharedIcons.length].background}
                                />
                                <FontAwesomeIcon
                                    icon={faUser}
                                    transform="shrink-6"
                                    color={sharedIcons[index % sharedIcons.length].icon}
                                />
                            </span>
                        </h3>
                    </div>
                    <div
                        className="col-xxl-3 col-xl-6 col-12"
                        style={{
                            paddingRight: !formPatientInfoSections.sharedList ? "0px" : "",
                        }}
                    >
                        <label htmlFor={"shared-first-name-" + index} className="form-label mb-0 text-muted d-xxl-none">
                            <small>First Name</small>
                        </label>
                        <input
                            type="text"
                            className={
                                "form-control access-input-style review-page " +
                                (methods.formState.errors.contacts?.[index]?.firstName ? "is-invalid" : "")
                            }
                            id={"shared-first-name-" + index}
                            value={contact.firstName ?? ""}
                            {...methods.register("contacts[" + index + "].firstName")}
                            readOnly={!formPatientInfoSections.sharedList}
                            onChange={(e) => updateFormContactInfo({ id: contact.id, firstName: e.target.value })}
                            style={{ paddingLeft: !formPatientInfoSections.sharedList ? "" : "12px" }}
                        />
                        <div className="invalid-feedback">{methods.formState.errors.contacts?.[index]?.firstName?.message}</div>
                    </div>
                    <div
                        className="col-xxl-3 col-xl-6 col-12"
                        style={{
                            paddingLeft: "0px",
                            paddingRight: !formPatientInfoSections.sharedList ? "0px" : "12px",
                        }}
                    >
                        <label htmlFor={"shared-last-name-" + index} className="form-label mb-0 text-muted d-xxl-none">
                            <small>Last Name</small>
                        </label>
                        <input
                            type="text"
                            className={
                                "form-control access-input-style review-page " +
                                (methods.formState.errors.contacts?.[index]?.lastName ? "is-invalid" : "")
                            }
                            id={"shared-last-name-" + index}
                            value={contact.lastName ?? ""}
                            {...methods.register("contacts[" + index + "].lastName")}
                            readOnly={!formPatientInfoSections.sharedList}
                            onChange={(e) => updateFormContactInfo({ id: contact.id, lastName: e.target.value })}
                            style={{ paddingLeft: !formPatientInfoSections.sharedList ? "" : "12px" }}
                        />
                        <div className="invalid-feedback">{methods.formState.errors.contacts?.[index]?.lastName?.message}</div>
                    </div>
                    <div
                        className="col-xxl-4 col-xl-11 col-11"
                        style={{
                            paddingLeft: "0px",
                            paddingRight: !formPatientInfoSections.sharedList ? "0px" : "12px",
                        }}
                    >
                        <label htmlFor={"shared-email-" + index} className="form-label mb-0 text-muted d-xxl-none">
                            <small>Email</small>
                        </label>
                        <input
                            type="email"
                            className={
                                "form-control access-input-style review-page " +
                                (methods.formState.errors.contacts?.[index]?.email ? "is-invalid" : "")
                            }
                            id={"shared-email-" + index}
                            value={contact.email ?? ""}
                            {...methods.register("contacts[" + index + "].email")}
                            readOnly={!formPatientInfoSections.sharedList}
                            onChange={(e) => updateFormContactInfo({ id: contact.id, email: e.target.value })}
                            style={{ paddingLeft: !formPatientInfoSections.sharedList ? "" : "12px" }}
                        />
                        <div className="invalid-feedback">{methods.formState.errors.contacts?.[index]?.email?.message}</div>
                    </div>
                    <div className="col-xx-1 col-xl-1 col-1" style={{ paddingLeft: "0px" }}>
                        <span
                            className="fa-layers fa-fw fa-center-icon float-end"
                            style={{ cursor: "pointer", marginRight: "0px" }}
                            onClick={(e) => deleteFromSharedList(contact.requestSubscriptionID)}
                            onMouseEnter={(e) => setRemoveShared(index)}
                            onMouseLeave={(e) => setRemoveShared()}
                        >
                            <FontAwesomeIcon
                                style={{ display: removeShare !== index ? "none" : "" }}
                                icon={faCircle}
                                color="#1db8ea"
                            />
                            <FontAwesomeIcon
                                icon={faTimes}
                                transform="shrink-6"
                                className="share-remove"
                                color={removeShare === index ? "white" : "#1db8ea"}
                            />
                        </span>
                    </div>
                </div>
            ))}
        </>
    )

}
