import React, { useContext, useEffect } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";

export const EasyButton = (props) => {
    const { setActivePage } = useContext(ContextGlobal);

    useEffect(() => {
        setActivePage("portal");
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="card" style={{ borderRadius: 0 }}>
                <div className="row g-0">
                    <div className="col-xl-5">
                        <h1 className="mt-3 mx-3">Injury Finance Portal</h1>
                    </div>
                    <div className="col-xl-2">
                        <a className="btn btn-primary mt-5" href="/builder/standard_bill_analysis?firstName=John&lastName=Doe&location=6400 S. Fiddlers Green Circle, Suite 1320, Greenwood Village, CO 80111&lawFirm=Sebben %26 Sebben Law Firm&userFirstName=Harvey&userLastName=Birdman&phoneNumber=(555) 555 5555&email=Harvey.birdman@sebben-sebben.com&dateOfLoss=03/12/2009" role="button" rel="noopener noreferrer" target="_blank">
                            <img src="img/AccuMed logo_Icon White.svg" style={{ maxWidth: "35%" }} />
                            <div className="row my-2">
                                <div className="col-md-12">AccuMed Easy Button</div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">Standard Bill Analysis</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="card-body">
                    <div className="injury-finance mt-2">
                        <div className="row mt-2">
                            <div className="col-xl-6 border-bottom">
                                <h4>Patient Information:</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 p-3 border-bottom border-end">Patient Name:</div>
                            <div className="col-xl-4 p-3 border-bottom border-end">John Doe</div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 p-3 border-bottom border-end">Patient Address:</div>
                            <div className="col-xl-4 p-3 border-bottom border-end">6400 S. Fiddlers Green Circle, Suite 1320, Greenwood Village, CO 80111</div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-xl-6 border-bottom">
                                <h4>Law Firm Details:</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 p-3 border-bottom border-end">Contact Name:</div>
                            <div className="col-xl-4 p-3 border-bottom border-end">Harvey Birdman</div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 p-3 border-bottom border-end">Law Firm:</div>
                            <div className="col-xl-4 p-3 border-bottom border-end">Sebben & Sebben Law Firm</div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 p-3 border-bottom border-end">Contact Phone Number:</div>
                            <div className="col-xl-4 p-3 border-bottom border-end">555-555-5555</div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 p-3 border-bottom border-end">Contact Email:</div>
                            <div className="col-xl-4 p-3 border-bottom border-end">Harvey.birdman@sebben-sebben.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
