const reorder = (state) => state.map((row, index) => ({ ...row }));

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_LIST": {
            const contacts = action.payload.map(contact => ({...contact, edit: false}));
            return reorder(state.concat( ...contacts ));
        }
        
        case "ADD_TO_LIST": {
            return reorder(state.concat( [action.payload] ));
        }

        case "DELETE_CONTACT": {
            return reorder(state.filter((row) => row.contactID !== action.payload.contactID));
        }

        case "UPDATE_CONTACT": {
            return state.map((contact) =>
                contact.contactID===action.payload.contactID ? { ...contact, ...action.payload } : contact
            );
        }

        case "CLEAR_ALL": {
            return [];
        }

        default: {
            return state;
        }
    }
};

export default reducer;
