import React, { userEffect, useState, useContext } from "react";
import { useMediaPredicate } from "react-media-hook";
import { ProductButton } from "../Buttons/ProductButton";

export const HistoricalAnalysisProducts = (prop) => {
    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const vertical = useMediaPredicate("(max-width: 1080px)");
    const laptopSize = useMediaPredicate("(max-width: 1536px)");

    const badge = {
        width: "auto",
        position: "absolute",
        bottom: "5%",
        left: "24%",
        height: "143%",
    };

    const mobileBadge = {
        width: "auto",
        position: "absolute",
        bottom: "20px",
        left: "4px",
        height: "90px",
    };

    return (
        <div className="card card-product">
            <div className="card-header text-center mt-4" style={{ background: "#fff", borderTopLeftRadius: "1em", borderTopRightRadius: "1em", borderBottom: "0" }}>
                {/* <img src="/img/Access_Homepage Doctor SM.png" className="img-fluid" alt="Responsive image"/> */}
                <h4
                    style={{
                        marginBottom: "10px",
                        marginTop: "24px",
                        paddingLeft: "16px",
                        font: "700 17pt Montserrat",
                    }}
                >
                    <span style={{ color: "#1db8ea", font: "700 22pt Montserrat" }}>Historical Analysis Products</span>
                </h4>
            </div>
            <div className="card-body text-center">
                <h5 style={{ font: "700 18px Montserrat", color: "#000046" }}>MEDICAL BILL ANALYSIS</h5>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12">Review of medical bills to establish reasonable value using our database of over 20 billion patient encounters to compare actual billed charges to other providers' fees in the same area.</div>
                </div>
                {/* <img src="/img/Access_New Badge.png" style={{position: "absolute", left: "110px", top: "400px", height: "137px"}} /> */}
                <div className="row justify-content-center mt-4" style={{ position: "relative" }}>
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER STANDARD BA"} description={"For use on smaller cases"} product={"type_product_medical_bill_analysis_standard"} beta={true} />
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER PRO BA"} description={"Hand it over to the Pros"} product={"type_product_medical_bill_analysis_pro"} />
                    </div>
                </div>
                <div className="row justify-content-center my-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER EXPERT BA"} description={"Expert Witness at your service"} product={"type_product_medical_bill_analysis_expert"} />
                    </div>
                </div>
                {/* <div className="row justify-content-center mt-4" style={{position: "relative"}} >
                    <img src="/img/Access_Beta Badge.png" style={phoneSize ? mobileBadge : badge} />
                    <ProductButton 
                        title={"SNAPSHOT"} 
                        description={"Your prep work, our data"} 
                        product={"bill_value_auto"} 
                    />
                </div> */}
                <h5 style={{ font: "700 18px Montserrat", color: "#000046", marginTop: "60px" }}>BILL ANALYSIS + CODE REVIEW</h5>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12">Audit of medical bills & records, associated medical coding, and billed charges to establish both medical coding compliance and reasonable value of billed charges in the area where treatment was received.</div>
                </div>

                <div className="row justify-content-center mt-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER PRO BA + CR"} description={"Hand it over to the Pros"} product={"type_product_bill_analysis_with_code_review_pro"} />
                    </div>
                </div>
                <div className="row justify-content-center my-4">
                    <div className="d-grid col-xxl-7 col-lg-10 col-11 mx-auto">
                        <ProductButton title={"ORDER EXPERT BA + CR"} description={"Expert Witness at your service"} product={"type_product_bill_analysis_with_code_review_expert"} />
                    </div>
                </div>
            </div>
        </div>
    );
};
